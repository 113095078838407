import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
export default function Tooltip({ tooltip, position, oneline, instant, clickable, visible, className, children, style, }) {
    const options = [
        position !== 'top' && position,
        oneline && 'oneline',
        instant && 'instant',
        clickable && 'clickable',
        visible && 'visible',
    ]
        .filter(Boolean)
        .join(' ');
    if (!tooltip) {
        return (_jsx("div", { className: className, style: style, children: children }));
    }
    const tooltipString = typeof tooltip === 'string' ? tooltip : '';
    return (_jsxs("div", { className: className, style: style, "data-tooltip-options": options, "data-tooltip": tooltipString, children: [children, !tooltipString && _jsx("div", { className: "data-tooltip-content", children: tooltip })] }));
}
