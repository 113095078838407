import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { map } from 'lodash/fp';
import { Fragment } from 'react';
import { trackEvent } from '../../../react/services/Tracking';
import styles from './style.m.less';
export const GridRadio = ({ value, options, onChange, className, small = true, eventTracking, urlMapping }) => {
    return (_jsx("div", { className: classNames(className, styles.gridRadio, { small }), children: map(option => {
            const id = option.id;
            return (_jsxs(Fragment, { children: [_jsx("input", { id: id, name: option.name, disabled: option.disabled, type: "radio", checked: (value === null || value === void 0 ? void 0 : value.id) === option.id, onChange: () => {
                            if (eventTracking) {
                                trackEvent(Object.assign(Object.assign({}, eventTracking), { label: option.trackingLabel }));
                            }
                            return onChange(option);
                        } }), _jsxs("label", { htmlFor: id, title: option.name, className: classNames({ [styles.smallLabel]: small, [styles.regularLabel]: !small }), children: [urlMapping && urlMapping[option.icon] && (_jsx("img", { className: styles.image, src: urlMapping[option.icon], alt: option.name })), option.name] })] }, option.id));
        }, options) }));
};
