import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconChevronDown } from 'featherico';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { mapProps } from '../../hoc/recompose';
import withConfirmModal from '../../hoc/withConfirmModal';
import withDisabledCheck from '../../hoc/withDisabledCheck';
import withModal from '../../hoc/withModal';
import Button from '../Button';
import withExportModal from '../ExportModal/withExportModal';
import { BEHAVIOR, composeOption, createBaseOption, createDropdown } from '../GenericDropdownFactory';
import Link from '../Link';
import styles from './style.m.less';
export const BaseOption = createBaseOption({ activeClassName: styles.activeOption })(props => (_jsx(Button, Object.assign({ kind: "LINK" }, props))));
export const createLinkOption = component => compose(mapProps(props => (Object.assign({ onClick: () => { } }, props))), composeOption())(createBaseOption({ activeClassName: styles.activeOption })(component));
export const Option = composeOption()(BaseOption);
export const OptionLink = createLinkOption(props => _jsx(Link, Object.assign({}, props)));
export const OptionWithConfirm = composeOption(withConfirmModal)(BaseOption);
export const OptionWithDisabledCheck = composeOption(withDisabledCheck)(BaseOption);
export const OptionWithDisabledCheckWithConfirm = composeOption(withConfirmModal, withDisabledCheck)(BaseOption);
export const OptionWithModal = composeOption(mapProps(props => (Object.assign(Object.assign({}, props), { modalProps: Object.assign(Object.assign({}, props.modalProps), { onClose: props.onClose }) }))), withModal)(BaseOption);
export const OptionWithModalAndDisabledCheck = composeOption(withModal, withDisabledCheck)(BaseOption);
export const OptionWithExportModal = composeOption(withExportModal)(BaseOption);
export const Dropdown = createDropdown({
    behavior: BEHAVIOR.DROPDOWN,
    wrapperClassName: 'ui-dropdown',
    wrapperOpenClassName: 'hover',
    menuComponent: ({ children, large }) => {
        return (
        // class "large" forces style onto the children of ui-dropdown-drop
        _jsx("div", { className: classNames('ui-dropdown-drop', { large: large }), children: _jsx("ul", { children: children }) }));
    },
    triggerComponent: ({ kind = 'DEFAULT', style, buttonClass, noDefaultButtonStyle, label, ariaLabel, onClick, onFocus, tabIndex, 'aria-expanded': ariaExpanded, showChevron = true, eventTracking, disabled = false, }) => {
        const buttonClassName = classNames(buttonClass, {
            small: !noDefaultButtonStyle,
        });
        return (_jsxs(Button, { kind: kind, onFocus: onFocus, className: buttonClassName, style: style, onMouseDown: onClick, onTouchEnd: onClick, tabIndex: tabIndex, "aria-haspopup": "true", "aria-expanded": ariaExpanded, "aria-label": ariaLabel, eventTracking: eventTracking, disabled: disabled, children: [label, " ", showChevron && _jsx(IconChevronDown, { className: "no-print" })] }));
    },
});
Dropdown.propTypes = {
    label: PropTypes.node,
    ariaLabel: (props, propName, componentName) => {
        if (!props.label && !props.ariaLabel) {
            return new Error(`Please specify a label and/or an ariaLabel for ${componentName}.`);
        }
    },
    eventTracking: PropTypes.shape({
        category: PropTypes.string.isRequired,
        action: PropTypes.string,
    }),
    large: PropTypes.bool,
};
export const Divider = () => _jsx("hr", { className: styles.divider });
