import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import api from '../../../api';
import { DASHBOARD } from '../../../routes';
import Button from '../../components/Button';
import { useAppContext } from '../../hooks/useAppContext';
import { prependAppPath } from '../../services/Url';
const ImpersonationBanner = () => {
    const { user } = useAppContext();
    const { impersonator } = user;
    if (!impersonator || user.loginname.endsWith('.fake')) {
        return null;
    }
    const endImpersonation = () => {
        api.users.impersonate(impersonator.id).then(() => window.location.assign(prependAppPath(DASHBOARD)));
    };
    return (_jsxs("div", { className: "boxWarning", children: ["You are impersonating ", user.name, ". Any action you perform will be real, and attributed to their account.", ' ', _jsx(Button, { kind: "LINK", onClick: endImpersonation, children: "End impersonation." })] }));
};
export default ImpersonationBanner;
