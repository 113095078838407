import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IconHelpCircle } from 'featherico';
import moment from 'moment-timezone';
import api from '../../../../../api';
import { Dropdown, OptionLink, OptionWithModal, createLinkOption } from '../../../../components/Dropdown';
import NotificationsDotButton from '../../../../components/Notifications/Dot/Button';
import NotificationsDotInline from '../../../../components/Notifications/Dot/Inline';
import { useAppContext } from '../../../../hooks/useAppContext';
import { useObservable } from '../../../../hooks/useObservable';
import { isAnyAdmin } from '../../../../services/User';
import IntercomSupportLink from '../../../Navigation/SupportLink/IntercomSupportLink';
import { HrListSupport } from '../../../Navigation/SupportLink/WithHrListSupportModal';
const OptionHelpLink = createLinkOption((props) => (_jsx(IntercomSupportLink, Object.assign({ inHeader: true }, props))));
export default function HelpMenu() {
    var _a;
    const { user: currentUser } = useAppContext();
    const { value: lastUpdatedReleaseNotes } = useObservable(() => api.releaseNotes.getLastUpdatedReleaseNotes.createObservable(), []);
    const newReleaseNotes = lastUpdatedReleaseNotes &&
        moment((_a = currentUser.lastCheckedReleaseNotes) !== null && _a !== void 0 ? _a : currentUser.dateCreated).isBefore(lastUpdatedReleaseNotes === null || lastUpdatedReleaseNotes === void 0 ? void 0 : lastUpdatedReleaseNotes.date);
    return (_jsxs(Dropdown, { buttonClass: "headerIconButton", kind: "PLAIN", showChevron: false, ariaLabel: `Help menu`, label: _jsxs(_Fragment, { children: [_jsx(IconHelpCircle, {}), newReleaseNotes && _jsx(NotificationsDotButton, {})] }), eventTracking: { category: 'navigation header help', action: 'toggle' }, large: true, children: [_jsx(OptionLink, { href: "https://www.small-improvements.com/resources", openInNewTab: true, eventTracking: { category: 'navigation header help', action: 'click', label: 'helpful resources' }, children: "Helpful Resources" }), _jsxs(OptionLink, { href: "https://www.small-improvements.com/release-notes", onClick: () => api.userSettings.setCheckedReleaseNotes(), openInNewTab: true, eventTracking: { category: 'navigation header help', action: 'click', label: 'whats new' }, children: ["What's New", newReleaseNotes && _jsx(NotificationsDotInline, {})] }), _jsx(OptionLink, { href: "https://status.small-improvements.com", openInNewTab: true, eventTracking: { category: 'navigation header help', action: 'click', label: 'system status' }, children: "System Status" }), isAnyAdmin(currentUser) ? (_jsx(OptionHelpLink, {})) : (_jsx(OptionWithModal, { modalProps: {
                    size: 'LG_WIDTH',
                    title: 'Get Help',
                    body: () => _jsx(HrListSupport, {}),
                }, eventTracking: {
                    category: 'navigation header help',
                    action: 'click',
                    label: 'get help non-admin',
                }, children: "Get Help" }))] }));
}
