import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { formatNumber } from '../../../../common/services/number-formatter';
import Button from '../../../components/Button';
class MaxCharacters extends Component {
    constructor(props) {
        super(props);
        this.state = { changing: false, maximumCharactersPerResponse: this.props.maximumCharactersPerResponse };
    }
    render() {
        if (this.state.changing) {
            return (_jsxs("small", { className: "guided-cycle-card-footer", children: [_jsx("span", { children: "Maximum characters per question answer" }), "\u00A0", _jsxs("span", { children: [_jsx("input", { type: "number", min: 1, value: this.state.maximumCharactersPerResponse, onChange: e => this.setState({ maximumCharactersPerResponse: e.target.value }) }), "\u00A0", _jsx(Button, { kind: "LINK", onClick: () => this.changeMaximumCharacters(), children: "Save" })] })] }));
        }
        else {
            return (_jsxs("small", { className: "guided-cycle-card-footer", children: [_jsxs("span", { children: ["Question answers are limited to ", formatNumber(this.props.maximumCharactersPerResponse), ' ', "characters."] }), "\u00A0", _jsx(Button, { kind: "LINK", onClick: () => this.setState({ changing: true }), children: "Change" })] }));
        }
    }
    changeMaximumCharacters() {
        this.props.onChangeMaximumCharactersPerResponse({
            maximumCharactersPerResponse: this.state.maximumCharactersPerResponse,
        });
        this.setState({ changing: false });
    }
}
export default MaxCharacters;
