import { useState } from 'react';
export const useLoadingValue = (initialValue, initialLoading = true) => {
    const toInitialState = () => ({
        loading: initialLoading,
        error: undefined,
        value: initialValue,
    });
    const [{ loading, error, value }, setState] = useState(toInitialState());
    const setLoading = (nextLoading) => setState({ loading: nextLoading, value, error });
    const setValue = (nextValue) => setState({ loading: false, value: nextValue, error });
    const setError = (nextError) => setState({ loading: false, value, error: nextError });
    const reset = () => setState(toInitialState());
    return { loading, error, value, setLoading, setValue, setError, reset };
};
