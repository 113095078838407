import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { sanitize } from '../../../common/services/sanitizer/service';
import { ADMIN_FEATURE_SELECTION } from '../../../routes';
import { useAppContext } from '../../hooks/useAppContext';
import { prependAppPath } from '../../services/Url';
const CompanyMessage = () => {
    const { user } = useAppContext();
    const { company: { companySettings }, } = user;
    if (!companySettings.headerMessage) {
        return null;
    }
    return (_jsxs("div", { className: "broadcast", id: "globalMsg", children: [_jsx("img", { src: "/images/illustrations/bullhorn_icon.svg", alt: "" }), _jsx("span", { dangerouslySetInnerHTML: { __html: sanitize(companySettings.headerMessage) } }), user.isHR && (_jsx("a", { href: prependAppPath(ADMIN_FEATURE_SELECTION), className: "editLink", children: "Edit" }))] }));
};
export default CompanyMessage;
