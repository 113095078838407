import { startsWith } from 'lodash/fp';
import history from '../../../routing/history';
import { interpolateUrl } from '../Url';
const withoutAppPrefix = (t) => t.replace(/^\/app\//, '/');
const isSamePage = (a, b) => withoutAppPrefix(a) === withoutAppPrefix(b);
function isSinglePageApp(h) {
    return !!h;
}
export function redirect(url, params = {}, forceReload = true) {
    if (typeof window === 'undefined') {
        return;
    }
    window.onbeforeunload = null;
    const realUrl = interpolateUrl(url, params);
    if (!forceReload && history && isSamePage(history.location.pathname, realUrl)) {
        return;
    }
    goToUrl(realUrl);
}
const warnAboutStateNotSupported = (state) => state && console.error('Passing state is not supported when navigating outside the SPA.'); // eslint-disable-line no-console
export function goToUrl(url, state) {
    if (isSinglePageApp(history) && isSinglePageAppPath(url)) {
        history.push(withoutAppPrefix(url), state);
    }
    else {
        warnAboutStateNotSupported(state);
        window.location.href = url;
    }
}
export function replaceUrl(url, state) {
    if (isSinglePageApp(history) && isSinglePageAppPath(url)) {
        history.replace(withoutAppPrefix(url), state);
    }
    else {
        warnAboutStateNotSupported(state);
        window.location.replace(url);
    }
}
export function openInNewTab(url) {
    window.open(url, '_blank');
}
export function isSinglePageAppPath(path) {
    return startsWith('/app/', path);
}
