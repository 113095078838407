import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from 'react-router';
import { useAppContext } from '../../react/hooks/useAppContext';
import confirmIdentity from '../../react/services/ConfirmIdentity';
export default function ConfirmIdentityRoute(_a) {
    var { render } = _a, otherProps = __rest(_a, ["render"]);
    const { user } = useAppContext();
    return (_jsx(Route, Object.assign({}, otherProps, { render: user.secureLogin
            ? render
            : () => {
                confirmIdentity();
                return undefined;
            } })));
}
