import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { compose, filter, isEmpty, uniqBy } from 'lodash/fp';
import * as React from 'react';
import { get as getPluralized } from '../../../../react/services/Pluralize';
import r from '../../../../react/services/Replacement';
import MiniProfile from '../../MiniProfile';
const uniqActive = compose(uniqBy('id'), filter('isActive'));
export const AdminHelpListResolved = ({ admins, children }) => {
    const activeAdmins = uniqActive(admins);
    const adminWording = getAdminWording(activeAdmins);
    return (_jsxs(_Fragment, { children: [adminWording && _jsx("div", { children: children(adminWording) }), _jsx(AdminMiniProfilesList, { admins: activeAdmins })] }));
};
export const AdminMiniProfilesList = ({ admins }) => {
    if (isEmpty(admins)) {
        return null;
    }
    return (_jsxs("div", { children: [_jsx("p", { children: "Here are administrators that can help you:" }), _jsx("ul", { className: "nakedList", children: admins.map(user => (_jsx("li", { children: _jsx(MiniProfile, { user: user, className: "round-image ui-mini-profile" }) }, user.id))) })] }));
};
function getAdminWording(admins) {
    return admins.length ? `your ${getPluralized('admin', admins.length)}` : r('general.hr');
}
