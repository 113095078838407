export var PAYMENT_METHOD;
(function (PAYMENT_METHOD) {
    PAYMENT_METHOD["CREDIT_CARD"] = "CREDIT_CARD";
    PAYMENT_METHOD["WIRE_TRANSFER"] = "WIRE_TRANSFER";
})(PAYMENT_METHOD || (PAYMENT_METHOD = {}));
export var STATUS;
(function (STATUS) {
    STATUS["FUTURE"] = "FUTURE";
    STATUS["IN_TRIAL"] = "IN_TRIAL";
    STATUS["ACTIVE"] = "ACTIVE";
    STATUS["NON_RENEWING"] = "NON_RENEWING";
    STATUS["PAUSED"] = "PAUSED";
    STATUS["CANCELLED"] = "CANCELLED";
    STATUS["_UNKNOWN"] = "_UNKNOWN";
})(STATUS || (STATUS = {}));
export var CancelReason;
(function (CancelReason) {
    CancelReason["NOT_PAID"] = "NOT_PAID";
    CancelReason["NO_CARD"] = "NO_CARD";
    CancelReason["FRAUD_REVIEW_FAILED"] = "FRAUD_REVIEW_FAILED";
    CancelReason["NON_COMPLIANT_EU_CUSTOMER"] = "NON_COMPLIANT_EU_CUSTOMER";
    CancelReason["TAX_CALCULATION_FAILED"] = "TAX_CALCULATION_FAILED";
    CancelReason["CURRENCY_INCOMPATIBLE_WITH_GATEWAY"] = "CURRENCY_INCOMPATIBLE_WITH_GATEWAY";
    CancelReason["NON_COMPLIANT_CUSTOMER"] = "NON_COMPLIANT_CUSTOMER";
    CancelReason["_UNKNOWN"] = "_UNKNOWN";
})(CancelReason || (CancelReason = {}));
export var CustomerState;
(function (CustomerState) {
    /** A customer still on freshbooks */
    CustomerState["FRESHBOOKS"] = "FRESHBOOKS";
    /** A customer in trial */
    CustomerState["TRIAL"] = "TRIAL";
    /** A customer in trial that has completed the checkout process */
    CustomerState["TRIAL_CHECKED_OUT"] = "TRIAL_CHECKED_OUT";
    /** A customer cancels during trial */
    CustomerState["TRIAL_NON_RENEWING"] = "TRIAL_NON_RENEWING";
    /** A customer in future state */
    CustomerState["FUTURE"] = "FUTURE";
    /** A customer in future state that has completed the checkout process */
    CustomerState["FUTURE_CHECKED_OUT"] = "FUTURE_CHECKED_OUT";
    /** A regular active customer, has CC or autocollection off === has checked out*/
    CustomerState["ACTIVE"] = "ACTIVE";
    /** A free user, active but no addons */
    CustomerState["ACTIVE_FREE"] = "ACTIVE_FREE";
    /** An active customer that has not checked out. Can occur during in-term migration */
    CustomerState["ACTIVE_NOT_CHECKED_OUT"] = "ACTIVE_NOT_CHECKED_OUT";
    /** A regular actice customer that has auto collection on, but the credit card removed */
    CustomerState["ACTIVE_CC_REMOVED"] = "ACTIVE_CC_REMOVED";
    /** A regular active customer with outstanding invoices */
    CustomerState["ACTIVE_OVERDUE"] = "ACTIVE_OVERDUE";
    /** A customer with cancel reason "Not Paid", who fell through dunning */
    CustomerState["DUNNING_FAILED"] = "DUNNING_FAILED";
    /** A customer who was manually cancelled (no cancel reason) */
    CustomerState["MANUALLY_CANCELLED"] = "MANUALLY_CANCELLED";
    /** A customer with cancel reason "No Card", because they didn't finish their checkout */
    CustomerState["MISSED_ACTIVATION"] = "MISSED_ACTIVATION";
    /** Cancelled for some other chargebee reason, this should not normally occur */
    CustomerState["CANCELLED_OTHER"] = "CANCELLED_OTHER";
    /** A customer who won't renew at the end of their term */
    CustomerState["NON_RENEWING"] = "NON_RENEWING";
    CustomerState["UNKOWN"] = "UNKOWN";
})(CustomerState || (CustomerState = {}));
export var BILLING_LOCATION;
(function (BILLING_LOCATION) {
    BILLING_LOCATION["EU"] = "EU";
    BILLING_LOCATION["US"] = "US";
})(BILLING_LOCATION || (BILLING_LOCATION = {}));
export var Pricing;
(function (Pricing) {
    Pricing["SUITE_2020"] = "SUITE_2020";
    Pricing["STARTER_2020"] = "STARTER_2020";
    Pricing["UNIFIED_2020"] = "UNIFIED_2020";
    Pricing["UNIFIED_2017"] = "UNIFIED_2017";
    Pricing["UNIFIED_2015"] = "UNIFIED_2015";
    Pricing["LEGACY_GOLD"] = "LEGACY_GOLD";
    Pricing["LEGACY_SILVER"] = "LEGACY_SILVER";
    Pricing["LEGACY_BRONZE"] = "LEGACY_BRONZE";
    Pricing["LAUNCH_2024"] = "LAUNCH_2024";
    Pricing["GROW_2024"] = "GROW_2024";
    Pricing["ELEVATE_2024"] = "ELEVATE_2024";
})(Pricing || (Pricing = {}));
