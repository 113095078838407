import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../../services/CurrentUser';
import { subscribeKeyPress, subscribeOutsideClick } from '../../services/GlobalEventHelper';
import { TEAM, createQuery, searchWithActions } from '../../services/HeaderSearchBar';
import store from '../../store';
import { actionCreators } from './ducks';
import HeaderSearchBar from './presenter';
function mapStateToProps(state) {
    return {
        showResults: state.headerSearchBar.showResults,
    };
}
function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators(actionCreators, dispatch);
    return {
        actions: Object.assign(Object.assign({}, actions), { search: query => searchWithActions(query, actions.doSearch, actions.doSearchSuccess, actions.doSearchError) }),
    };
}
class HeaderSearchBarWrapper extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { actions } = this.props;
        actions.search(createQuery(TEAM, getCurrentUser()));
        subscribeOutsideClick('header_search_bar', () => {
            if (store.getState().headerSearchBar.showResults) {
                actions.doHideResults();
                this.props.onBlur();
            }
        });
        subscribeKeyPress(ev => {
            const J = 106;
            if (ev.keyCode === J || ev.charCode === J) {
                actions.doUpdateQuery('');
                actions.search(createQuery(TEAM, getCurrentUser()));
                actions.doShowResults();
                this.props.onFocus();
                ev.preventDefault();
            }
        });
    }
    render() {
        return _jsx(HeaderSearchBar, Object.assign({}, this.props));
    }
}
const Connected = connect(mapStateToProps, mapDispatchToProps)(HeaderSearchBarWrapper);
export default function OuterComponent(props) {
    return (_jsx(Provider, { store: store, children: _jsx(Connected, Object.assign({}, props)) }));
}
OuterComponent.propTypes = {
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};
