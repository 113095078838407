import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forEach, isEqual, keys, mapValues } from 'lodash/fp';
import { Component } from 'react';
import LoadingIndicatorFull from '../react/components/LoadingIndicatorFull';
import confirmIdentity from '../react/services/ConfirmIdentity';
import { isRememberMeViolation, isUnauthorized } from '../react/services/RequestRejection';
import RouteError from './RouteError';
class AngularRenderer extends Component {
    constructor() {
        super();
        this.state = { loading: true, error: null };
    }
    componentDidMount() {
        this.bootstrapAngular();
    }
    componentDidUpdate(prevProps) {
        if (this.props.state !== prevProps.state || !isEqual(this.props.match.params, prevProps.match.params)) {
            this.destroy();
            this.bootstrapAngular();
        }
    }
    componentWillUnmount() {
        this.destroy();
    }
    bootstrapAngular() {
        this.setState({ loading: true, error: null });
        const { state, $injector } = this.props;
        const { params } = this.props.match;
        overwriteObject($injector.get('$stateParams'), params);
        $injector.get('$state').current = state;
        $injector.get('commandQueue').changeSilenceMode(true);
        this.getLocals($injector, state.resolve)
            .then(this.renderAngular.bind(this, state, params))
            .catch(this.handleError.bind(this, state));
    }
    getLocals($injector, resolve = {}) {
        const $q = $injector.get('$q');
        return $q.all(mapValues(fn => $injector.invoke(fn), resolve));
    }
    isCurrentState(state) {
        const { $injector } = this.props;
        return state === $injector.get('$state').current;
    }
    handleError(state, error) {
        if (isRememberMeViolation(error)) {
            return this.confirmIdentity(state);
        }
        else if (isUnauthorized(error)) {
            window.location.reload();
            return;
        }
        const { $injector } = this.props;
        $injector.get('commandQueue').changeSilenceMode(false);
        this.setState({ error, loading: false });
        if (!error.status) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }
    confirmIdentity(state) {
        if (!this.isCurrentState(state)) {
            return;
        }
        confirmIdentity();
    }
    renderAngular(state, params, locals) {
        const { $injector } = this.props;
        const $compile = $injector.get('$compile');
        const $rootScope = $injector.get('$rootScope');
        const $controller = $injector.get('$controller');
        $injector.get('commandQueue').changeSilenceMode(false);
        if (!this.isCurrentState(state)) {
            return;
        }
        const element = angular.element(state.template || '<div />');
        const $scope = $rootScope.$new();
        $scope.$resolve = locals;
        if (state.controller) {
            const controller = $controller(state.controller, Object.assign({ $scope, $element: element }, locals));
            $scope[state.controllerAs] = controller;
            element.data('$ngControllerController', controller);
        }
        const link = $compile(element);
        const $element = link($scope);
        angular.element(this.domNode).append($element);
        this.$scope = $scope;
        this.setState({ loading: false });
    }
    destroy() {
        const { $injector } = this.props;
        overwriteObject($injector.get('$stateParams'), {});
        $injector.get('$state').current = {};
        if (this.$scope) {
            this.$scope.$destroy();
        }
        if (this.domNode) {
            angular.element(this.domNode.children).remove();
        }
    }
    render() {
        return (_jsxs("div", { children: [this.state.loading && _jsx(LoadingIndicatorFull, {}), this.state.error && _jsx(RouteError, { errorCode: this.state.error.status }), _jsx("div", { ref: domNode => (this.domNode = domNode) })] }));
    }
}
function overwriteObject(object, newObject) {
    forEach(key => delete object[key], keys(object));
    forEach(key => (object[key] = newObject[key]), keys(newObject));
}
export default AngularRenderer;
