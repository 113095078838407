import { isString, reduce } from 'lodash/fp';
export const REGEXES = {
    email: /.+@(.+\..+)+/,
    number: /^[0-9]+$/,
    urlDomain: /^[A-Za-z0-9_-]+$/,
    urlProtocol: /^(http|https):\/\//,
    publicKey: /(ssh-\S+\s)(.*?)(\s.*)/,
};
export const getValidateFunction = (validators) => (value, context) => {
    if (validators) {
        return (validators.reduce((error, validator) => error || validator.validate(value, context), null) || null);
    }
    else {
        return null;
    }
};
export const getInputProps = (validators) => {
    return reduce((result, validator) => (Object.assign(Object.assign({}, result), validator.inputProps)), { required: false }, validators);
};
export const VALIDATORS = {
    required: (params = {}) => ({
        inputProps: { required: true },
        validate: (value, { label }) => {
            const isInvalid = !value || (isString(value) && !value.length);
            return isInvalid ? params.errorMessage || getRequiredErrorMessage(label) : null;
        },
    }),
    email: () => ({
        validate: (value) => value && REGEXES.email.test(value) ? null : getEmailErrorMessage(),
    }),
    number: (params = {}) => ({
        validate: (value) => {
            if (!REGEXES.number.test(String(value))) {
                return getNumberErrorMessage();
            }
            const number = parseInt(String(value));
            if ((params.min !== undefined && number < params.min) ||
                (params.max !== undefined && number > params.max)) {
                return getNumberRangeErrorMessage(params.min, params.max);
            }
            return null;
        },
    }),
    urlDomain: () => ({
        validate: (value) => REGEXES.urlDomain.test(String(value)) ? null : getUrlDomainErrorMessage(),
    }),
    urlProtocol: () => ({
        validate: (value) => REGEXES.urlProtocol.test(String(value)) ? null : getUrlProtocolErrorMessage(),
    }),
    length: (params = {}) => ({
        inputProps: { minLength: params.minLength, maxLength: params.maxLength },
        validate: (value) => {
            const isTooShort = params.minLength && (!value || value.length < params.minLength);
            const isTooLong = params.maxLength && value && value.length > params.maxLength;
            return isTooShort || isTooLong ? getLengthErrorMessage(params.minLength, params.maxLength) : null;
        },
    }),
    valueAlreadyExists: (params) => ({
        validate: (value) => value && params.existingValues.includes(value) ? params.errorMessage : null,
    }),
    exactLineLength: (requiredLength) => ({
        validate: (value, { label }) => {
            const falseyIds = value === null || value === void 0 ? void 0 : value.split('\n').filter(row => {
                return row.length !== requiredLength && row.length !== 0;
            });
            return falseyIds && falseyIds.length > 0
                ? `Each ${label} must have a length of ${requiredLength}. First invalid line: "${falseyIds[0]}"`
                : null;
        },
    }),
    publicKey: () => ({
        validate: (value) => value && REGEXES.publicKey.test(value) ? null : 'Please enter valid public key',
    }),
};
export function getRequiredErrorMessage(label) {
    label = label || 'value';
    const article = getArticle(label);
    return `Please enter ${article} ${label}.`;
}
export function getNumberErrorMessage() {
    return `Please only use numbers.`;
}
export function getNumberRangeErrorMessage(min, max) {
    if (min !== undefined && max !== undefined) {
        return `Please only use numbers between ${min} and ${max}.`;
    }
    if (min !== undefined) {
        return `Please only use numbers greater than or equal to ${min}.`;
    }
    if (max !== undefined) {
        return `Please only use numbers lower than or equal to ${max}.`;
    }
    return null;
}
export function getUrlDomainErrorMessage() {
    return `Please only use alphanumeric characters, underscore or dash.`;
}
export function getUrlProtocolErrorMessage() {
    return `Please enter a valid url that starts with https:// or http://`;
}
export function getEmailErrorMessage() {
    return `Please enter a valid email address.`;
}
export function getLengthErrorMessage(minLength, maxLength) {
    if (minLength && !maxLength) {
        return `Please enter at least ${minLength} characters.`;
    }
    if (!minLength && maxLength) {
        return `Please enter less than ${maxLength} characters.`;
    }
    return `Please enter between ${minLength} and ${maxLength} characters.`;
}
export function getArticle(label) {
    const firstLetter = label[0].toLowerCase();
    const startsWithVowel = ['a', 'e', 'i', 'o', 'u'].includes(firstLetter);
    return startsWithVowel ? 'an' : 'a';
}
