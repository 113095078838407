import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { compose } from 'lodash/fp';
import api from '../../../api';
import CollectFeedbackHeaderBox from '../../components/CollectFeedbackHeaderBox';
import PageHeader from '../../components/PageHeader';
import PageStatic from '../../components/PageStatic';
import PageTitle from '../../components/PageTitle';
import { withAppContext } from '../../hoc/withAppContext';
import { withPageData } from '../../hoc/withPageData';
import AdminOverview from '../Admin/Overview/presenter';
import { countAdminErrors, groupLinksByCategory } from '../Admin/Overview/service';
function IntegrationsPage({ adminLinks, appContext }) {
    const title = 'Integrations';
    return (_jsxs(PageStatic, { children: [_jsx(PageTitle, { title: title }), _jsx(PageHeader, { title: title, children: _jsx(CollectFeedbackHeaderBox, { cta: "Request an Integration", url: "https://small-improvements-feedback.typeform.com/to/QZdakiX7" }) }), _jsx(AdminOverview, { categories: groupLinksByCategory(adminLinks), errorCount: countAdminErrors(adminLinks), currentUser: appContext.user })] }));
}
export default compose(withPageData({
    resolve: {
        adminLinks: () => api.admin.getIntegrationsLinks(),
    },
}), withAppContext)(IntegrationsPage);
