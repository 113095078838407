import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconX } from 'featherico';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Button from '../Button';
import * as styles from './style.m.less';
export const COLOURS = {
    BLUE: [styles.blue],
    GREEN: [styles.green],
    NEUTRAL: [styles.neutral],
    RED: [styles.red],
    YELLOW: [styles.yellow],
    WHITE: [styles.white],
};
export const CALLOUT_POSITIONS = {
    BOTTOM: 'callout--bottom',
    BOTTOM_LEFT: 'callout--bottom--left',
    BOTTOM_RIGHT: 'callout--bottom--right',
    LEFT: 'callout--left',
    LEFT_TOP: 'callout--left--top',
    LEFT_BOTTOM: 'callout--left--bottom',
    RIGHT: 'callout--right',
    RIGHT_TOP: 'callout--right--top',
    RIGHT_BOTTOM: 'callout--right--bottom',
    TOP: 'callout--top',
    TOP_LEFT: 'callout--top--left',
    TOP_RIGHT: 'callout--top--right',
};
function AlertBox({ colour, compact, onDismiss, className, title, calloutPosition, children }) {
    const classes = classNames(className, calloutPosition ? [CALLOUT_POSITIONS[calloutPosition], styles.callout] : null, [styles.container], COLOURS[colour], compact ? styles.compact : null);
    return (_jsxs("div", { className: classes, children: [_jsxs("div", { className: styles.content, children: [title && _jsx("div", { className: classNames(styles.title, children ? styles.titleMargin : null), children: title }), children] }), onDismiss ? _jsx(DismissButton, { onClick: onDismiss }) : null] }));
}
function DismissButton({ onClick }) {
    return (_jsx(Button, { kind: "PLAIN", className: styles.controls, onClick: onClick, children: _jsx(IconX, {}) }));
}
AlertBox.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    colour: PropTypes.oneOf(Object.keys(COLOURS)),
    onDismiss: PropTypes.func,
    calloutPosition: PropTypes.oneOf(Object.keys(CALLOUT_POSITIONS)),
};
AlertBox.defaultProps = {
    calloutPosition: null,
};
export default AlertBox;
