// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SupportLink-hrListButton-9tGjh {\n  color: #7a7a79;\n}\n.SupportLink-hrListButton-9tGjh:hover {\n  text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"hrListButton": "SupportLink-hrListButton-9tGjh"
};
module.exports = exports;
