import { jsx as _jsx } from "react/jsx-runtime";
import { assign } from 'lodash';
import { has, isEqual } from 'lodash/fp';
import { Component } from 'react';
import { getInjector } from '../../../angular-injector-holder';
/**
 * @param {string} name - service name
 */
export function getAngularServiceWeak(name) {
    const injector = getInjector();
    try {
        return injector === null || injector === void 0 ? void 0 : injector.get(name);
    }
    catch (_a) {
        return null;
    }
}
export function getAngularService(moduleGetter, name) {
    return import(/* webpackChunkName: 'AngularModule' */ '../../../module').then(({ $injector }) => moduleGetter().then(m => {
        $injector.loadNewModules([m.default]);
        return name && $injector.get(name);
    }));
}
const compileElement = scopeProps => element => {
    if (element) {
        const $compile = getAngularServiceWeak('$compile');
        const $rootScope = getAngularServiceWeak('$rootScope');
        if (element.__scope) {
            assign(element.__scope, { $ctrl: scopeProps });
        }
        else {
            const scope = assign($rootScope.$new(), { $ctrl: scopeProps });
            $compile(element)(scope);
            element.__scope = scope;
        }
        if (!element.__scope.$$phase) {
            element.__scope.$digest();
        }
    }
    return element;
};
export function compileAngular(template, scopeProps) {
    return _jsx(AngularCompiler, { template: template, scopeProps: scopeProps });
}
class AngularCompiler extends Component {
    constructor() {
        super(...arguments);
        this.setRef = element => {
            const { scopeProps } = this.props;
            this.element = element;
            this.setAngularElement(scopeProps);
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps, this.props)) {
            this.setAngularElement(nextProps.scopeProps);
        }
    }
    setAngularElement(scopeProps) {
        this.angularElement = compileElement(scopeProps)(this.element);
    }
    componentWillUnmount() {
        if (has('angularElement.__scope', this)) {
            this.angularElement.__scope.$destroy();
        }
    }
    render() {
        const { template } = this.props;
        return _jsx("div", { ref: this.setRef, dangerouslySetInnerHTML: { __html: template } });
    }
}
