export const mkCustomMapping = () => ({
    customMappingSource: '',
    customMappingTarget: null,
    customMappingSourceId: null,
});
export const validateCustomMapping = (customMapping) => ({
    customMappingSource: (customMapping.customMappingTarget || customMapping.customMappingSourceId) && !customMapping.customMappingSource
        ? 'Required'
        : undefined,
    customMappingSourceId: undefined,
    customMappingTarget: customMapping.customMappingSource && !customMapping.customMappingTarget ? 'Required' : undefined,
});
export const isNonEmptyAndValid = (customMapping) => Object.values(validateCustomMapping(customMapping)).every(x => x === undefined) &&
    customMapping.customMappingTarget !== null;
