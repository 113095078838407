import * as R from '../../../routes';
import { getAdminRouteForNavigation } from '../../../routes-pairs';
import { MODES } from '../../components/CycleCreate/CycleCreateContent';
import replacement from '../Replacement';
import { interpolateAppUrl } from '../Url';
import { hasBasicHr, isManager } from '../User';
const FEEDBACK = 'FEEDBACK';
const REVIEW = 'REVIEW';
const OBJECTIVE = 'OBJECTIVE';
export function getUrl(user, cycle) {
    return getConcreteUrl(user, cycle.type, cycle);
}
export function getPageTitle(mode, model, singularTitleKey) {
    return {
        [MODES.copy]: model.name,
        [MODES.create]: `${replacement(singularTitleKey)} Cycle Setup`,
        [MODES.edit]: `Edit ${model.name}`,
    }[mode];
}
export function getInitialStaffSelection(mode) {
    if (mode === MODES.copy) {
        return 'STAFF_SELECTION_SELECTED_STAFF';
    }
    else {
        return undefined;
    }
}
function getConcreteUrl(user, type, cycle) {
    const userHasBasicHr = hasBasicHr(user);
    switch (type) {
        case FEEDBACK:
            return userHasBasicHr
                ? interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE), {
                    cycleId: cycle.id,
                })
                : interpolateAppUrl(R.YOUR_TEAM_FEEDBACK_WITH_CYCLE, {
                    cycleId: cycle.id,
                    managerId: user.id,
                });
        case REVIEW:
            return userHasBasicHr
                ? interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_REVIEWS_WITH_CYCLE), {
                    cycleId: cycle.id,
                })
                : interpolateAppUrl(R.YOUR_TEAM_REVIEWS_WITH_CYCLE, {
                    cycleId: cycle.id,
                    managerId: user.id,
                });
        case OBJECTIVE:
            return userHasBasicHr
                ? interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_OBJECTIVES_OVERVIEW), {
                    cycleId: cycle.id,
                })
                : getObjectiveUrlForNonHr(user, cycle);
    }
}
function getObjectiveUrlForNonHr(user, cycle) {
    return isManager(user)
        ? interpolateAppUrl(R.YOUR_TEAM_OBJECTIVES_OVERVIEW, {
            cycleId: cycle.id,
            managerId: user.id,
        })
        : '/app/team/objectives/browse?select=coworkers&cycleIds=' + cycle.id;
}
