import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { difference, keys, omit } from 'lodash/fp';
import PropTypes from 'prop-types';
import * as React from 'react';
import Button, { KINDS, KINDS_CLASSMAP } from '../Button';
import Link from '../Link';
// @ts-ignore
const omitLinkProps = omit(difference(keys(Link.propTypes), keys(Button.propTypes)));
export default function LinkButton(_a) {
    var { href, kind = 'DEFAULT', isSmall = false, className, disabled = false, eventTracking } = _a, passedProps = __rest(_a, ["href", "kind", "isSmall", "className", "disabled", "eventTracking"]);
    if (disabled) {
        return (_jsx(Button, Object.assign({}, omitLinkProps(passedProps), { kind: kind, className: className, isSmall: isSmall, disabled: true, children: passedProps.children })));
    }
    const cN = classNames({ small: isSmall }, KINDS_CLASSMAP[kind], className);
    return _jsx(Link, Object.assign({ href: href }, passedProps, { eventTracking: eventTracking, className: cN }));
}
LinkButton.propTypes = Object.assign({ kind: PropTypes.oneOf(KINDS), isSmall: PropTypes.bool, disabled: PropTypes.bool, eventTracking: PropTypes.object }, Link.propTypes);
LinkButton.defaultProps = {
    kind: 'DEFAULT',
    disabled: false,
    isSmall: false,
};
