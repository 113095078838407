import { compose, filter, map, set, some } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { isAnyAdmin } from '../../../services/User';
export const isMatching = (currentLocation, link) => some(path => matchPath(currentLocation, { path }) && matchesRouteSearchParams(link))([
    link.route,
    ...link.alsoMatch,
]);
const matchesRouteSearchParams = link => {
    if (!link.routeSearchParams) {
        return true;
    }
    const currentSearchParams = new URLSearchParams(window.location.search);
    return Object.keys(link.routeSearchParams).every(key => currentSearchParams.get(key) === link.routeSearchParams[key]);
};
const highlightCurrent = currentLocation => link => set('isCurrent', isMatching(currentLocation, link), link);
const strikeThrough = currentUser => link => set('isDeactivated', !link.isFeatureEnabled(currentUser.company.enabledFeatures), link);
const availableLinks = currentUser => group => ({
    className: group.className,
    links: filter(link => link.isAvailableTo(currentUser), group.links),
});
const highlightLinks = currentLocation => group => ({
    className: group.className,
    links: map(highlightCurrent(currentLocation), group.links),
});
const filterOrStrikeThrough = currentUser => group => ({
    className: group.className,
    links: isAnyAdmin(currentUser)
        ? map(strikeThrough(currentUser), group.links)
        : filter(link => link.isFeatureEnabled(currentUser.company.enabledFeatures), group.links),
});
export const toView = (groupedLinks, currentUser, currentLocation) => compose(map(filterOrStrikeThrough(currentUser)), map(highlightLinks(currentLocation)), map(availableLinks(currentUser)))(groupedLinks);
export const useNavigation = currentNavigation => {
    const [selectedNav, setSelectedNav] = useState(currentNavigation);
    useEffect(() => {
        setSelectedNav(currentNavigation);
    }, [currentNavigation]);
    return [selectedNav, setSelectedNav];
};
