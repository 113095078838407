import { API_URL } from '../../../app-config/constants';
import { get, post, put } from '../request';
export function createOnboardingSettings(request) {
    return post(`${API_URL}/onboarding-survey/`, { postData: request });
}
export function updateOnboardingSettings(request) {
    return put(`${API_URL}/onboarding-survey/update/`, { postData: request });
}
export function getOnboardingSettings() {
    return get(`${API_URL}/onboarding-survey/`);
}
export function getCompanyOnboardingSettingsCount() {
    return get(`${API_URL}/onboarding-survey/count/`);
}
export function getCompanySizeFilled() {
    return get(`${API_URL}/onboarding-survey/company-size/`);
}
