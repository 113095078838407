import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import Button from '../../Button';
import SaveButton from '../../SaveButton';
import styles from './style.m.less';
export const SIZES_CLASSMAP = {
    DEFAULT: styles.default,
    SMALL: styles.small,
    TITLE: styles.title,
};
export const InputButton = (_a) => {
    var { className, size = 'DEFAULT' } = _a, otherProps = __rest(_a, ["className", "size"]);
    return (_jsx(Button, Object.assign({ isSmall: size !== 'SMALL', className: classNames(styles.inputButton, SIZES_CLASSMAP[size], className) }, otherProps)));
};
export const InputButtonIcon = (_a) => {
    var { className, size = 'DEFAULT' } = _a, otherProps = __rest(_a, ["className", "size"]);
    return (_jsx(Button, Object.assign({ kind: "PLAIN", className: classNames(styles.inputButtonIcon, SIZES_CLASSMAP[size], className) }, otherProps)));
};
export const InputSaveButton = (_a) => {
    var { className, size = 'DEFAULT' } = _a, otherProps = __rest(_a, ["className", "size"]);
    return (_jsx(SaveButton, Object.assign({ isSmall: size !== 'SMALL', className: classNames(styles.inputButton, SIZES_CLASSMAP[size], className) }, otherProps)));
};
