import { getCurrentUser } from '../../../services/CurrentUser';
import { getOnValue } from '../../../services/Firebase';
let firstCall = true;
export function onCount(cb) {
    const currentUser = getCurrentUser();
    return getOnValue(`/users/${currentUser.id}/notificationsCount`).then(onValue => onValue(snapshot => {
        cb(snapshot.val(), firstCall);
        firstCall = false;
    }));
}
