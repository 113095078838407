import { curry, keyBy, noop, replace } from 'lodash/fp';
import { replaceQueryWithEmbed } from '../../helpers/autocompletion';
import AutocompletionBlot, { mkSerialize } from '../../helpers/autocompletion/blot';
class CustomReplacement extends AutocompletionBlot {
}
CustomReplacement.blotName = 'custom-replacement';
CustomReplacement.className = 'rte-custom-replacement';
CustomReplacement.siSerialize = mkSerialize(noop);
export default CustomReplacement;
export const createDeserialize = curry((replacements, text) => {
    const replacementsByKey = keyBy('placeHolder', replacements);
    return replace(/{{\s?(.+?)\s?}}/g, match => {
        const replacement = replacementsByKey[match];
        return replacement
            ? CustomReplacement.create({ value: replacement.displayName, serialization: replacement.placeHolder })
                .outerHTML
            : match;
    }, text);
});
export const insertCustomReplacement = (quill, query, replacement) => {
    replaceQueryWithEmbed(quill, query, CustomReplacement.blotName, {
        value: replacement.displayName,
        serialization: replacement.placeHolder,
    });
};
