// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationsList-empty-1-TeR {\n  padding: 20px;\n  font-style: italic;\n  color: #7a7a79;\n  text-align: center;\n  font-size: 13px;\n  --fi-size: 14px;\n}\n.NotificationsList-spinner-2Szkk {\n  text-align: center;\n  margin: 20px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"empty": "NotificationsList-empty-1-TeR",
	"spinner": "NotificationsList-spinner-2Szkk"
};
module.exports = exports;
