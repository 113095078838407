// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingIndicator-arrow-cGldA {\n  stroke-dasharray: 14;\n  stroke-dashoffset: 14;\n  animation: LoadingIndicator-arrowBuild-1iBym 1.5s linear 0.25s infinite forwards;\n}\n.LoadingIndicator-head-vom1p {\n  stroke-dasharray: 25;\n  stroke-dashoffset: 25;\n  animation: LoadingIndicator-buildPath-3fqql 0.5s ease-in forwards;\n}\n.LoadingIndicator-body-11hMm {\n  stroke-dasharray: 24;\n  stroke-dashoffset: 24;\n  animation: LoadingIndicator-buildPath-3fqql 0.5s ease-in forwards;\n}\n@keyframes LoadingIndicator-arrowBuild-1iBym {\n  40%,\n  50% {\n    stroke-dashoffset: 0;\n  }\n  80%,\n  100% {\n    stroke-dashoffset: -14;\n  }\n}\n@keyframes LoadingIndicator-buildPath-3fqql {\n  100% {\n    stroke-dashoffset: 0;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"arrow": "LoadingIndicator-arrow-cGldA",
	"arrowBuild": "LoadingIndicator-arrowBuild-1iBym",
	"head": "LoadingIndicator-head-vom1p",
	"buildPath": "LoadingIndicator-buildPath-3fqql",
	"body": "LoadingIndicator-body-11hMm"
};
module.exports = exports;
