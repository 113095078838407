import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconAlertCircle } from 'featherico';
import PropTypes from 'prop-types';
import Crossfade from '../../Crossfade';
import styles from './style.m.less';
InputSubLabel.propTypes = {
    children: PropTypes.node,
    show: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
};
export function InputSubLabel({ children, show, isError }) {
    return (_jsx(Crossfade, { children: show ? (_jsxs("div", { className: isError ? styles.error : styles.label, children: [isError && _jsx(IconAlertCircle, {}), " ", children] }, "label")) : (_jsx("noscript", {}, "noLabel")) }));
}
