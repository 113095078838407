const IRREGULAR_PLURALS = {
    is: 'are',
    has: 'have',
};
export function get(str, count, customPlural) {
    return count === 1 ? str : customPlural || IRREGULAR_PLURALS[str] || createDefaultPlural(str);
}
export function getWithCount(str, count, customPlural) {
    return `${count} ${get(str, count, customPlural)}`;
}
function createDefaultPlural(str) {
    const lastI = str.length - 1;
    return isYSingular(str, lastI) ? str.slice(0, lastI) + 'ies' : str + 's';
}
function isYSingular(str, lastI) {
    return str.length > 2 && str[lastI] === 'y' && !isVowel(str[lastI - 1]);
}
function isVowel(char) {
    return char.match(/[aeiou]/);
}
