import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { API_URL } from '../../../../../../../app-config/constants';
import { get } from '../../../../../../api/request';
import LoadingIndicatorFull from '../../../../../components/LoadingIndicatorFull';
import { withData } from '../../../../../hoc/withData';
import ObjectiveCycleCreate from '../../lazy';
import { getEditModel } from '../../services';
function ObjectiveCycleEditPage({ cycle }) {
    const { model: backendModel } = cycle;
    const model = getEditModel(backendModel);
    return _jsx(ObjectiveCycleCreate, { mode: "edit", model: model });
}
const BASE = `${API_URL}/objective-cycles/guided`;
export default compose(setPropTypes({
    cycleId: PropTypes.string.isRequired,
}), withData({
    resolve: {
        cycle: ({ cycleId }) => get(`${BASE}/${cycleId}`),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
}))(ObjectiveCycleEditPage);
