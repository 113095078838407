import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { isFunction, values } from 'lodash/fp';
import PropTypes from 'prop-types';
import * as React from 'react';
import Button from '../Button';
import LinkButton from '../LinkButton';
import styles from './style.m.less';
const COLORS = {
    RED: 'RED',
    BLUE: 'BLUE',
    TEAL: 'TEAL',
    YELLOW: 'YELLOW',
};
const getColorClass = (color) => {
    switch (color) {
        case COLORS.RED:
            return styles.red;
        case COLORS.TEAL:
            return styles.teal;
        case COLORS.BLUE:
            return styles.blue;
        case COLORS.YELLOW:
            return styles.yellow;
        default:
            return styles.defaultColor;
    }
};
PillBox.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
    color: PropTypes.oneOf(values(COLORS)),
    className: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,
};
export default function PillBox({ value, className, onClick, color, href }) {
    const isButton = isFunction(onClick);
    const allClassNames = classNames({ [styles.hasOnClick]: isButton || href }, getColorClass(color), styles.container, className);
    if (isButton) {
        return (_jsx(Button, { kind: "PLAIN", className: allClassNames, onClick: onClick, children: value }));
    }
    if (href) {
        return (_jsx(LinkButton, { kind: "PLAIN", className: allClassNames, href: href, openInNewTab: true, children: value }));
    }
    return _jsx("span", { className: allClassNames, children: value });
}
