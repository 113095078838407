import { jsx as _jsx } from "react/jsx-runtime";
import Button from '../../../components/Button';
import { getCurrentUser } from '../../../services/CurrentUser';
import { isAnyAdmin } from '../../../services/User';
import IntercomSupportLink from './IntercomSupportLink';
import WithHrListSupportModal from './WithHrListSupportModal';
import styles from './style.m.less';
export default function SupportLink() {
    const currentUser = getCurrentUser();
    const useIntercom = isAnyAdmin(currentUser);
    if (useIntercom) {
        return _jsx(IntercomSupportLink, { inHeader: false });
    }
    return (_jsx(WithHrListSupportModal, { children: ({ openModal }) => (_jsx(Button, { kind: "PLAIN", className: styles.hrListButton, onClick: openModal, eventTracking: {
                category: 'navigation footer',
                action: 'support needed',
                label: 'modal',
            }, children: "Need Support?" })) }));
}
