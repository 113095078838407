// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AdminNavigationContainer-container-jFWsA {\n  margin-bottom: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "AdminNavigationContainer-container-jFWsA"
};
module.exports = exports;
