import { jsx as _jsx } from "react/jsx-runtime";
import { isDeleted } from '../../services/User';
import Link from '../Link';
import PrepareUser from '../PrepareUser';
import UserLabel from '../UserLabel';
function UserLink(props) {
    const { user, eventTracking } = props;
    if (user.link && !isDeleted(user)) {
        return (_jsx(Link, { href: user.link, eventTracking: eventTracking, children: _jsx(UserLabel, Object.assign({}, props)) }));
    }
    return _jsx(UserLabel, Object.assign({}, props));
}
export default PrepareUser(UserLink); // eslint-disable-line new-cap
