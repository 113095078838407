import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Component } from 'react';
import { pageTitleService } from '../../../services/PageTitle';
import NotificationsDotButton from '../Dot/Button';
import { onCount } from './service';
class NotificationCount extends Component {
    constructor() {
        super(...arguments);
        this.socket = null;
    }
    UNSAFE_componentWillMount() {
        this.checkCount();
    }
    componentWillUnmount() {
        if (this.socket) {
            this.socket();
            this.socket = null;
        }
    }
    componentDidMount() {
        this.updateTitleCount();
    }
    componentDidUpdate() {
        this.updateTitleCount();
    }
    updateTitleCount() {
        pageTitleService.updateUnreadNotificationCount(this.props.count);
    }
    checkCount() {
        onCount((countObj, firstCall) => {
            if (countObj) {
                const { count, lastChange, actions } = this.props;
                const newCount = countObj.count;
                const hasChanged = count !== newCount || lastChange !== countObj.lastChange;
                if (hasChanged || firstCall) {
                    actions.doInitCount(countObj);
                }
                if (hasChanged && newCount) {
                    const hasCountIncreased = count < newCount;
                    if (hasCountIncreased) {
                        actions.doGetNotifications({
                            limit: Math.min(newCount, 10),
                            offset: 0,
                            pushAlert: !firstCall,
                        });
                    }
                }
            }
        }).then(deregister => (this.socket = deregister));
    }
    render() {
        const { count } = this.props;
        const displayedCount = count > 99 ? '99+' : count;
        if (!count) {
            return null;
        }
        return _jsx(NotificationsDotButton, { children: displayedCount });
    }
}
NotificationCount.propTypes = {
    count: PropTypes.number.isRequired,
    actions: PropTypes.shape({
        doInitCount: PropTypes.func.isRequired,
    }).isRequired,
};
export default NotificationCount;
