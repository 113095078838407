import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { castArray, isEmpty, some } from 'lodash/fp';
import { Route } from 'react-router';
import { useAppContext } from '../../react/hooks/useAppContext';
import { isFeatureEnabled } from '../../react/services/Features';
import { isAdmin, isAssistant, isHr } from '../../react/services/User';
import RouteError from '../RouteError';
const ROLE_CHECKS = {
    'HR Admin': isHr,
    'HR Assistant': isAssistant,
    'Tech Admin': isAdmin,
};
export default function RestrictedRoute(_a) {
    var { render, requiredRole = [], requiredFeature } = _a, otherProps = __rest(_a, ["render", "requiredRole", "requiredFeature"]);
    const { user } = useAppContext();
    let errorCode = null;
    const matchesRequiredFeature = !requiredFeature || isFeatureEnabled(requiredFeature);
    if (!matchesRequiredFeature) {
        errorCode = 404;
    }
    const matchesRequiredRole = isEmpty(requiredRole) || some(role => ROLE_CHECKS[role](user), castArray(requiredRole));
    if (!matchesRequiredRole) {
        errorCode = 403;
    }
    return _jsx(Route, Object.assign({}, otherProps, { render: errorCode ? () => _jsx(RouteError, { errorCode: errorCode }) : render }));
}
