import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators, selectors } from '../ducks';
import NotificationsCount from './presenter';
const mapStateToProps = state => {
    return {
        count: selectors.getCount(state),
        lastChange: selectors.getLastChangeDate(state),
    };
};
const mapDispatchToProps = dispatch => {
    const { doInitCount, doGetNotifications } = actionCreators;
    return {
        actions: bindActionCreators({ doInitCount, doGetNotifications }, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsCount);
