export function topPosition(el) {
    if (!el) {
        return 0;
    }
    return el.offsetTop + topPosition(el.offsetParent);
}
export function normalizedScrollTop() {
    return window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop;
}
export function hasReachedScrollEnd(node, scrollThreshold) {
    const scrollTop = normalizedScrollTop();
    const pos = topPosition(node) + node.offsetHeight - scrollTop - window.innerHeight;
    return pos < Number(scrollThreshold);
}
