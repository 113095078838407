import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card } from '../../../Cards';
import styles from '../style.m.less';
import Selector from './selector';
import { HOURS_12, HOURS_24, isPm, MINUTES, MINUTES_10, MINUTES_15, MINUTES_5, MINUTES_ZERO, toHoursMinutes, toOption, } from './time';
const AmPmOptions = ['AM', 'PM'];
const getHours = (format) => {
    switch (format) {
        case '12hr':
            return HOURS_12;
        case '24hr':
            return HOURS_24;
    }
};
const getMinutes = (stepSize) => {
    switch (stepSize) {
        case 0:
            return MINUTES_ZERO;
        case 1:
            return MINUTES;
        case 5:
            return MINUTES_5;
        case 10:
            return MINUTES_10;
        case 15:
            return MINUTES_15;
    }
};
const getSelectedHour = (hour, format) => {
    if (hour === null) {
        return null;
    }
    else if (format === '12hr') {
        return hour % 12;
    }
    else {
        return hour % 24;
    }
};
export default function TimePickerPopup({ value, timeZone, format, onChange, stepSizeMins }) {
    const [hour24, minute] = toHoursMinutes(value);
    const hourOptions = getHours(format);
    const hour = getSelectedHour(hour24, format);
    const minuteOptions = getMinutes(stepSizeMins);
    const isHourPm = format === '12hr' && hour24 !== null && isPm(hour24);
    const getHour24 = (newHour) => {
        const convertToPm = isHourPm && typeof parseInt(newHour) !== 'undefined';
        if (convertToPm) {
            return Math.max(12, (parseInt(newHour) + 12) % 24);
        }
        else if (format === '12hr') {
            return parseInt(newHour) % 12;
        }
        return parseInt(newHour) % 24;
    };
    const handleChange = (parts) => {
        onChange(parts.map(toOption).join(':'));
    };
    const handleAmPmChange = (newOption) => {
        if (newOption === 'AM' || hour === 12) {
            handleChange([hour || 0, minute || 0]);
        }
        else {
            handleChange([hour !== null ? hour + 12 : 0, minute || 0]);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.flexWrapper, children: [_jsx(Selector, { options: hourOptions, selectedIndex: hour, onSelect: newHour => handleChange([getHour24(newHour), minute || 0]) }), _jsx(Selector, { options: minuteOptions, selectedIndex: minute !== null ? minuteOptions === null || minuteOptions === void 0 ? void 0 : minuteOptions.indexOf(toOption(minute)) : null, onSelect: newMinute => handleChange([hour24 || 0, parseInt(newMinute)]) }), format === '12hr' && (_jsx(Selector, { options: AmPmOptions, selectedIndex: isHourPm ? 1 : 0, onSelect: handleAmPmChange, scrollToSelected: false }))] }), _jsxs(Card, { className: styles.infoBox, children: ["All times are ", timeZone, " time"] })] }));
}
