import { isString } from 'lodash/fp';
export function getFieldFromChargebeeError(message) {
    if (!isString(message)) {
        return null;
    }
    if (isVatNumberRelated(message)) {
        return 'vatNumber';
    }
    // Sample message: 'billing_address[zip] : invalid zip/postal code'
    const regex = new RegExp('(billing_address)([[a-z]+])', 'i');
    const matches = message.match(regex);
    const field = matches && isString(matches[2]) ? matches[2].slice(1, -1) : null;
    return field;
}
/**
 * Handle special cases like 'billing_address[country] : VAT Number not applicable for this country'.
 * @param {string} message
 */
function isVatNumberRelated(message) {
    return message.startsWith('vat_number') || message.includes('VAT Number not applicable');
}
