// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CompanySettings-hourSelect-20uj2 {\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  display: inline-block;\n  vertical-align: bottom;\n}\n.CompanySettings-defaultCategoriesButton-JeGWv {\n  margin-right: 10px;\n}\n.CompanySettings-deleteCompanyButton-pM_IU {\n  margin-bottom: 10px;\n}\n.CompanySettings-pickerMiddle-mQc1h {\n  margin-left: 10px;\n  margin-right: 10px;\n}\n.CompanySettings-pickerStart-3iL3K {\n  margin-right: 10px;\n}\n.CompanySettings-flexAlignCenter-2BoeH {\n  display: flex;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"hourSelect": "CompanySettings-hourSelect-20uj2",
	"defaultCategoriesButton": "CompanySettings-defaultCategoriesButton-JeGWv",
	"deleteCompanyButton": "CompanySettings-deleteCompanyButton-pM_IU",
	"pickerMiddle": "CompanySettings-pickerMiddle-mQc1h",
	"pickerStart": "CompanySettings-pickerStart-3iL3K",
	"flexAlignCenter": "CompanySettings-flexAlignCenter-2BoeH"
};
module.exports = exports;
