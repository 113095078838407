import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators, selectors } from '../ducks';
import NotificationsList from './presenter';
const mapStateToProps = state => {
    return {
        notifications: selectors.getNotifications(state),
        isLoading: selectors.getIsLoading(state),
    };
};
const mapDispatchToProps = dispatch => {
    const { doGetNotifications } = actionCreators;
    return {
        actions: bindActionCreators({ doGetNotifications }, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
