import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { map } from 'lodash';
import LoadingContentIndicator from '../../components/LoadingContentIndicator';
import PageTitle from '../../components/PageTitle';
import { withData } from '../../hoc/withData';
import { getCurrentUser } from '../../services/CurrentUser';
import { getTeamMembersWithProfileUrl } from '../../services/User';
import styles from './style.m.less';
function PeopleTab({ teamMembers }) {
    const listClasses = classNames('nakedList', styles.userList);
    return (_jsxs("section", { className: styles.peopleTab, children: [_jsx(PageTitle, { title: "Your Team" }), _jsx("ul", { className: listClasses, children: map(teamMembers, u => (_jsx(User, { user: u }, u.id))) })] }));
}
function User({ user }) {
    const itemClasses = classNames(styles.userItem);
    return (_jsx("li", { className: itemClasses, children: _jsxs("a", { href: user.profileUrl, className: styles.userShortInfo, children: [_jsx("img", { className: styles.userImage, src: user.logo, alt: user.name }), _jsx("p", { className: styles.userName, children: user.name })] }) }));
}
export default withData({
    resolve: {
        teamMembers: () => {
            const currentUser = getCurrentUser();
            return getTeamMembersWithProfileUrl(currentUser);
        },
    },
    pendingComponent: () => _jsx(LoadingContentIndicator, {}),
})(PeopleTab);
