// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DoubleConfirmationDialog-confirmbox-1kMpZ input {\n  max-width: 320px;\n  padding: 10px;\n  margin-bottom: 20px;\n}\n.DoubleConfirmationDialog-confirmbox-1kMpZ > div {\n  margin-bottom: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"confirmbox": "DoubleConfirmationDialog-confirmbox-1kMpZ"
};
module.exports = exports;
