import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import HrList from '../../../components/HrList';
import Modal from '../../../components/Modal';
import { WithModal } from '../../../hoc/withModal';
import { getCurrentUser } from '../../../services/CurrentUser';
export default function WithHrListSupportModal({ children }) {
    return (_jsx(WithModal, { title: "Need Support?", size: "LG_WIDTH", renderModalContent: HrListSupport, children: children }));
}
export function HrListSupport() {
    const user = getCurrentUser();
    return (_jsxs(_Fragment, { children: [_jsx("p", { children: "If you need help using Small Improvements, please get in touch with your company's account administrators." }), !user.isGuest && _jsx(HrList, { includeAssistants: true })] }));
}
export function HrListSupportModal({ isOpen, onClose }) {
    return (_jsx(Modal, { title: "Need Support?", isOpen: isOpen, onClose: onClose, size: "LG_WIDTH", children: _jsx(HrListSupport, {}) }));
}
