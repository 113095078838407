import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Route } from 'react-router';
import { compose, setPropTypes } from 'recompose';
import r from '../../../react/services/Replacement';
import * as ROUTES from '../../../routes';
import LoadingIndicatorFull from '../../components/LoadingIndicatorFull';
import NavigationTabs from '../../components/NavigationTabs';
import PageHeader from '../../components/PageHeader';
import PageStatic from '../../components/PageStatic';
import SwitchDefaultRoute from '../../components/SwitchDefaultRoute';
import SwitchErrorRoute from '../../components/SwitchErrorRoute';
import { withAppContext } from '../../hoc/withAppContext';
import { withData } from '../../hoc/withData';
import { useAppContext } from '../../hooks/useAppContext';
import { getAngularChildRoutes } from '../../services/AngularRoute';
import { isFeatureEnabled } from '../../services/Features';
import { isReviewerInCycles } from '../../services/ReviewCycles';
import { hasSecondaryReports, isManager } from '../../services/User';
import ActivityStreamTab from '../ActivityStream/tab';
import AnytimeFeedbackTeamInsights from '../AnytimeFeedback/pages/AnytimeFeedbackAdminPage/AnytimeFeedbackInsights/pages/AnytimeFeedbackTeamInsights/lazy';
import MeetingsDashboard from '../MeetingsDashboard/lazy';
import PeopleTab from '../PeopleTab';
import RequestedFeedbackTeamOverview from '../RequestFeedbackView/tabs/RequestedFeedbackTeamOverview/lazy';
export default compose(setPropTypes({
    match: PropTypes.object.isRequired,
}), withAppContext, withData({
    resolve: {
        currentUserHasReviewersInCycles: compose(isReviewerInCycles, get('appContext.user')),
    },
    shouldRefetch: (props, nextProps) => props.appContext.user.id !== nextProps.appContext.user.id,
}))(TeamPage);
const ActivityStream = () => _jsx(ActivityStreamTab, { mode: "TEAM" });
const MeetingsDashboardTab = () => _jsx(MeetingsDashboard, { mode: "TEAM" });
const Objectives = ({ match }) => {
    const { user } = useAppContext();
    const routes = getAngularChildRoutes([
        ROUTES.YOUR_TEAM_OBJECTIVES_BROWSER,
        ROUTES.YOUR_TEAM_OBJECTIVES_OVERVIEW,
        ROUTES.YOUR_TEAM_OBJECTIVES_SELECT_CYCLE,
    ]);
    if (isManager(user)) {
        routes.reverse();
    }
    return _jsx(SwitchDefaultRoute, { defaultPath: match.path, children: routes });
};
const Feedback = () => {
    const routes = getAngularChildRoutes([
        ROUTES.YOUR_TEAM_FEEDBACK_SELECT_CYCLE,
        ROUTES.YOUR_TEAM_FEEDBACK_WITH_CYCLE,
    ]);
    return _jsx(SwitchErrorRoute, { children: routes });
};
const Reviews = () => {
    const routes = getAngularChildRoutes([ROUTES.YOUR_TEAM_REVIEWS_SELECT_CYCLE, ROUTES.YOUR_TEAM_REVIEWS_WITH_CYCLE]);
    return _jsx(SwitchErrorRoute, { children: routes });
};
function TeamPage({ match, appContext, currentUserHasReviewersInCycles }) {
    const { user } = appContext;
    const tabs = [
        {
            label: 'Activities',
            link: ROUTES.YOUR_TEAM_ACTIVITY,
            visible: true,
        },
        {
            label: r('meeting.name.plural'),
            link: ROUTES.YOUR_TEAM_MEETINGS,
            visible: isManager(user),
            feature: 'meetings',
        },
        {
            label: r('objective.plural.big'),
            link: ROUTES.YOUR_TEAM_OBJECTIVES,
            visible: true,
            feature: 'objective',
        },
        {
            label: r('fr.review.singular.title'),
            link: ROUTES.YOUR_TEAM_FEEDBACK_SELECT_CYCLE,
            visible: isManager(user),
            feature: 'feedback',
        },
        {
            label: isFeatureEnabled('FEATURE_anytimeFeedbackGive') ? r('anyfb.name') : 'Feedback Requests',
            link: ROUTES.YOUR_TEAM_FEEDBACK_REQUESTED,
            visible: isManager(user),
            feature: 'requestFeedback',
        },
        {
            label: r('pr.plural.short'),
            link: ROUTES.YOUR_TEAM_REVIEWS_SELECT_CYCLE,
            visible: isManager(user) || hasSecondaryReports(user) || currentUserHasReviewersInCycles,
            feature: 'review',
        },
        {
            label: 'People',
            link: ROUTES.YOUR_TEAM_USERS,
            visible: true,
        },
    ];
    return (_jsxs("div", { children: [_jsx(PageStatic, { children: _jsx(PageHeader, { title: "Your Team" }) }), _jsx(NavigationTabs, { tabs: tabs }), _jsx(Suspense, { fallback: _jsx(LoadingIndicatorFull, {}), children: _jsxs(SwitchDefaultRoute, { defaultPath: match.path, children: [_jsx(Route, { exact: true, path: ROUTES.YOUR_TEAM_ACTIVITY, render: ActivityStream }), _jsx(Route, { path: ROUTES.YOUR_TEAM_MEETINGS, component: MeetingsDashboardTab }), _jsx(Route, { path: ROUTES.YOUR_TEAM_OBJECTIVES, component: Objectives }), _jsx(Route, { path: ROUTES.YOUR_TEAM_FEEDBACK_SELECT_CYCLE, component: Feedback }), _jsx(Route, { path: ROUTES.YOUR_TEAM_FEEDBACK_REQUESTED, component: isFeatureEnabled('FEATURE_anytimeFeedbackTeamInsights')
                                ? AnytimeFeedbackTeamInsights
                                : RequestedFeedbackTeamOverview }), _jsx(Route, { path: ROUTES.YOUR_TEAM_REVIEWS_SELECT_CYCLE, component: Reviews }), _jsx(Route, { exact: true, path: ROUTES.YOUR_TEAM_USERS, component: PeopleTab })] }) })] }));
}
