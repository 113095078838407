import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { compose } from 'recompose';
import AlertBox from '../../../../components/AlertBox';
import LoadingBar from '../../../../components/LoadingBar';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { withData } from '../../../../hoc/withData';
import { compileAngular, getAngularService } from '../../../../services/AngularService';
import { observablePollUntil } from '../../../../services/Poll';
const Loader = () => _jsx(LoadingBar, { label: "Loading preview\u2026" });
Preview.propTypes = {
    preview: PropTypes.object,
    goToHistory: PropTypes.func,
};
export default compose(withData({
    resolve: {
        previewId: ({ apiImport, companyId }) => apiImport.startPreview(companyId).then(({ id }) => id),
    },
    pendingComponent: Loader,
}), withData({
    observe: {
        preview: ({ apiImport, previewId }) => observablePollUntil(() => apiImport.getPreview(previewId), 1000, result => { var _a; return (_a = result === null || result === void 0 ? void 0 : result.entries) === null || _a === void 0 ? void 0 : _a.length; }),
    },
    resolve: {
        _components: () => Promise.all([
            getAngularService(() => import(
            /* webpackChunkName: 'AdminUserImportPage' */ '../../../../../screens/admin/user-import/import-table/import-table-directive')),
            getAngularService(() => import(
            /* webpackChunkName: 'AdminUserImportPage' */ '../../../../../screens/admin/user-import/import-table/import-statistics/import-statistics-directive')),
        ]),
    },
    pendingComponent: Loader,
}))(Preview);
const ngImportStatsTemplate = `<import-statistics entries="$ctrl.preview.entries"></import-statistics>`;
function Preview({ apiImport, preview, goToHistory, name, companyId }) {
    const isImportDisabled = !!companyId;
    const showCredentialError = preview.state === 'failed_wrong_configuration';
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [importInProgress, setImportInProgress] = useState(false);
    const doImport = useCallback(() => apiImport.doImport().then(() => {
        setShowConfirmModal(false);
        setImportInProgress(true);
    }), [apiImport]);
    const doShowConfirmationModal = useCallback(() => setShowConfirmModal(true), []);
    const doHideConfirmationModal = useCallback(() => setShowConfirmModal(false), []);
    const doHideInProgressModal = useCallback(() => setImportInProgress(false), []);
    const confirmModalProps = {
        title: `Run Import`,
        body: compileAngular(ngImportStatsTemplate, { preview }),
        size: 'MD_WIDTH',
        confirmLabel: 'Start Import',
        cancelLabel: 'Cancel',
        isOpen: showConfirmModal,
        onConfirm: doImport,
        onClose: doHideConfirmationModal,
    };
    const inProgressModalProps = {
        title: `Import in progress`,
        body: _jsx("p", { children: "The import was started. It should appear on the history tab in about one minute." }),
        size: 'MD_WIDTH',
        confirmLabel: 'Go to history',
        cancelLabel: 'Close',
        isOpen: importInProgress,
        onConfirm: goToHistory,
        onClose: doHideInProgressModal,
    };
    return (_jsxs("div", { children: [_jsx(ConfirmModal, Object.assign({}, confirmModalProps)), _jsx(ConfirmModal, Object.assign({}, inProgressModalProps)), showCredentialError ? (_jsx(AlertBox, { colour: "RED", children: "The preview failed because of a connection error. Please make sure your credentials are correct." })) : (_jsx(ImportTable, { name: name, preview: preview, showModal: doShowConfirmationModal, isImportDisabled: isImportDisabled }))] }));
}
function ImportTable({ name, preview, showModal, isImportDisabled }) {
    const template = `<import-table name="${name}"
        import-result="$ctrl.preview"
        import-action="$ctrl.importAction">
    </import-table>`;
    return compileAngular(template, {
        preview,
        importAction: { fn: showModal, disabled: isImportDisabled },
    });
}
