import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Provider } from 'react-redux';
import store from '../../../store';
import PageHeader from '../../PageHeader';
import PageStatic from '../../PageStatic';
import PageStaticSection from '../../PageStaticSection';
import PageTitle from '../../PageTitle';
import NotificationsList from '../NotificationsList';
import NotificationsMarkAllAsReadButton from '../NotificationsMarkAllAsReadButton';
import styles from './style.m.less';
export default function NotificationsPage() {
    const title = 'Your Notifications';
    return (_jsx(Provider, { store: store, children: _jsxs(PageStatic, { children: [_jsx(PageTitle, { title: title }), _jsx(PageHeader, { title: title }), _jsxs(PageStaticSection, { children: [_jsx("div", { className: styles.page_actions, children: _jsx(NotificationsMarkAllAsReadButton, {}) }), _jsx("div", { className: styles.list, children: _jsx(NotificationsList, {}) })] })] }) }));
}
