import PropTypes from 'prop-types';
import { pure } from '../../hoc/recompose';
import { pageTitleService } from '../../services/PageTitle';
export default pure(PageTitle);
function PageTitle({ title }) {
    pageTitleService.setPageTitle(title);
    return null;
}
PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};
