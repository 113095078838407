import { jsx as _jsx } from "react/jsx-runtime";
import { curry } from 'lodash';
import { Component, createElement } from 'react';
import LoadingContentIndicator from '../../components/LoadingContentIndicator';
import { pure } from '../../hoc/recompose';
import { reducerInjector, sagaInjector } from '../../store';
class LazyLoadComponent extends Component {
    constructor(props) {
        super(props);
        this.safeSetState = (...args) => {
            if (this.unmounted) {
                return;
            }
            this.setState(...args);
        };
        this.state = {
            component: null,
        };
        this.unmounted = false;
    }
    componentDidMount() {
        this.props.getComponent().then(component => this.safeSetState({ component }));
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    render() {
        const { originalProps, pendingComponent } = this.props;
        if (this.state.component) {
            return createElement(this.state.component, originalProps);
        }
        else {
            return pendingComponent ? createElement(pendingComponent) : null;
        }
    }
}
export const lazyLoadComponent = curry((pendingComponent, getComponent) => {
    const lazyComponent = pure(originalProps => {
        const props = { pendingComponent, originalProps, getComponent };
        return createElement(LazyLoadComponent, props);
    });
    lazyComponent.preload = getComponent;
    return lazyComponent;
});
// empty element instead of null, because when used in an angular component it throws an (harmless) error in the console
export const lazyLoadComponentSilent = lazyLoadComponent(() => _jsx("span", {}));
export const lazyLoadContentComponent = lazyLoadComponent(LoadingContentIndicator);
const resolvedBundles = [];
const isFirstLoad = bundle => resolvedBundles.indexOf(bundle) === -1;
export const loadReducersAndSagas = bundle => {
    if (isFirstLoad(bundle)) {
        if (bundle.reducers) {
            reducerInjector.inject(bundle.reducers);
        }
        if (bundle.sagas) {
            sagaInjector.inject(bundle.sagas);
        }
        resolvedBundles.push(bundle);
    }
    return bundle;
};
export const loadBundle = bundle => loadReducersAndSagas(bundle).default;
