import { maxBy, minBy } from 'lodash/fp';
import history from '../../routing/history';
import { trackError } from './TrackJs';
const ALWAYS_END_AFTER_MS = 30000;
function startPerformanceMeasurement(state) {
    try {
        if (!state || !state.observer) {
            return;
        }
        state.events = [];
        state.startTime = window.performance.now();
        if (state.timeoutHandle) {
            window.clearTimeout(state.timeoutHandle);
        }
        state.timeoutHandle = window.setTimeout(() => endPerformanceMeasurement(state), ALWAYS_END_AFTER_MS);
    }
    catch (error) {
        trackError(error);
    }
}
function applyCallback(state, start, end, initialLoad) {
    if (state.onMeasurementCallback) {
        state.onMeasurementCallback(end - start, initialLoad);
    }
}
function endPerformanceMeasurement(state) {
    try {
        const events = state.events;
        const initialLoad = state.initialLoad;
        let start = state.startTime;
        state.events = [];
        state.startTime = undefined;
        if (state.timeoutHandle) {
            window.clearTimeout(state.timeoutHandle);
            state.timeoutHandle = undefined;
        }
        state.initialLoad = false;
        const firstEvent = minBy(event => event.startTime, events);
        const lastEvent = maxBy(event => event.startTime + event.duration, events);
        if (start && firstEvent && lastEvent) {
            start = Math.min(start, firstEvent.startTime);
            const end = lastEvent.startTime + lastEvent.duration;
            applyCallback(state, start, end, initialLoad);
            return;
        }
    }
    catch (error) {
        trackError(error);
    }
}
export function initPerformanceTracking(callback) {
    try {
        if ('PerformanceLongTaskTiming' in window) {
            if (!history) {
                return;
            }
            const state = {
                events: [],
                startTime: window.performance.now(),
                initialLoad: true,
                observer: new window.PerformanceObserver(measurement => {
                    // We only add events from our own frame to avoid long tasks not triggered by page navigation.
                    // https://www.w3.org/TR/longtasks/#performancelongtasktiming
                    state.events = state.events.concat(measurement.getEntries().filter(event => event.name === 'self'));
                }),
                onMeasurementCallback: callback,
            };
            state.observer.observe({ entryTypes: ['longtask'] });
            history.listen(() => startPerformanceMeasurement(state));
            const originalPush = history.push;
            history.push = function (...args) {
                endPerformanceMeasurement(state);
                return originalPush.apply(this, args);
            };
            startPerformanceMeasurement(state);
        }
    }
    catch (error) {
        trackError(error);
    }
}
