import classNames from 'classnames';
import moment from 'moment';
import { TrackJS } from 'trackjs';
import history from '../../routing/history';
import { getCurrentUser } from './CurrentUser';
import { isFeatureEnabledForUser } from './Features';
let initPromise;
const shouldEnableAppcues = (user) => isFeatureEnabledForUser('FEATURE_appcues', user) &&
    !user.company.companySettings.optOutExternalServicesDataSync &&
    !user.isGuest &&
    (user.isHR || user.isAssistant || !!user.reports.length);
const initInternal = async (currentUser) => {
    if (!shouldEnableAppcues(currentUser)) {
        return;
    }
    let refreshTimeoutId;
    let latestUpdate = Date.now();
    let appcuesUser = await loadAppcuesUser();
    const appcues = await loadScript(appcuesUser.accountId);
    appcues.identify(appcuesUser.id, getAppcuesProperties(currentUser));
    appcues.on('flow_aborted', trackError);
    appcues.on('step_aborted', trackError);
    const historyUnsubscribe = history === null || history === void 0 ? void 0 : history.listen(() => appcues.page());
    const scheduleRefreshAppcuesUser = async () => {
        const clearScheduledUpdates = () => {
            window.clearTimeout(refreshTimeoutId);
            document.removeEventListener('visibilitychange', scheduleRefreshAppcuesUser);
        };
        const refreshAppcuesUser = async () => {
            clearScheduledUpdates();
            latestUpdate = Date.now();
            try {
                const newUser = await loadAppcuesUser();
                appcuesUser = newUser;
                scheduleRefreshAppcuesUser();
            }
            catch (_a) {
                appcues.reset();
                historyUnsubscribe === null || historyUnsubscribe === void 0 ? void 0 : historyUnsubscribe();
            }
        };
        const updateOrSchedule = async () => {
            const now = Date.now();
            const aimedForTimeToNextUpdate = new Date(appcuesUser.expiresAt).getTime() - now;
            // To protect against accidental self-DOS, we enforce a minimum interval of 10min
            const minimalTimeToNextUpdate = latestUpdate + 10 * 60 * 1000 - now;
            const timeToNextUpdate = Math.max(aimedForTimeToNextUpdate, minimalTimeToNextUpdate);
            if (timeToNextUpdate < 0) {
                await refreshAppcuesUser();
            }
            else {
                const maxInt = 2147483647;
                refreshTimeoutId = window.setTimeout(() => refreshAppcuesUser(), Math.min(timeToNextUpdate, maxInt));
            }
        };
        clearScheduledUpdates();
        if (document.visibilityState === 'visible') {
            updateOrSchedule();
        }
        document.addEventListener('visibilitychange', scheduleRefreshAppcuesUser);
    };
    scheduleRefreshAppcuesUser();
};
function loadScript(accountId) {
    if (!initPromise) {
        initPromise = new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://fast.appcues.com/${accountId}.js`;
            script.onload = () => {
                // @ts-ignore Appcues actually exists globally, but we're not adding it in globals.d.ts because people
                // should not access it from  window directly, but always through load() here
                resolve(window.Appcues);
            };
            script.onerror = () => {
                reject();
            };
            document.body.appendChild(script);
        });
    }
    return initPromise;
}
async function loadAppcuesUser() {
    const appcuesUser = await fetch('/api/v2/appcues-users/me')
        .then(res => (res.ok ? res.json() : Promise.reject()))
        .then(data => ((data === null || data === void 0 ? void 0 : data.token) !== null && (data === null || data === void 0 ? void 0 : data.expiresAt) !== null && (data === null || data === void 0 ? void 0 : data.accountId) !== null && data) ||
        Promise.reject());
    // @ts-ignore
    window.AppcuesSettings = Object.assign(Object.assign({}, (window.AppcuesSettings || {})), { userIdSignature: appcuesUser.token });
    return appcuesUser;
}
function getAppcuesProperties(user) {
    return {
        companyId: user.company.id,
        companyName: user.company.name,
        isHR: user.isHR,
        isAdmin: user.isAdmin,
        isAssistant: user.isAssistant,
        isManager: user.reports.length > 0,
        previousLoginDate: user.previousLoginDate,
        activeReports: user.reports.filter(u => u.isActive).length,
        isSmallImprovements: user.isSmallImprovements,
        featureFlags: classNames(user.company.enabledFeatures),
        userCount: user.company.userCount,
        isNonProfit: user.company.isNonProfit,
        userCreated: user.dateCreated,
        companyCreated: user.company.dateCreated,
        isInTrial: user.company.payment.trialUntil && moment(user.company.payment.trialUntil).isAfter(),
        licenseOveragesSinceDays: null, // No longer sent as it's not used
        featReview: user.company.enabledFeatures.review,
        featFeedback: user.company.enabledFeatures.feedback,
        featPraise: user.company.enabledFeatures.praise,
        featMessage: user.company.enabledFeatures.message,
        featObjective: user.company.enabledFeatures.objective,
        featMeetings: user.company.enabledFeatures.meetings,
        featSurveys: user.company.enabledFeatures.surveys,
        featRetention: user.company.enabledFeatures.retention,
        featAutomations: user.company.enabledFeatures.automations,
        featCalendarIntegration: user.company.enabledFeatures.FEATURE_calendarIntegration,
        featRestApi: user.company.enabledFeatures.FEATURE_restApi,
        featCycleAdmins: user.company.enabledFeatures.FEATURE_cycleAdmins,
        featAnytimeFbGive: user.company.enabledFeatures.FEATURE_anytimeFeedbackGive,
        featSecManagerObjAccess: user.company.enabledFeatures.FEATURE_secondaryManagerObjectiveAccess,
        featSecManagerFbAccess: user.company.enabledFeatures.FEATURE_secondaryManagerFeedbackAccess,
        featSlack: user.company.enabledFeatures.slack,
        featSecondaryOverallRating: user.company.enabledFeatures.FEATURE_secondaryOverallRating,
    };
}
function trackError(event) {
    TrackJS.console.error(event.error, event.details, event);
}
function delay(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}
export function initAppcues(currentUser = getCurrentUser()) {
    return delay(250)
        .then(() => initInternal(currentUser))
        .catch(() => { });
}
