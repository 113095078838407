import { REVIEW_CYCLE_EDIT, YOUR_COMPANY_REVIEWS_WITH_CYCLE } from '../../../../routes';
import { getAdminRouteForNavigation } from '../../../../routes-pairs';
import { goToUrl } from '../../../services/Router';
import { interpolateAppUrl } from '../../../services/Url';
export const getPreviewQuestionnaireActionUrls = cycleId => ({
    CYCLE_SETTINGS: interpolateAppUrl(getAdminRouteForNavigation(REVIEW_CYCLE_EDIT), { cycleId }),
    CYCLE_OVERVIEW: interpolateAppUrl(getAdminRouteForNavigation(YOUR_COMPANY_REVIEWS_WITH_CYCLE), { cycleId }),
});
export const getCompanyCycleUrl = cycleId => interpolateAppUrl(getAdminRouteForNavigation(YOUR_COMPANY_REVIEWS_WITH_CYCLE), { cycleId });
export const getTeamCycleUrl = (userId, cycleId) => `/app/team/reviews/${userId}/${cycleId}`;
export const getEditUrl = (id, options = {}) => `/app/reviews/edit/${id}${options.editAs ? `?editAs=${options.editAs}` : ''}`;
export const getViewUrl = id => `/app/reviews/view/${id}`;
export const redirectToViewOnAction = (review, assessmentType, actionType) => {
    goToUrl(getViewUrl(review.id), { assessmentType, actionType });
};
