// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CollectFeedbackHeaderBox-icon-32QLU {\n  font-size: 15px;\n  --fi-size: 18px;\n}\n.CollectFeedbackHeaderBox-linkButton-2tHB3 {\n  text-decoration: none !important;\n}\n.CollectFeedbackHeaderBox-feedbackAlertText-25vql {\n  display: inline;\n  margin: 0 10px 0 5px;\n  font-weight: 400;\n  --fi-stroke: var(--fi-stroke-s);\n}\n", ""]);
// Exports
exports.locals = {
	"icon": "CollectFeedbackHeaderBox-icon-32QLU",
	"linkButton": "CollectFeedbackHeaderBox-linkButton-2tHB3",
	"feedbackAlertText": "CollectFeedbackHeaderBox-feedbackAlertText-25vql"
};
module.exports = exports;
