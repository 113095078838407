import { compose, padCharsStart, rangeStep, split, toString } from 'lodash/fp';
export const addLeadingZero = padCharsStart('0', 2);
const removeSuffix = (value) => {
    if (value === undefined) {
        return value;
    }
    return value.replace(/AM|PM/i, '').trim();
};
// Pad with zeros i.e 1 -> '01'
export const toOption = compose(addLeadingZero, toString);
export const HOURS_12 = rangeStep(1, 12, 24)
    .map(n => (n > 12 ? n - 12 : n))
    .map(toOption);
export const HOURS_24 = rangeStep(1, 0, 24).map(toOption);
export const MINUTES_ZERO = ['00'];
export const MINUTES = rangeStep(1, 0, 60).map(toOption);
export const MINUTES_5 = rangeStep(5, 0, 60).map(toOption);
export const MINUTES_10 = rangeStep(10, 0, 60).map(toOption);
export const MINUTES_15 = rangeStep(15, 0, 60).map(toOption);
const isNotANumber = compose(isNaN, parseInt);
const splitParts = compose(split(':'), toString);
const isValid = (hours, minutes) => {
    return hours !== null && HOURS_24.includes(hours) && minutes !== null && MINUTES.includes(minutes);
};
export const toHoursMinutes = (value) => {
    if (!value) {
        return [null, null];
    }
    const isPm = value && value.toUpperCase().endsWith('PM');
    const parts = splitParts(removeSuffix(value));
    if (parts.length !== 2) {
        return [null, null];
    }
    return parts
        .map(part => {
        return part.length !== 2 || isNotANumber(part, undefined) ? null : part;
    })
        .map((n, idx) => {
        if (n === null) {
            return n;
        }
        const num = parseInt(n);
        if (idx === 0 && isPm && num < 12) {
            return num + 12;
        }
        return num;
    });
};
export const isValidTime = (value, allowEmpty) => {
    if (value === '' && allowEmpty) {
        return true;
    }
    const [hours, minutes] = toHoursMinutes(value).map(n => (n !== null ? toOption(n) : n));
    return isValid(hours, minutes);
};
export const toValidTime = (value) => {
    if (!value) {
        return undefined;
    }
    const [hours, minutes] = toHoursMinutes(value).map(toOption);
    return isValid(hours, minutes) ? `${hours}:${minutes}` : undefined;
};
export const isPm = (hours) => hours >= 12;
export const format12Hour = (value) => {
    const [hours, minutes] = toHoursMinutes(value);
    if (hours === null || minutes === null) {
        return '';
    }
    const isHourPM = isPm(hours);
    return [isHourPM && hours > 12 ? hours - 12 : hours, minutes].map(toOption).join(':') + (isHourPM ? ' PM' : ' AM');
};
