import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Route, useRouteMatch } from 'react-router';
import r from '../../../react/services/Replacement';
import * as ROUTES from '../../../routes';
import LoadingIndicatorFull from '../../components/LoadingIndicatorFull';
import NavigationTabs from '../../components/NavigationTabs';
import PageHeader from '../../components/PageHeader';
import PageStatic from '../../components/PageStatic';
import SwitchDefaultRoute from '../../components/SwitchDefaultRoute';
import SwitchErrorRoute from '../../components/SwitchErrorRoute';
import { useAppContext } from '../../hooks/useAppContext';
import { getAngularChildRoutes } from '../../services/AngularRoute';
import { isFeatureEnabled, isFeatureEnabledForUser } from '../../services/Features';
import { hasBasicHr, isAnyAdmin } from '../../services/User';
import ActivityStreamTab from '../ActivityStream/tab';
import MeetingTemplatesCreate from '../MeetingTemplates/Create/lazy';
import MeetingTemplatesEdit from '../MeetingTemplates/Edit/lazy';
import MeetingTemplatesListUserLegacy from '../MeetingTemplates/ListUserLegacy';
import ObjectiveHierarchyView from '../Objectives/HierarchyView/lazy';
import SharedMeetingDedicated from '../SharedMeetings/Dedicated/lazy';
import SharedMeetingsList from '../SharedMeetings/List/lazy';
import SharedMeetingsOverview from '../SharedMeetings/Overview/lazy';
import SurveyDashboard from '../Surveys/pages/SurveyDashboard/lazy';
import SurveyOverview from '../Surveys/pages/SurveyOverview/lazy';
import { useShowSurveysTab } from '../Surveys/services/useShowSurveysTab';
export const showObjectiveAnalyzeInCompanyMode = user => !hasBasicHr(user) && isFeatureEnabled('FEATURE_objectiveAnalyzeViewForICs');
const Objectives = ({ match }) => {
    const { user } = useAppContext();
    const routes = [ROUTES.YOUR_COMPANY_OBJECTIVES_BROWSER];
    if (showObjectiveAnalyzeInCompanyMode(user)) {
        routes.push(ROUTES.YOUR_COMPANY_OBJECTIVES_OVERVIEW, ROUTES.YOUR_COMPANY_OBJECTIVES_SELECT_CYCLE);
    }
    const angularRoutes = getAngularChildRoutes(routes);
    if (hasBasicHr(user)) {
        angularRoutes.reverse();
    }
    return (_jsxs(SwitchDefaultRoute, { defaultPath: match.path, children: [angularRoutes, isFeatureEnabledForUser('FEATURE_objectiveHierarchyView', user) && (_jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_OBJECTIVES + '/hierarchy', component: ObjectiveHierarchyView }))] }));
};
const Meetings = ({ match }) => {
    return (_jsxs(SwitchDefaultRoute, { defaultPath: match.path, excludedPaths: [ROUTES.YOUR_COMPANY_MEETINGS_MY_TEMPLATES_CREATE], children: [isFeatureEnabled('FEATURE_meetingUserTemplates') && (_jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_MEETINGS_MY_TEMPLATES, render: () => _jsx(MeetingTemplatesListUserLegacy, {}) })), isFeatureEnabled('FEATURE_meetingUserTemplates') && (_jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_MEETINGS_MY_TEMPLATES_CREATE, render: props => _jsx(MeetingTemplatesCreate, Object.assign({}, props, { type: "USER" })) })), isFeatureEnabled('FEATURE_meetingUserTemplates') && (_jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_MEETINGS_MY_TEMPLATES_EDIT, render: props => _jsx(MeetingTemplatesEdit, Object.assign({}, props, { type: "USER" })) })), _jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_MEETINGS_SHARED, component: SharedMeetingsOverview }), _jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_MEETINGS_SHARED_PAGE, render: props => _jsx(SharedMeetingsList, { userIds: props.match.params.userIds }) }), _jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_MEETINGS_SHARED_DEDICATED, render: props => _jsx(SharedMeetingDedicated, { meetingId: props.match.params.meetingId }) })] }));
};
const Feedback = () => {
    const routes = getAngularChildRoutes([
        ROUTES.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE,
        ROUTES.YOUR_COMPANY_FEEDBACK_WITH_CYCLE,
    ]);
    return _jsx(SwitchErrorRoute, { children: routes });
};
const Reviews = () => {
    const routes = getAngularChildRoutes([
        ROUTES.YOUR_COMPANY_REVIEWS_SELECT_CYCLE,
        ROUTES.YOUR_COMPANY_REVIEWS_WITH_CYCLE,
    ]);
    return _jsx(SwitchErrorRoute, { children: routes });
};
const Messages = () => {
    const route = getAngularChildRoutes([ROUTES.YOUR_COMPANY_MESSAGES]);
    return _jsx(SwitchErrorRoute, { children: route });
};
const Directory = () => {
    const route = getAngularChildRoutes([ROUTES.YOUR_COMPANY_USERS]);
    return _jsx(SwitchErrorRoute, { children: route });
};
function hasAnyCycleAdminRights(user, cycleType) {
    const rights = user.cycleAdminRights || {};
    const forCycle = rights[cycleType] || [];
    return !!forCycle.length;
}
export default function CompanyPage() {
    const match = useRouteMatch();
    const { user } = useAppContext();
    const showSurveys = useShowSurveysTab();
    const showFeedback = !hasBasicHr(user) && hasAnyCycleAdminRights(user, 'feedbackCycles');
    const showReviews = !hasBasicHr(user) && hasAnyCycleAdminRights(user, 'reviewCycles');
    const tabs = [
        {
            label: 'Activities',
            link: ROUTES.YOUR_COMPANY_ACTIVITY,
            visible: true,
        },
        {
            label: r('meeting.name.plural'),
            link: ROUTES.YOUR_COMPANY_MEETINGS,
            visible: (user.permissions.canManageMeetingTemplates ||
                user.permissions.canViewMeetingInsights ||
                user.permissions.canViewSharedMeetings) &&
                !isFeatureEnabled('FEATURE_meetingNewNavigation'),
            feature: 'meetings',
        },
        {
            label: r('surveys.plural.big'),
            link: ROUTES.YOUR_COMPANY_SURVEYS,
            visible: showSurveys,
            feature: 'surveys',
        },
        {
            label: r('objective.plural.big'),
            link: ROUTES.YOUR_COMPANY_OBJECTIVES,
            visible: true,
            feature: 'objective',
        },
        {
            label: r('fr.review.singular.title'),
            link: ROUTES.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE,
            visible: showFeedback,
            feature: 'feedback',
        },
        {
            label: r('pr.plural.short'),
            link: ROUTES.YOUR_COMPANY_REVIEWS_SELECT_CYCLE,
            visible: showReviews,
            feature: 'review',
        },
        {
            label: r('messages.menu.title'),
            link: ROUTES.YOUR_COMPANY_MESSAGES,
            visible: true,
            feature: 'message',
        },
        {
            label: 'Directory',
            link: ROUTES.YOUR_COMPANY_USERS,
            visible: !isAnyAdmin(user),
        },
    ];
    return (_jsxs("div", { children: [_jsx(PageStatic, { className: "no-print", children: _jsx(PageHeader, { title: "Your Company" }) }), _jsx(NavigationTabs, { className: "no-print", tabs: tabs, baseUrl: match.url }), _jsx(Suspense, { fallback: _jsx(LoadingIndicatorFull, {}), children: _jsxs(SwitchDefaultRoute, { defaultPath: match.path, children: [_jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_ACTIVITY, component: ActivityStreamTab }), _jsx(Route, { path: ROUTES.YOUR_COMPANY_MEETINGS, component: Meetings }), _jsx(Route, { path: ROUTES.YOUR_COMPANY_OBJECTIVES, component: Objectives }), showReviews && _jsx(Route, { path: ROUTES.YOUR_COMPANY_REVIEWS_SELECT_CYCLE, component: Reviews }), showFeedback && _jsx(Route, { path: ROUTES.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE, component: Feedback }), _jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_SURVEYS, component: SurveyOverview }), isFeatureEnabled('surveys') && showSurveys && (_jsx(Route, { path: ROUTES.YOUR_COMPANY_SURVEYS_WITH_SURVEY, render: props => (
                            // We need the key to re-initialize the page upon jumping to a different survey.
                            _jsx(SurveyDashboard, { surveyId: props.match.params.surveyId }, props.match.params.surveyId)) })), _jsx(Route, { exact: true, path: ROUTES.YOUR_COMPANY_MESSAGES, component: Messages }), _jsx(Route, { path: ROUTES.YOUR_COMPANY_USERS, component: Directory })] }) })] }));
}
