import { jsx as _jsx } from "react/jsx-runtime";
import c from 'classnames';
import { IconActivity } from 'featherico';
import meeting from '../../../../../img/icons/moduleIcons/1_1_meeting.svg';
import icon360 from '../../../../../img/icons/moduleIcons/360.svg';
import feedbackRequest from '../../../../../img/icons/moduleIcons/feedback_request.svg';
import giveFeedback from '../../../../../img/icons/moduleIcons/give_feedback.svg';
import privateNote from '../../../../../img/icons/moduleIcons/new_private_note.svg';
import Badge from '../../../features/Praise/components/Badge';
import styles from './styles.m.less';
export const MeetingIcon = () => _jsx("img", { src: meeting, alt: "" });
export const FeedbackCycleIcon = () => _jsx("img", { src: icon360, alt: "" });
export const FeedbackRequestIcon = () => _jsx("img", { src: feedbackRequest, alt: "" });
export const FeedbackSingleIcon = () => _jsx("img", { src: giveFeedback, alt: "" });
export const PrivateNoteIcon = () => _jsx("img", { src: privateNote, alt: "" });
export const ObjectiveIcon = ({ objective }) => (_jsx("div", { className: c('svg-icon-' + objective.icon, styles.objectiveIcon) }));
export const SurveyIcon = () => (_jsx("div", { className: styles.surveyIconWrapper, children: _jsx(IconActivity, {}) }));
export const PraiseBadgeIcon = ({ praise }) => _jsx(Badge, { size: '60px', badge: praise.badge });
