import r from '../../../services/Replacement';
import { getIsSelfAssessment } from '../../Feedback/services/response';
export const getProvideWordings = (response) => {
    const isSelfAssessment = getIsSelfAssessment(response);
    return {
        pageTitle: isSelfAssessment
            ? `Write  ${r('fr.review.selfAssessment.big')} - ${response.topic}`
            : `Provide ${r('fr.menu.title')} - ${response.topic}`,
        preTitle: isSelfAssessment ? `Write  ${r('fr.review.selfAssessment')}` : `Provide ${r('fr.overview.title')}`,
    };
};
