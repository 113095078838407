import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconAlertTriangle } from 'featherico';
import { useEffect, useState } from 'react';
import { trackEvent } from '../../services/Tracking';
const hasTranslationClassPrefix = (element) => {
    // A class with the following prefix is added to <html> by the Google
    // Translate plugin in Chrome to indicate that the page has been translated.
    return !!element.className.match('translated-');
};
const useGoogleTranslateDetection = () => {
    const [isGoogleTranslateActive, setGoogleTranslateActive] = useState(false);
    useEffect(() => {
        const htmlElement = document.getElementsByTagName('html')[0];
        const observer = new MutationObserver(() => {
            const hasClassPrefix = hasTranslationClassPrefix(htmlElement);
            setGoogleTranslateActive(hasClassPrefix);
            if (hasClassPrefix) {
                trackEvent({
                    category: 'SYSTEM',
                    action: 'detect translation plugin',
                });
            }
        });
        observer.observe(htmlElement, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false,
        });
        setGoogleTranslateActive(hasTranslationClassPrefix(htmlElement));
        return () => {
            observer.disconnect();
        };
    }, []);
    return isGoogleTranslateActive;
};
export default function TranslationPluginWarning() {
    const isGoogleTranslateActive = useGoogleTranslateDetection();
    if (!isGoogleTranslateActive) {
        return null;
    }
    return (_jsx("div", { className: "boxWarning broadcast", children: _jsxs("div", { children: [_jsxs("p", { children: [_jsx(IconAlertTriangle, {}), " Language translation plugin detected"] }), "We detect that you are using a browser plugin like Google Translate to dynamically translate this page into another language.", _jsx("br", {}), "These translation plugins are not supported by our application and can cause problems in your account including loss of your work.", _jsx("br", {}), "Please turn off the plugin and reload the page."] }) }));
}
