// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EmailReports-beta-2S3ox {\n  vertical-align: middle;\n}\n", ""]);
// Exports
exports.locals = {
	"beta": "EmailReports-beta-2S3ox"
};
module.exports = exports;
