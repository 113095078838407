import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router';
import { useAppContext } from '../../../hooks/useAppContext';
import { prependAppPath } from '../../../services/Url';
import { NewNavigation } from './presenter';
export default function PageNavigation() {
    const appContext = useAppContext();
    const { pathname } = useLocation();
    return _jsx(NewNavigation, { currentLocation: prependAppPath(pathname), appContext: appContext });
}
