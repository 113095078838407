import { useEffect } from 'react';
import { useLoadingValue } from './useLoadingValue';
export const useObservable = (fn, dependencies) => {
    const { value, error, loading, setLoading, setValue, setError } = useLoadingValue();
    useEffect(() => {
        if (!loading) {
            setLoading(true);
        }
        const { unsubscribe } = fn().subscribe(setValue, setError);
        return () => unsubscribe();
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
    return { value, error, loading };
};
