export const EMAIL_TEMPLATE_TYPES = [
    'REVIEW_INFORM_TEAM_MEMBER',
    'REVIEW_INFORM_CUSTOM_REVIEWER_UNSPECIFIC',
    'REVIEW_INFORM_SECONDARY_REVIEWER_UNSPECIFIC',
    'REVIEW_INFORM_MANAGER_UNSPECIFIC',
    'REVIEW_INFORM_SECONDARY_REVIEWER_NUDGE_MANAGER',
    'REVIEW_NUDGE_MANAGER',
    'REVIEW_NUDGE_MANAGER_UNSPECIFIC',
    'REVIEW_NUDGE_SECONDARY_REVIEWER_UNSPECIFIC',
    'REVIEW_NUDGE_REPORT',
    'REVIEW_INDICATED_BY_MANAGER',
    'REVIEW_SHARE_WITH_EMPLOYEE',
    'REVIEW_SHARE_WITH_MANAGER',
    'REVIEW_CHANGED_BY_REVIEWEE',
    'REVIEW_CHANGED_BY_MANAGER',
    'REVIEW_HID_BY_EMPLOYEE',
    'REVIEW_HID_BY_MANAGER',
    'REVIEW_SIGNED_BY_REPORT',
    'REVIEW_SIGNED_BY_MANAGER',
];
