// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchInput-clearButton-i5kLQ {\n  border-radius: 4px;\n  width: 18px;\n  margin-left: -22px;\n}\n.SearchInput-clearButton-i5kLQ:hover {\n  background-color: #edeceb;\n}\n", ""]);
// Exports
exports.locals = {
	"clearButton": "SearchInput-clearButton-i5kLQ"
};
module.exports = exports;
