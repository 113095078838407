import { curry } from 'lodash/fp';
/* Rather than writing combinators ad-hoc, let's collect them.
 * Don't overuse them! See https://www.youtube.com/watch?v=seVSlKazsNk&feature=youtu.be
 * List of common combinators (for reference and inspiration):
 * https://hackage.haskell.org/package/data-aviary-0.4.0/docs/Data-Aviary-Birds.html
 *
 */
// Also called big-psi and phoenix
export const liftM2 = curry((f, g, h, x) => f(g(x), h(x)));
// Also called psi
export const on = curry((f, g, x, y) => f(g(x), g(y)));
// Also called dovekie
export const on2 = curry((f, g, h, x, y) => f(g(x), h(y)));
// Compose (but only for 2 functions)
export const compose = curry((f, g, x) => f(g(x)));
export const compose2 = curry((f, g, x, y) => f(g(x, y)));
