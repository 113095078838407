export function observablePollUntil(call, interval = 1000, getTotal = result => result.length) {
    return {
        subscribe: (publishData, publishError) => {
            let subscribed = true;
            let previousTotal;
            const poll = () => {
                call()
                    .then(data => {
                    if (!subscribed) {
                        return;
                    }
                    const newTotal = getTotal(data);
                    if (shouldPublishData(previousTotal, newTotal)) {
                        publishData(data);
                    }
                    else {
                        previousTotal = newTotal;
                        setTimeout(poll, interval);
                    }
                })
                    .catch(error => {
                    if (!subscribed) {
                        return;
                    }
                    publishError(error);
                });
            };
            const unsubscribe = () => {
                subscribed = false;
            };
            poll();
            return { unsubscribe };
        },
    };
}
export function shouldPublishData(previousTotal, newTotal) {
    return (previousTotal && newTotal <= previousTotal) || (previousTotal === 0 && newTotal === 0);
}
