import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { isIE10, isIE11 } from '../../services/Browser';
import './style.m.less';
const propTypes = {
    children: PropTypes.element,
    mode: PropTypes.string,
};
const MODES = {
    CROSS: 'cross',
    CROSS_WAIT: 'wait',
    RIGHT_OUT: 'right-out',
    GENTLE_LEFT: 'gentle-left',
    GENTLE_RIGHT: 'gentle-right',
    GENTLE_TOP: 'gentle-top',
    GENTLE_BOTTOM: 'gentle-bottom',
};
const TIMEOUT = 600;
const WAIT_TIMEOUT = 1200;
const NAMES = {
    [MODES.CROSS]: 'react-crossfade',
    [MODES.CROSS_WAIT]: 'react-crossfade-wait',
    [MODES.RIGHT_OUT]: 'react-crossfade-right-out',
    [MODES.GENTLE_LEFT]: 'react-crossfade-gentle-left',
    [MODES.GENTLE_RIGHT]: 'react-crossfade-gentle-right',
    [MODES.GENTLE_TOP]: 'react-crossfade-gentle-top',
    [MODES.GENTLE_BOTTOM]: 'react-crossfade-gentle-bottom',
};
function needsToWait(mode) {
    return mode !== MODES.CROSS;
}
export default function Crossfade({ children, mode = MODES.CROSS }) {
    const props = {
        transitionName: NAMES[mode],
        transitionEnterTimeout: TIMEOUT,
        transitionLeaveTimeout: needsToWait(mode) ? WAIT_TIMEOUT : TIMEOUT,
    };
    if (isIE10() || isIE11()) {
        return _jsxs("div", Object.assign({}, props, { children: [" ", children] }));
    }
    return _jsx(ReactCSSTransitionReplace, Object.assign({}, props, { children: children }));
}
Crossfade.propTypes = propTypes;
Crossfade.MODES = MODES;
