export const initHubspot = (user, setShowConfirmation) => {
    window.hbspt.forms.create({
        portalId: '2392423',
        formId: '1617fc5c-3fab-4651-ba44-9f8f396d9a92',
        target: '#hubspotDemoForm',
        onFormReady: ($form) => {
            setFormField($form, 'firstname', user.firstName);
            setFormField($form, 'lastname', user.lastName || '');
            setFormField($form, 'email', user.email);
        },
        onFormSubmitted: () => {
            setShowConfirmation(true);
        },
    });
};
const setFormField = (form, fieldName, value) => {
    const field = form.querySelector(`input[name="${fieldName}"]`);
    if (field && field instanceof HTMLInputElement) {
        field.value = value;
        field.dispatchEvent(new Event('input', { bubbles: true }));
    }
};
