// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".List-group-2yWAk {\n  padding-left: 0;\n  margin: 0;\n}\n.List-group-2yWAk + .List-group-2yWAk {\n  margin-top: 20px;\n}\n.List-liReset-3qhnu {\n  list-style-type: none;\n  margin: 0;\n}\n.List-link-2gJsd {\n  display: flex;\n  flex: 1;\n  padding: 4px 0;\n  color: #606060;\n  margin: 0 0 10px 0;\n  align-items: center;\n}\n.List-link-2gJsd.List-subGroup-14qjs {\n  padding-left: 20px;\n}\n.List-link-2gJsd:hover,\n.List-link-2gJsd:focus {\n  background-color: #e6e1d8;\n  color: #4d4d4c;\n}\n.List-link-2gJsd:active,\n.List-link-2gJsd:focus {\n  outline: none;\n}\n.List-active-3koBL {\n  font-weight: 500;\n  color: #4d4d4c;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.List-icon-3P9_f {\n  margin: 0 10px;\n}\n.List-icon-3P9_f svg {\n  margin-top: -1px;\n}\n.List-title-w0tr_ {\n  line-height: 20px;\n  margin-right: 4px;\n  white-space: break-spaces;\n}\n.List-title-w0tr_ strong {\n  font-weight: 500;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.List-deactivated-1vz3p {\n  opacity: 0.5;\n}\n.List-notification-2MsQF {\n  margin-left: 10px;\n  pointer-events: none;\n}\n.List-tagNew-tQZKV {\n  opacity: 1;\n}\n.List-navSelect-2grxG {\n  text-transform: uppercase;\n  width: 150px;\n  height: 25px;\n  border-radius: 50px;\n  padding: 2px;\n  margin-left: 10px;\n  margin-bottom: 20px;\n}\n.List-navSelect-2grxG input:checked + label,\n.List-navSelect-2grxG input:checked + label:hover {\n  background: #1a1a1a;\n  color: #ffffff;\n  border-radius: 20px;\n}\n.List-navSelect-2grxG input:not(:checked, [disabled]) + label {\n  cursor: pointer;\n}\n.List-navSelect-2grxG input:not(:checked, [disabled]) + label:hover,\n.List-navSelect-2grxG input:not(:checked, [disabled]) + label:focus {\n  border-radius: 20px;\n  background-color: #edeceb;\n}\n.List-navSelect-2grxG label {\n  align-items: center;\n  padding: 0;\n  font-size: 11px;\n  border-radius: 20px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"group": "List-group-2yWAk",
	"liReset": "List-liReset-3qhnu",
	"link": "List-link-2gJsd",
	"subGroup": "List-subGroup-14qjs",
	"active": "List-active-3koBL",
	"icon": "List-icon-3P9_f",
	"title": "List-title-w0tr_",
	"deactivated": "List-deactivated-1vz3p",
	"notification": "List-notification-2MsQF",
	"tagNew": "List-tagNew-tQZKV",
	"navSelect": "List-navSelect-2grxG"
};
module.exports = exports;
