import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import api from '../../../../api';
import { ErrorCard } from '../../../../routing/RouteError';
import { Card, CardHeader, CardHeaderHeading, CardSection } from '../../../components/Cards';
import { Grid, GridItem } from '../../../components/Grid';
import PageHeader from '../../../components/PageHeader';
import PageStatic from '../../../components/PageStatic';
import PageStaticSection from '../../../components/PageStaticSection';
import PageTitle from '../../../components/PageTitle';
import PillBox from '../../../components/PillBox';
import triggerPopupAlert from '../../../components/PopupAlert/trigger';
import SaveButton, { DISABLED, ENABLED, SAVING } from '../../../components/SaveButton';
import Spinner from '../../../components/Spinner';
import AdminReportSubscriptionForm from './AdminReportSubscriptionForm';
import styles from './style.m.less';
export default function AdminEmailReportsPage() {
    var _a, _b;
    const [adminReportSubscription, setAdminReportSubscription] = useState(undefined);
    const { result: adminReport, loading, error, } = useAsync(() => api.emailReportSubscriptions.getReportSubscription('ADMIN_REPORT'), []);
    useEffect(() => {
        setAdminReportSubscription(adminReport);
    }, [adminReport, setAdminReportSubscription]);
    const [unsubscribing, setUnsubscribing] = useState(false);
    const handleSubscribe = (configuration) => api.emailReportSubscriptions
        .createReportSubscription({ reportType: 'ADMIN_REPORT', configuration })
        .then(setAdminReportSubscription)
        .catch(() => {
        triggerPopupAlert("We couldn't subscribe to this report. Please try again or contact support.", 'error');
    });
    const handleUnsubscribe = (reportType) => {
        setUnsubscribing(true);
        api.emailReportSubscriptions.deleteReportSubscription(reportType).then(() => {
            setUnsubscribing(false);
            setAdminReportSubscription(undefined);
        }, () => {
            setUnsubscribing(false);
            triggerPopupAlert("We couldn't unsubscribe you from this report. Please try again or contact support.", 'error');
        });
    };
    const getFrequency = (configuration) => {
        switch (configuration.reportFrequencyWeeks) {
            case 1:
                return 'once per week';
            case 2:
                return 'once every two weeks';
            default:
                return `${configuration.reportFrequencyWeeks} weeks`;
        }
    };
    return (_jsxs(PageStatic, { children: [_jsx(PageTitle, { title: "Receive email reports" }), _jsx(PageHeader, { title: "Receive email reports" }), _jsxs(PageStaticSection, { children: [error && _jsx(ErrorCard, { errorCode: (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 500 }), !error && (_jsxs(Card, { children: [_jsx(CardHeader, { children: _jsxs(CardHeaderHeading, { children: ["Administrative Reporting ", _jsx(PillBox, { className: styles.beta, color: "BLUE", value: "\u0299\u1D07\u1D1B\u1D00" })] }) }), _jsxs(CardSection, { children: [_jsx("p", { children: "From here you can subscribe to receive reports over email, designed to help you administer your performance process." }), loading && _jsx(Spinner, {}), !loading && !error && !adminReportSubscription && (_jsx(Grid, { children: _jsx(GridItem, { desktop: "half", s: "full", children: _jsx(AdminReportSubscriptionForm, { onSubmit: handleSubscribe }) }) })), !loading && !error && adminReportSubscription && (_jsxs(_Fragment, { children: ["You are subscribed to receive this report", ' ', getFrequency(adminReportSubscription.configuration), ' ', _jsx(SaveButton, { onClick: () => handleUnsubscribe('ADMIN_REPORT'), state: unsubscribing ? SAVING : ENABLED, labels: {
                                                    [ENABLED]: `Unsubscribe`,
                                                    [DISABLED]: `Unsubscribe`,
                                                    [SAVING]: `Unsubscribing`,
                                                } })] }))] })] }))] })] }));
}
