import moment from 'moment';
const QUARTERS = [
    {
        start: { month: 1, day: 1 },
        end: { month: 3, day: 31 },
        name: 'Q1',
        number: 1,
    },
    {
        start: { month: 4, day: 1 },
        end: { month: 6, day: 30 },
        name: 'Q2',
        number: 2,
    },
    {
        start: { month: 7, day: 1 },
        end: { month: 9, day: 30 },
        name: 'Q3',
        number: 3,
    },
    {
        start: { month: 10, day: 1 },
        end: { month: 12, day: 31 },
        name: 'Q4',
        number: 4,
    },
];
export function getCurrentQuarter(now) {
    const currentQuarter = findCurrentQuarter(now);
    return formatQuarter(currentQuarter, moment(now));
}
export function getLastQuarter(now) {
    const lastQuarter = findLastQuarter(now);
    const baseMoment = lastQuarter.number === 4 ? moment(now).year(now.year() - 1) : moment(now);
    return formatQuarter(lastQuarter, baseMoment);
}
function formatQuarter(quarter, baseMoment) {
    const { name, start, end } = quarter;
    return {
        name,
        start: quarterBoundaryToDate(start, baseMoment),
        end: quarterBoundaryToDate(end, baseMoment),
        year: baseMoment.year(),
    };
}
function quarterBoundaryToDate(boundary, baseMoment) {
    return moment(baseMoment)
        .month(boundary.month - 1)
        .date(boundary.day);
}
function findCurrentQuarter(now) {
    return QUARTERS[now.quarter() - 1];
}
function findLastQuarter(now) {
    const quarter = now.quarter();
    return quarter === 1 ? QUARTERS[3] : QUARTERS[quarter - 2];
}
