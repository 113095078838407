import PropTypes from 'prop-types';
import { Component } from 'react';
import { goToUrl, replaceUrl } from '../../services/Router';
class Redirect extends Component {
    componentDidMount() {
        if (this.props.push) {
            goToUrl(this.props.to);
        }
        else {
            replaceUrl(this.props.to);
        }
    }
    render() {
        return null;
    }
}
Redirect.propTypes = {
    to: PropTypes.string.isRequired,
    push: PropTypes.bool,
};
export default Redirect;
