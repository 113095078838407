// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Overview-section-30nDC {\n  margin-top: 40px;\n}\n.Overview-image-3UK8H {\n  margin-bottom: 5px;\n  outline: none;\n}\n.Overview-image-3UK8H,\n.Overview-image-3UK8H svg {\n  width: 45px;\n  height: 45px;\n  color: #4d4d4c;\n}\n.Overview-hasWarning-HRNOM:not([disabled]) {\n  background: #fff7d9;\n  border: 2px solid #ffcc00;\n}\n.Overview-hasWarning-HRNOM:not([disabled]):hover.Overview-hasWarning-HRNOM:not([disabled]):hover {\n  background: #fff2c0;\n}\n.Overview-hasError-3S772:not([disabled]) {\n  background-color: #fff0f0;\n  border: 2px solid #f23037;\n}\n.Overview-hasError-3S772:not([disabled]):hover.Overview-hasError-3S772:not([disabled]):hover {\n  background-color: #ffd7d7;\n}\n.Overview-gridContainer-1dbIE {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(162px, 1fr));\n  grid-gap: 10px;\n  grid-auto-rows: 112px;\n}\n.Overview-gridItem-2t4T- {\n  text-align: center;\n  display: inline-block;\n  min-width: 162px;\n  margin: auto 10px 10px auto;\n}\n@supports (display: grid) {\n  .Overview-gridItem-2t4T- {\n    margin: 0;\n  }\n}\n.Overview-card-3TTS1 {\n  min-height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"section": "Overview-section-30nDC",
	"image": "Overview-image-3UK8H",
	"hasWarning": "Overview-hasWarning-HRNOM",
	"hasError": "Overview-hasError-3S772",
	"gridContainer": "Overview-gridContainer-1dbIE",
	"gridItem": "Overview-gridItem-2t4T-",
	"card": "Overview-card-3TTS1"
};
module.exports = exports;
