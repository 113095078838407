import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ThumbCard } from '../../../components/Cards';
import Link from '../../../components/Link';
import styles from './style.m.less';
export default function AdminLink({ name, url, tooltip, enabled, status, icon, className, target }) {
    const linkClasses = classNames(styles.card, className, {
        [styles.hasWarning]: status === 'WARNING',
        [styles.hasError]: status === 'ERROR',
    });
    let iconSrc = require(`./svg/default.svg`);
    try {
        iconSrc = require(`./svg/${icon}.svg`);
    }
    catch (error) {
        // ignore error as we fall back to default svg
    }
    const card = (_jsxs(ThumbCard, { isEnabled: enabled, className: linkClasses, isCompact: true, children: [_jsx("img", { className: styles.image, src: iconSrc, alt: "" }), _jsx("div", { children: name })] }));
    return enabled ? (_jsx(Link, { href: url, className: styles.gridItem, hasTextStyle: false, "data-tooltip": tooltip, target: target, children: card })) : (_jsx("div", { className: styles.gridItem, "data-tooltip": tooltip, children: card }));
}
