// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OfflineWarning-container-1UfUs {\n  z-index: 610;\n  top: 0;\n  position: -webkit-sticky;\n  position: sticky;\n}\n.OfflineWarning-warning-34MK0 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 10px;\n  background-color: #f23037;\n  color: #ffffff;\n  font-size: 13px;\n  --fi-size: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "OfflineWarning-container-1UfUs",
	"warning": "OfflineWarning-warning-34MK0"
};
module.exports = exports;
