import { map, set, startsWith } from 'lodash/fp';
import api from '../../../api';
const IMAGE_PREFIX = '/images/icons/badges/';
const IMAGE_SUFFIX = '.svg';
const IMAGE_VERSION = '?v=4'; // update this when updating the images to force cdn to serve new version
const IMAGE_SERVICE = '/imageservice?id=';
const DEFAULT_BADGE = 'b_default';
export const isCustomIcon = icon => startsWith('badge-icon-', icon);
export const getIconImageUrl = icon => isCustomIcon(icon) ? `${IMAGE_SERVICE}${icon}` : `${IMAGE_PREFIX}${icon}${IMAGE_SUFFIX}${IMAGE_VERSION}`;
export const getIconImageUrlWithDefault = icon => !!icon ? getIconImageUrl(icon) : IMAGE_PREFIX + DEFAULT_BADGE + IMAGE_SUFFIX + IMAGE_VERSION;
export const addImage = badge => set('image', getIconImageUrl(badge.icon), badge);
export const getBadgesWithImages = () => api.badges.getAll().then(map(addImage));
