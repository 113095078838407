import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { useEffect } from 'react';
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
export const useHeightAsCssVariable = (cssVar, node) => {
    useEffect(() => {
        if (!node) {
            return;
        }
        let height = null;
        const observer = new ResizeObserver(entries => {
            const { height: newHeight } = entries[0].contentRect;
            if (newHeight !== height) {
                height = newHeight;
                document.body.style.setProperty(cssVar, height + 'px');
            }
        });
        observer.observe(node);
        return () => {
            observer.disconnect();
            document.body.style.removeProperty(cssVar);
        };
    }, [node, cssVar]);
};
