export function scrollTo(topPaddingContainer, marginItem, selectedClass, selected, numberOfItems, shouldScrollTo) {
    return elem => {
        if (!elem || !shouldScrollTo) {
            return;
        }
        const selectedElem = elem.getElementsByClassName(selectedClass)[0];
        if (!selectedElem) {
            return;
        }
        const containerHeight = elem.clientHeight + topPaddingContainer;
        const itemPos = selectedElem.offsetTop;
        const itemHeight = selectedElem.scrollHeight + marginItem;
        const itemIsToHigh = itemPos < elem.scrollTop;
        const elementAtTop = itemPos - marginItem;
        const elementAtBottom = itemPos - (containerHeight - itemHeight) + marginItem;
        if (!isVisible(itemPos, elem.scrollTop, containerHeight, itemHeight)) {
            if (itemIsToHigh) {
                elem.scrollTop = elementAtTop;
            }
            else {
                elem.scrollTop = elementAtBottom;
            }
        }
    };
}
function isVisible(itemPos, currScrollTop, containerHeight, itemHeight) {
    const viewPortStart = currScrollTop;
    const viewPortEnd = currScrollTop + containerHeight;
    return itemPos >= viewPortStart && itemPos + itemHeight <= viewPortEnd;
}
