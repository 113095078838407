import { getCurrentUser } from './react/services/CurrentUser';
import { hasBasicHr, isAdmin } from './react/services/User';
import * as R from './routes';
const routePairsForHr = {
    [R.YOUR_COMPANY_SURVEYS]: R.ADMIN_SURVEYS,
    [R.YOUR_COMPANY_SURVEYS_WITH_SURVEY]: R.ADMIN_SURVEYS_WITH_SURVEY,
    [R.YOUR_COMPANY_SURVEYS_WITH_SURVEY_PRINT]: R.ADMIN_SURVEYS_WITH_SURVEY_PRINT,
    [R.YOUR_COMPANY_OBJECTIVES_SELECT_CYCLE]: R.ADMIN_OBJECTIVES_SELECT_CYCLE,
    [R.YOUR_COMPANY_OBJECTIVES_OVERVIEW]: R.ADMIN_OBJECTIVES_CYCLE_OVERVIEW,
    [R.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE]: R.ADMIN_FEEDBACK_SELECT_CYCLE,
    [R.YOUR_COMPANY_FEEDBACK_WITH_CYCLE]: R.ADMIN_FEEDBACK_WITH_CYCLE,
    [R.FEEDBACK_CYCLE_EDIT_NEW]: R.ADMIN_FEEDBACK_CYCLE_EDIT,
    [R.YOUR_COMPANY_REVIEWS_SELECT_CYCLE]: R.ADMIN_REVIEWS_SELECT_CYCLE,
    [R.YOUR_COMPANY_REVIEWS_QUESTIONNAIRES]: R.ADMIN_REVIEWS_QUESTIONNAIRES,
    [R.YOUR_COMPANY_REVIEWS_WITH_CYCLE]: R.ADMIN_REVIEWS_WITH_CYCLE,
    [R.REVIEW_CYCLE_EDIT]: R.ADMIN_REVIEW_CYCLE_EDIT,
    [R.REVIEW_GRAPH_COMPANY_WITH_CYCLE]: R.ADMIN_REVIEW_GRAPH_WITH_CYCLE,
};
const routePairsForTech = {
    // tech admins have access to
    [R.YOUR_COMPANY_USERS]: R.ADMIN_USERS_DIRECTORY,
    [R.YOUR_COMPANY_USERS_CREATE]: R.ADMIN_USERS_DIRECTORY_CREATE,
};
const routePairsAll = Object.assign(Object.assign({}, routePairsForHr), routePairsForTech);
const getRoutePairsForUser = (user) => {
    if (hasBasicHr(user)) {
        return routePairsAll;
    }
    else if (isAdmin(user)) {
        return routePairsForTech;
    }
    else {
        return {};
    }
};
export const getAdminRouteForNavigation = (oldRoute) => {
    const user = getCurrentUser();
    const newRoute = getRoutePairsForUser(user)[oldRoute];
    return newRoute !== null && newRoute !== void 0 ? newRoute : oldRoute;
};
export const getRedirectsForHR = () => {
    const user = getCurrentUser();
    const newNavRedirects = Object.entries(getRoutePairsForUser(user));
    return newNavRedirects;
};
