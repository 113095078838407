import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { Redirect, Route, Switch } from 'react-router';
import { compose, setPropTypes } from 'recompose';
import api from '../../../../api';
import * as ROUTES from '../../../../routes';
import AlertBox from '../../../components/AlertBox';
import AppProvider from '../../../components/AppProvider';
import LoadingIndicatorFull from '../../../components/LoadingIndicatorFull';
import NavigationTabs from '../../../components/NavigationTabs';
import PageHeader from '../../../components/PageHeader';
import PageStatic from '../../../components/PageStatic';
import PageStaticSection from '../../../components/PageStaticSection';
import PageTitle from '../../../components/PageTitle';
import { mapProps } from '../../../hoc/recompose';
import { withData } from '../../../hoc/withData';
import CompanyLink from '../UserImport/CompanyLink';
import HistoryTab from '../UserImport/History';
import PreviewTab from '../UserImport/Preview';
import SyncedByOtherSystemWarning from '../UserImport/SyncedByOtherSystemWarning';
import IntegrationsBreadcrumbs from '../components/IntegrationsBreadcrumbs';
import ConfigTab from './Configuration';
export default compose(setPropTypes({
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}), mapProps(props => (Object.assign({ companyId: parse(props.location.search).company }, props))), withData({
    observe: {
        config: ({ companyId }) => api.workableImportConfiguration.getConfig.createObservable(companyId),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
}))(AdminWorkablePage);
const hasConnectionDetails = config => {
    return !!(config.systemSpecificProperties.workableSubdomain && config.systemSpecificProperties.workablePartnerToken);
};
AdminWorkablePage.propTypes = {
    config: PropTypes.object.isRequired,
};
function AdminWorkablePage({ config, companyId, location: { search }, history }) {
    const withSearch = pathname => ({ pathname, search });
    const tabs = [
        {
            label: 'Configuration',
            link: withSearch(ROUTES.INTEGRATIONS_WORKABLE_CONFIG),
            visible: true,
        },
        {
            label: 'History',
            link: withSearch(ROUTES.INTEGRATIONS_WORKABLE_HISTORY),
            visible: true,
        },
        {
            label: 'Preview',
            link: withSearch(ROUTES.INTEGRATIONS_WORKABLE_PREVIEW),
            visible: true,
            enabled: hasConnectionDetails(config),
        },
    ];
    return (_jsxs(AppProvider, { children: [_jsx(PageTitle, { title: "Workable Integration" }), _jsxs(PageStatic, { children: [_jsx(IntegrationsBreadcrumbs, { page: "Workable" }), _jsx(Header, { companyId: companyId }), _jsx("p", { className: "page_static-section", children: "Small Improvements integrates with Workable so you can manage your employee data in one place and import it directly into your Small Improvements account." }), _jsx(SyncedByOtherSystemWarning, { config: config })] }), _jsx(NavigationTabs, { tabs: tabs }), _jsx(PageStaticSection, { children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: ROUTES.INTEGRATIONS_WORKABLE_HISTORY, render: () => (_jsx(HistoryTab, { apiImport: api.workableImport, name: "Workable", companyId: companyId })) }), _jsx(Route, { exact: true, path: ROUTES.INTEGRATIONS_WORKABLE_PREVIEW, render: () => hasConnectionDetails(config) ? (_jsx(PreviewTab, { apiImport: api.workableImport, name: "Workable", companyId: companyId, goToHistory: () => history.push(ROUTES.INTEGRATIONS_WORKABLE_HISTORY) })) : (_jsx(AlertBox, { colour: "YELLOW", children: "Please enter your details in the Configuration tab in order to view a preview." })) }), _jsx(Route, { exact: true, path: ROUTES.INTEGRATIONS_WORKABLE_CONFIG, render: () => (_jsx(ConfigTab, { config: config, form: "workableConfig", disabled: !!companyId, apiImportConfig: api.workableImportConfiguration, hasConnectionDetails: hasConnectionDetails(config) })) }), _jsx(Redirect, { to: hasConnectionDetails(config) && config.synchronizationInterval !== 'NEVER'
                                ? ROUTES.INTEGRATIONS_WORKABLE_HISTORY
                                : ROUTES.INTEGRATIONS_WORKABLE_CONFIG })] }) })] }));
}
function Header({ companyId }) {
    const title = 'Workable Integration';
    if (!companyId) {
        return _jsx(PageHeader, { title: title });
    }
    return (_jsx(PageHeader, { title: _jsxs("span", { children: [title, " for ", _jsx(CompanyLink, { companyId: companyId })] }) }));
}
