import { jsx as _jsx } from "react/jsx-runtime";
import { withData as pureWithData } from 'ladda-react';
import * as React from 'react';
import { ApiExceptionInline } from '../../components/ErrorProvider';
export function withData(conf) {
    return pureWithData(Object.assign({ errorComponent: ApiExceptionInline }, conf));
}
withData.PAGINATION = pureWithData.PAGINATION;
export const handleApiErrors = (handler, DefaultError) => props => {
    const response = props.error.response;
    const data = response.data;
    const Component = handler[data.message];
    return Component ? _jsx(Component, Object.assign({}, props, { error: data })) : _jsx(DefaultError, { errorCode: response.status });
};
