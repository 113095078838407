import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAsync } from 'react-async-hook';
import PageTitle from '../../components/PageTitle';
import { getCurrentUser } from '../../services/CurrentUser';
import { getTeamMembers } from '../../services/User';
import ActivityStream from './lazy';
const STYLE = {
    maxWidth: 700,
    marginTop: 20,
};
export default function ActivityStreamTab({ mode }) {
    const currentUser = getCurrentUser();
    const { result: teamMembers } = useAsync(() => getTeamMembers(currentUser), [currentUser]);
    const title = mode === 'TEAM' ? 'Activities in Your Team' : 'Activities in Your Company';
    return (_jsxs("div", { children: [_jsx(PageTitle, { title: title }), _jsx("div", { style: STYLE, children: _jsx(ActivityStream, { involvedUsers: mode === 'TEAM' && teamMembers ? teamMembers.map(u => u.id).join(',') : undefined }) })] }));
}
