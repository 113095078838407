import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import Spark from '../../components/Spark';
import { useAppContext } from '../../hooks/useAppContext';
const HeaderSparks = () => {
    const { user } = useAppContext();
    const { company } = user;
    return user.isHR && company.onAutomatedBilling ? _jsx(Spark, { story: "AUTOMATED_BILLING", type: "promptToCheckout" }) : null;
};
export default HeaderSparks;
