// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationsMarkAllAsReadButton-button-3iL0h {\n  color: #0071d4;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "NotificationsMarkAllAsReadButton-button-3iL0h"
};
module.exports = exports;
