import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { updateOnboardingSettings } from '../../../../api/custom/CompanyOnboarding';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import RegisterFlowTemplate from '../../../components/RegisterFlowTemplate';
import SaveButton from '../../../components/SaveButton';
import VerticalCheckboxes from '../../../components/VerticalCheckboxes';
import { getCurrentUser } from '../../../services/CurrentUser';
import { goToDashboardPage, goToOnboardingSurveyHris } from '../../../services/GoTo';
import { isAdmin } from '../../../services/User';
import styles from '../styles.m.less';
const BUTTON_LABELS = {
    DISABLED: 'Continue',
    ENABLED: 'Continue',
};
const imagePerformanceReviews = require('./images/performance-reviews.svg');
const imageMeetings = require('./images/meetings.svg');
const imageAutomate = require('./images/automate.svg');
const imageGoals = require('./images/goals.svg');
const imageFeedback = require('./images/feedback.svg');
const imageEngagement = require('./images/engagement.svg');
const INTERESTS = [
    { title: 'Running a performance review', image: imagePerformanceReviews },
    { title: 'Capturing 1:1 meetings', image: imageMeetings },
    { title: 'Automating repetitive tasks', image: imageAutomate },
    { title: 'Tracking goal performance', image: imageGoals },
    { title: 'Facilitating continuous feedback', image: imageFeedback },
    { title: 'Measuring employee engagement', image: imageEngagement },
];
export default function OnboardingSurveyInterestStep() {
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [imageURL, setImageUrl] = useState(imagePerformanceReviews);
    const isSelectionValid = selectedInterests.length > 0;
    const isSelectionComplete = selectedInterests.length === 3;
    const interestOptions = generateInterestOptions(INTERESTS);
    function generateInterestOptions(interests) {
        return interests.map(interest => ({
            value: interest.title,
            label: interest.title,
            disabled: isSelectionComplete && !selectedInterests.includes(interest.title),
        }));
    }
    const handleInterestChange = (interestTitle, checked) => {
        if (checked && isSelectionComplete) {
            return;
        }
        if (checked) {
            setSelectedInterests([...selectedInterests, interestTitle]);
            setImageUrl(INTERESTS.find(interest => interest.title === interestTitle).image);
            return;
        }
        const newSelectedInterests = selectedInterests.filter(selectedInterest => selectedInterest !== interestTitle);
        setSelectedInterests(newSelectedInterests);
        const lastSelectedInterest = newSelectedInterests[newSelectedInterests.length - 1];
        const newImageURL = lastSelectedInterest
            ? INTERESTS.find(interest => interest.title === lastSelectedInterest).image
            : imagePerformanceReviews;
        setImageUrl(newImageURL);
    };
    const redirect = () => {
        const currentUser = getCurrentUser();
        if (isAdmin(currentUser)) {
            goToOnboardingSurveyHris();
        }
        else {
            goToDashboardPage();
        }
    };
    const saveAndRedirect = async () => {
        try {
            await updateOnboardingSettings({ interests: selectedInterests });
        }
        finally {
            redirect();
        }
    };
    return (_jsx(RegisterFlowTemplate, { step: "4", title: "What are your main interests?", subtitle: _jsxs(_Fragment, { children: ["Select up to 3 interests.", _jsx("br", {}), "This will help us tailor Small Improvements to your needs."] }), imageURL: imageURL, children: _jsx(Form, { onSubmit: saveAndRedirect, children: ({ submitError }) => (_jsxs(_Fragment, { children: [_jsx(VerticalCheckboxes, { options: interestOptions, checkedValues: selectedInterests, onChange: handleInterestChange }), _jsxs("div", { className: styles.formButtonContainer, children: [_jsx(SaveButton, { labels: BUTTON_LABELS, disabled: !isSelectionValid }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null] })] })) }) }));
}
