import { map, startsWith, toPairs } from 'lodash/fp';
import { getMeetingResourceId } from '../../../react/features/Meetings/services/meeting';
import { getFeedbackViewUrl } from '../../../react/features/YourFeedback/services/url';
import { getCurrentUser } from '../../../react/services/CurrentUser';
import { isFeatureEnabled } from '../../../react/services/Features';
import { interpolateAppUrl, prependAppPath } from '../../../react/services/Url';
import { ADMIN_MEETINGS_TEMPLATES_CREATE, ADMIN_MEETINGS_TEMPLATES_EDIT, BASE, MEETING_DEDICATED, MEETING_OVERVIEW, MEETING_PAGE, MEETING_SHARED, MEETING_TEMPLATES_CREATE, MEETING_TEMPLATES_EDIT, MESSAGE_SINGLE, OBJECTIVE_SINGLE, OBSERVER_COMPANY, OBSERVER_COMPANY_PAGE, OBSERVER_CUSTOMER_HEALTH_COMPANY, OBSERVER_EXPERIMENT_VIEW, PRAISE_SINGLE, USER_PROFILE, YOUR_COMPANY_MEETINGS_MY_TEMPLATES_CREATE, YOUR_COMPANY_MEETINGS_MY_TEMPLATES_EDIT, YOUR_COMPANY_MEETINGS_SHARED_PAGE, } from '../../../routes';
const PLACEHOLDER = {
    ID: ':id',
    CYCLE_ID: ':cycleId',
    OBJECTIVE_ID: ':objectiveId',
};
export function makeAbsolute(link) {
    const relativeLink = validateRelativeLink(link);
    return `${getCurrentUser().company.baseUrl}/${relativeLink}`;
}
export function linkToRequestFeedbackView(uid, relative = false) {
    const relativeLink = getFeedbackViewUrl(uid);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToMeetingOverview(relative = true) {
    const relativeLink = BASE + MEETING_OVERVIEW;
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
/**
 *
 * @param {import('../../../../types/MiniUser').MiniUser} user
 * @param {boolean} relative
 */
export function linkToMeetingSpacePage(user, relative = true) {
    const userIds = getMeetingResourceId(getCurrentUser(), user);
    const relativeLink = BASE + MEETING_PAGE.replace(':userIds', userIds);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToSharedMeetingSpacePage(users, relative = true) {
    const userIds = getMeetingResourceId(...users);
    const route = isFeatureEnabled('FEATURE_meetingNewNavigation') ? MEETING_SHARED : YOUR_COMPANY_MEETINGS_SHARED_PAGE;
    const relativeLink = interpolateAppUrl(route, { userIds });
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToMeetingDedicatedPage(meetingId, relative = true) {
    const relativeLink = BASE + MEETING_DEDICATED.replace(':meetingId', meetingId);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToMeetingTemplateEditForCompany(templateId, relative = true) {
    const relativeLink = interpolateAppUrl(ADMIN_MEETINGS_TEMPLATES_EDIT, { templateId });
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToMeetingTemplateEditForUser(templateId, relative = true) {
    const route = isFeatureEnabled('FEATURE_meetingNewNavigation')
        ? MEETING_TEMPLATES_EDIT
        : YOUR_COMPANY_MEETINGS_MY_TEMPLATES_EDIT;
    const relativeLink = interpolateAppUrl(route, { templateId });
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkMeetingTemplateCreateForCompany(relative = true) {
    const relativeLink = prependAppPath(ADMIN_MEETINGS_TEMPLATES_CREATE);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkMeetingTemplateCreateForUser(relative = true) {
    const route = isFeatureEnabled('FEATURE_meetingNewNavigation')
        ? MEETING_TEMPLATES_CREATE
        : YOUR_COMPANY_MEETINGS_MY_TEMPLATES_CREATE;
    const relativeLink = prependAppPath(route);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToObjective(objective, relative = true) {
    const { cycleId, id } = objective;
    const relativeLink = BASE + OBJECTIVE_SINGLE.replace(PLACEHOLDER.CYCLE_ID, cycleId).replace(PLACEHOLDER.OBJECTIVE_ID, id);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToNewUserProfile(userId, relative = true) {
    const relativeLink = BASE + USER_PROFILE.replace(PLACEHOLDER.ID, userId);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToMessage(message, relative = true) {
    const relativeLink = BASE + MESSAGE_SINGLE.replace(PLACEHOLDER.ID, message.id);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToPraise(message, relative = true) {
    const relativeLink = BASE + PRAISE_SINGLE.replace(PLACEHOLDER.ID, message.id);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToPraiseWall(token) {
    return makeAbsolute(`/wall-of-praise${toQueryString({ token })}`);
}
export function toQueryString(params) {
    const paramList = map(([k, v]) => `${k}=${v}`, toPairs(params)).join('&');
    return `?${paramList}`;
}
export function linkToExperiment(codeId, relative = true) {
    const relativeLink = BASE + OBSERVER_EXPERIMENT_VIEW.replace(':codeId', codeId);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
/**
 * @param {string | null | undefined} companyId
 * @param {boolean} relative
 */
export function linkToCompanyObserver(companyId = null, relative = true) {
    const relativeLink = !!companyId
        ? BASE + OBSERVER_COMPANY_PAGE.replace(':companyId', companyId)
        : BASE + OBSERVER_COMPANY;
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
export function linkToCustomerHealthCompany(companyId, relative = true) {
    const relativeLink = BASE + OBSERVER_CUSTOMER_HEALTH_COMPANY.replace(':companyId', companyId);
    return relative ? relativeLink : makeAbsolute(relativeLink);
}
function validateRelativeLink(relativeLink) {
    if (startsWith('/', relativeLink)) {
        relativeLink = relativeLink.substring(1);
    }
    return relativeLink;
}
