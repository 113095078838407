import lowerCase from 'lodash/lowerCase';
import PERSONALIZATION_REPLACEMENTS from '../../../react/constants/personalization-replacements';
const MALE = 'male';
const FEMALE = 'female';
export { FEMALE, MALE, asGenitive, asObjectPronoun, asPossessivePronoun, asReflexivePronoun, asSubjectPronoun, getPluralPronouns, getPronouns, };
function asObjectPronoun(gender) {
    switch (lowerCase(gender)) {
        case FEMALE:
            return 'her';
        case MALE:
            return 'him';
        default:
            return 'them';
    }
}
function asPossessivePronoun(gender) {
    switch (lowerCase(gender)) {
        case FEMALE:
            return 'her';
        case MALE:
            return 'his';
        default:
            return 'their';
    }
}
function asSubjectPronoun(gender) {
    switch (lowerCase(gender)) {
        case FEMALE:
            return 'she';
        case MALE:
            return 'he';
        default:
            return 'they';
    }
}
function asReflexivePronoun(gender) {
    switch (lowerCase(gender)) {
        case FEMALE:
            return 'herself';
        case MALE:
            return 'himself';
        default:
            return 'themself';
    }
}
function getPronouns(gender) {
    return {
        [PERSONALIZATION_REPLACEMENTS.SUBJECT_PRONOUN]: asSubjectPronoun(gender),
        [PERSONALIZATION_REPLACEMENTS.SUBJECT_PRONOUN_REVERSE]: asSubjectPronoun(gender),
        [PERSONALIZATION_REPLACEMENTS.OBJECT_PRONOUN]: asObjectPronoun(gender),
        [PERSONALIZATION_REPLACEMENTS.OBJECT_PRONOUN_REVERSE]: asObjectPronoun(gender),
        [PERSONALIZATION_REPLACEMENTS.POSSESSIVE_PRONOUN]: asPossessivePronoun(gender),
        [PERSONALIZATION_REPLACEMENTS.POSSESSIVE_PRONOUN_REVERSE]: asPossessivePronoun(gender),
        [PERSONALIZATION_REPLACEMENTS.REFLEXIVE_PRONOUN]: asReflexivePronoun(gender),
        [PERSONALIZATION_REPLACEMENTS.REFLEXIVE_PRONOUN_REVERSE]: asReflexivePronoun(gender),
    };
}
function getPluralPronouns() {
    return {
        [PERSONALIZATION_REPLACEMENTS.SUBJECT_PRONOUN]: 'they',
        [PERSONALIZATION_REPLACEMENTS.SUBJECT_PRONOUN_REVERSE]: 'they',
        [PERSONALIZATION_REPLACEMENTS.OBJECT_PRONOUN]: 'them',
        [PERSONALIZATION_REPLACEMENTS.OBJECT_PRONOUN_REVERSE]: 'them',
        [PERSONALIZATION_REPLACEMENTS.POSSESSIVE_PRONOUN]: 'their',
        [PERSONALIZATION_REPLACEMENTS.POSSESSIVE_PRONOUN_REVERSE]: 'their',
        [PERSONALIZATION_REPLACEMENTS.REFLEXIVE_PRONOUN]: 'themselves',
        [PERSONALIZATION_REPLACEMENTS.REFLEXIVE_PRONOUN_REVERSE]: 'themselves',
    };
}
function asGenitive(str) {
    if (str && /[xsz]$/.test(str)) {
        return `${str}'`;
    }
    else {
        return `${str}'s`;
    }
}
