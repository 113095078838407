import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconAlertTriangle } from 'featherico';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useHeightAsCssVariable } from '../../hooks/useHeightAsCssVariable';
import { LicenseExpiredBanner } from '../LicenseExpiredWarnings';
import UserSessionWarnings from '../UserSessionWarnings';
import CompanyMessage from './CompanyMessage';
import HeaderSparks from './HeaderSparks';
import ImpersonationBanner from './ImpersonationBanner';
import TranslationPluginWarning from './TranslationPluginWarning';
const HeaderBroadcast = () => {
    const [node, setNode] = useState(null);
    const ref = useCallback(n => setNode(n), []);
    useHeightAsCssVariable('--headerBroadcastOffset', node);
    return (_jsxs("div", { className: "broadcastContainer", ref: ref, children: [_jsx("div", { className: "broadcast old-browser-warning", children: _jsxs("div", { children: [_jsxs("strong", { children: [_jsx(IconAlertTriangle, {}), " We no longer support this browser."] }), ' ', "Some features may be unstable or unusable.", ' ', _jsx("a", { href: "https://intercomdocs.small-improvements.com/en/articles/9146253-system-requirements", children: "Please use one of our supported browsers." })] }) }), _jsx(LicenseExpiredBanner, {}), _jsx(UserSessionWarnings, {}), _jsx(HeaderSparks, {}), _jsx(ImpersonationBanner, {}), _jsx(CompanyMessage, {}), _jsx(TranslationPluginWarning, {})] }));
};
export default HeaderBroadcast;
