import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconBell } from 'featherico';
import { map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import InfiniteScroll from '../../InfiniteScroll';
import Spinner from '../../Spinner';
import NotificationItem from '../NotificationItem';
import styles from '../NotificationsDropdown/style.m.less';
import ownStyles from './style.m.less';
import classNames from 'classnames';
const propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    isDropdown: PropTypes.bool,
    className: PropTypes.string,
    actions: PropTypes.shape({
        doGetNotifications: PropTypes.func.isRequired,
    }).isRequired,
};
export default class NotificationsList extends Component {
    UNSAFE_componentWillMount() {
        if (!this.props.notifications.length) {
            this.props.actions.doGetNotifications();
        }
    }
    render() {
        const { notifications, isLoading, isDropdown, className, actions } = this.props;
        const noNotifications = !notifications.length;
        if (isLoading && noNotifications) {
            return (_jsx("div", { className: ownStyles.spinner, children: _jsx(Spinner, {}) }));
        }
        if (noNotifications) {
            return _jsx("div", { className: ownStyles.empty, children: "No notifications." });
        }
        const containerClasses = classNames(className, styles.list, { [styles.dropdown]: isDropdown });
        const getMore = noOfItems => {
            const limit = isDropdown ? 20 : 40;
            actions.doGetNotifications({ offset: noOfItems, limit });
        };
        const infiniteScrollProps = {
            items: notifications,
            initialCount: isDropdown ? 10 : 20,
            increment: count => count + 10,
            onItemsDepleted: getMore,
            noToTop: true,
            loader: _jsx(Spinner, {}),
            closer: isDropdown ? null : _jsx(NoMoreNotifications, {}),
            className: isDropdown ? [styles.has_more_content] : null,
        };
        return (_jsx(InfiniteScroll, Object.assign({}, infiniteScrollProps, { children: items => _jsx(InnerList, { className: containerClasses, items: items }) })));
    }
}
function NoMoreNotifications() {
    return (_jsxs("div", { className: styles.no_more_notifications, children: [_jsx(IconBell, {}), " No more notifications"] }));
}
function InnerList({ items, className }) {
    return (_jsx("ul", { className: className, children: map(item => (_jsx(NotificationItem, { item: item }, item.id)), items) }));
}
NotificationsList.propTypes = propTypes;
