import { useCallback, useState } from 'react';
const useToggle = (initialValue = false) => {
    const [value, setValue] = useState(initialValue);
    const toggleValue = useCallback(() => {
        setValue(oldValue => !oldValue);
    }, []);
    return [value, toggleValue];
};
export default useToggle;
export const useBooleanState = (initialState = false) => {
    const [value, setValue] = useState(initialState);
    const setFalse = useCallback(() => setValue(false), []);
    const setTrue = useCallback(() => setValue(true), []);
    return [value, setTrue, setFalse];
};
