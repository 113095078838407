import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
CompanyLink.propTypes = {
    company: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};
export default function CompanyLink({ company }) {
    return _jsx("a", { href: `/app/observer/company/${company.id}`, children: company.name });
}
