import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getIconImageUrlWithDefault, isCustomIcon } from '../../../../services/Badge';
import styles from './style.m.less';
const propTypes = {
    badge: PropTypes.object,
    size: PropTypes.string,
    ratio: PropTypes.number,
    className: PropTypes.string,
};
const defaultProps = {
    size: 'auto',
};
const CustomBadge = ({ image, altText, sizeStyle, className }) => (_jsx("img", { src: image, style: sizeStyle, alt: altText, className: classNames(styles.customBadgeImage, className) }));
const setSizeStyle = size => ({
    width: size,
    height: size,
});
export default function Badge({ baseUrl = '', badge, size, className }) {
    const badgeUrl = baseUrl + getIconImageUrlWithDefault(badge === null || badge === void 0 ? void 0 : badge.icon);
    const sizeStyle = setSizeStyle(size);
    const altText = 'Badge: ' + (badge === null || badge === void 0 ? void 0 : badge.name);
    if (badge && isCustomIcon(badge.icon)) {
        return _jsx(CustomBadge, { sizeStyle: sizeStyle, altText: altText, image: badgeUrl, className: className });
    }
    else {
        return (_jsx("img", { style: sizeStyle, src: badgeUrl, alt: altText, className: classNames(styles.badgeImage, className) }));
    }
}
export function StandaloneCustomBadge({ image, size }) {
    return (_jsx("div", { style: setSizeStyle(size), children: _jsx(CustomBadge, { image: image }) }));
}
Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;
