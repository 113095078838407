import { __rest } from "tslib";
import { parse, stringify } from 'query-string';
import { getCurrentUser } from './react/services/CurrentUser';
// eslint-disable-next-line no-restricted-imports
import * as RR from './removed-routes';
import * as R from './routes';
import { getRedirectsForHR } from './routes-pairs';
export default [
    ['/', R.DASHBOARD],
    [RR.DASHBOARD, R.DASHBOARD],
    [RR.USER_DIRECTORY, R.YOUR_COMPANY_USERS],
    [RR.USER_CREATE, R.YOUR_COMPANY_USERS_CREATE],
    [RR.YOUR_COMPANY_USER_CREATE, R.YOUR_COMPANY_USERS_CREATE],
    [RR.REVIEW_CYCLE_OVERVIEW, R.YOUR_COMPANY_REVIEWS_SELECT_CYCLE],
    [RR.ADMIN_REVIEWS_SETTINGS, R.ADMIN_REVIEWS_EMAILS],
    [RR.REVIEW_GRAPH_COMPANY, R.YOUR_COMPANY_REVIEWS_SELECT_CYCLE],
    [RR.REVIEW_GRAPH_TEAM, R.YOUR_TEAM_REVIEWS_SELECT_CYCLE],
    [RR.REVIEW_NO_CYCLE, R.YOUR_TEAM_REVIEWS_SELECT_CYCLE],
    [RR.REVIEW_OVERVIEW, R.YOUR_COMPANY_REVIEWS_SELECT_CYCLE],
    [RR.REVIEW_OVERVIEW_WITH_CYCLE, R.YOUR_COMPANY_REVIEWS_WITH_CYCLE],
    [RR.REVIEW_TEAM, R.YOUR_TEAM_REVIEWS_SELECT_CYCLE],
    [RR.REVIEW_TEAM_WITH_CYCLE, R.YOUR_TEAM_REVIEWS_WITH_CYCLE],
    [RR.MEETING_DASHBOARD, R.ADMIN_MEETINGS_INSIGHTS],
    [RR.OBJECTIVE_CYCLE_OVERVIEW, R.YOUR_COMPANY_OBJECTIVES_SELECT_CYCLE],
    [RR.OBJECTIVE_NO_CYCLE, R.YOUR_TEAM_OBJECTIVES_SELECT_CYCLE],
    [RR.OBJECTIVE_OVERVIEW, R.YOUR_COMPANY_OBJECTIVES_SELECT_CYCLE],
    [RR.OBJECTIVE_OVERVIEW_WITH_CYCLE, R.YOUR_COMPANY_OBJECTIVES_OVERVIEW],
    [RR.OBJECTIVE_TEAM_WITH_CYCLE, R.YOUR_TEAM_OBJECTIVES_OVERVIEW],
    [RR.ADMIN_PLATFORMS, R.ADMIN_MESSAGES],
    [RR.ADMIN_PLATFORMS_EDIT, R.ADMIN_MESSAGES],
    [RR.ADMIN_COMPANY_BASICS, R.ADMIN_FEATURE_SELECTION],
    [RR.FEEDBACK_CYCLE_OVERVIEW, R.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE],
    [RR.ADMIN_FEEDBACK_SETTINGS, R.ADMIN_FEEDBACK_EMAILS],
    [RR.FEEDBACK_OVERVIEW, R.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE],
    [RR.FEEDBACK_OVERVIEW_WITH_CYCLE, R.YOUR_COMPANY_FEEDBACK_WITH_CYCLE],
    [RR.FEEDBACK_PERSONAL_CYCLE, R.ADMIN_FEEDBACK_REQUESTED],
    [RR.FEEDBACK_TEAM, R.YOUR_TEAM_FEEDBACK_SELECT_CYCLE],
    [RR.FEEDBACK_NO_CYCLE, R.YOUR_TEAM_FEEDBACK_SELECT_CYCLE],
    [RR.FEEDBACK_TEAM_WITH_CYCLE, R.YOUR_TEAM_FEEDBACK_WITH_CYCLE],
    [RR.FEEDBACK_PROVIDE_PREVIEW, R.FEEDBACK_PREVIEW_ZEN],
    [RR.FEEDBACK_TEMPLATE_OVERVIEW, R.ADMIN_FEEDBACK_REQUESTED_TEMPLATES],
    [RR.OBJECTIVE_BROWSER, handleObjectiveBrowser],
    [RR.YOUR_COMPANY_FEEDBACK_PERSONAL_CYCLE, R.ADMIN_FEEDBACK_REQUESTED],
    [RR.YOUR_COMPANY_FEEDBACK_PERSONAL_CYCLE_OLDER, R.ADMIN_FEEDBACK_REQUESTED],
    [RR.YOUR_TEAM_FEEDBACK_PERSONAL_CYCLE, R.YOUR_TEAM_FEEDBACK_REQUESTED],
    [R.USER_PROFILE_SHORT, () => R.USER_PROFILE_SHORT + '/' + getCurrentUser().id],
    [RR.MESSAGE_STREAM, R.YOUR_COMPANY_MESSAGES],
    [RR.OLD_SAMPLE_CONTENT_MGMT, R.ADMIN_SAMPLE_CONTENT],
    [RR.NEW_USER_PROFILE, R.USER_PROFILE],
    [RR.OLD_USER_PROFILE, R.USER_PROFILE],
    [RR.OLD_ADMIN_EMAIL_MANAGE, R.ADMIN_EMAIL_MANAGE],
    [RR.ENGAGEMENT_SYSTEM, R.DASHBOARD],
    [RR.ADMIN_BAMBOO, R.INTEGRATIONS_BAMBOO],
    [RR.ADMIN_BAMBOO_HISTORY, R.INTEGRATIONS_BAMBOO_HISTORY],
    [RR.ADMIN_BAMBOO_PREVIEW, R.INTEGRATIONS_BAMBOO_PREVIEW],
    [RR.ADMIN_BAMBOO_CONFIG, R.INTEGRATIONS_BAMBOO_CONFIG],
    [RR.ADMIN_GOOGLE_APPS_IMPORT, R.INTEGRATIONS_GOOGLE_WORKSPACE],
    [RR.ADMIN_SLACK_CONFIGURATION, R.INTEGRATIONS_SLACK_CONFIGURATION],
    [RR.ADMIN_NAMELY, R.INTEGRATIONS_NAMELY],
    [RR.ADMIN_NAMELY_HISTORY, R.INTEGRATIONS_NAMELY_HISTORY],
    [RR.ADMIN_NAMELY_PREVIEW, R.INTEGRATIONS_NAMELY_PREVIEW],
    [RR.ADMIN_NAMELY_CONFIG, R.INTEGRATIONS_NAMELY_CONFIG],
    [RR.ADMIN_PERSONIO, R.INTEGRATIONS_PERSONIO],
    [RR.ADMIN_PERSONIO_HISTORY, R.INTEGRATIONS_PERSONIO_HISTORY],
    [RR.ADMIN_PERSONIO_PREVIEW, R.INTEGRATIONS_PERSONIO_PREVIEW],
    [RR.ADMIN_PERSONIO_CONFIG, R.INTEGRATIONS_PERSONIO_CONFIG],
    [RR.ADMIN_SAPLING, R.INTEGRATIONS_KALLIDUS],
    [RR.ADMIN_SAPLING_HISTORY, R.INTEGRATIONS_KALLIDUS_HISTORY],
    [RR.ADMIN_SAPLING_PREVIEW, R.INTEGRATIONS_KALLIDUS_PREVIEW],
    [RR.ADMIN_SAPLING_CONFIG, R.INTEGRATIONS_KALLIDUS_CONFIG],
    [RR.ADMIN_CALENDAR_INTEGRATION, R.INTEGRATIONS_CALENDAR_INTEGRATION],
    [RR.ADMIN_SAML, R.INTEGRATIONS_SAML],
    [RR.ADMIN_BADGES, R.ADMIN_PRAISE],
    [RR.INTEGRATIONS_SAPLING, R.INTEGRATIONS_KALLIDUS],
    [RR.INTEGRATIONS_SAPLING_HISTORY, R.INTEGRATIONS_KALLIDUS_HISTORY],
    [RR.INTEGRATIONS_SAPLING_PREVIEW, R.INTEGRATIONS_KALLIDUS_PREVIEW],
    [RR.INTEGRATIONS_SAPLING_CONFIG, R.INTEGRATIONS_KALLIDUS_CONFIG],
    [RR.YOUR_COMPANY_MEETINGS_SETTINGS, R.ADMIN_MEETINGS_SETTINGS],
    [RR.YOUR_COMPANY_MEETINGS_INSIGHTS, R.ADMIN_MEETINGS_INSIGHTS],
    [RR.YOUR_COMPANY_MEETINGS_TEMPLATES, R.ADMIN_MEETINGS_TEMPLATES],
    [RR.YOUR_COMPANY_MEETINGS_TEMPLATES_CREATE, R.ADMIN_MEETINGS_TEMPLATES_CREATE],
    [RR.YOUR_COMPANY_MEETINGS_TEMPLATES_EDIT, R.ADMIN_MEETINGS_TEMPLATES_EDIT],
    [RR.SURVEYS_CREATE, R.ADMIN_SURVEYS_CREATE],
    [RR.SURVEYS_EDIT, R.ADMIN_SURVEYS_EDIT],
    [RR.SURVEYS_COPY, R.ADMIN_SURVEYS_COPY],
    [RR.OBJECTIVE_CYCLE_CREATE, R.ADMIN_OBJECTIVE_CYCLE_CREATE],
    [RR.OBJECTIVE_CYCLE_EDIT, R.ADMIN_OBJECTIVE_CYCLE_EDIT],
    [RR.OBJECTIVE_CYCLE_COPY, R.ADMIN_OBJECTIVE_CYCLE_COPY],
    [RR.ADMIN_OBJECTIVES_SETTINGS, R.ADMIN_OBJECTIVES_EMAILS],
    [RR.FEEDBACK_CYCLE_CREATE_NEW, R.ADMIN_FEEDBACK_CYCLE_CREATE],
    [RR.FEEDBACK_CYCLE_COPY_NEW, R.ADMIN_FEEDBACK_CYCLE_COPY],
    [RR.YOUR_COMPANY_FEEDBACK_REQUESTED, R.ADMIN_FEEDBACK_REQUESTED],
    [RR.YOUR_COMPANY_FEEDBACK_REQUESTED_TEMPLATES, R.ADMIN_FEEDBACK_REQUESTED_TEMPLATES],
    [RR.YOUR_COMPANY_FEEDBACK_REQUESTED_SETTINGS, R.ADMIN_FEEDBACK_REQUESTED_SETTINGS],
    [RR.REVIEW_CYCLE_CREATE, R.ADMIN_REVIEW_CYCLE_CREATE],
    [RR.REVIEW_CYCLE_COPY, R.ADMIN_REVIEW_CYCLE_COPY],
    [RR.YOUR_COMPANY_PRAISE, R.ADMIN_PRAISE],
    [RR.ADMIN_V2_MEETINGS, R.ADMIN_MEETINGS],
    [RR.ADMIN_V2_MEETINGS_INSIGHTS, R.ADMIN_MEETINGS_INSIGHTS],
    [RR.ADMIN_V2_MEETINGS_SETTINGS, R.ADMIN_MEETINGS_SETTINGS],
    [RR.ADMIN_V2_MEETINGS_TEMPLATES, R.ADMIN_MEETINGS_TEMPLATES],
    [RR.ADMIN_V2_MEETINGS_TEMPLATES_CREATE, R.ADMIN_MEETINGS_TEMPLATES_CREATE],
    [RR.ADMIN_V2_MEETINGS_TEMPLATES_EDIT, R.ADMIN_MEETINGS_TEMPLATES_EDIT],
    [RR.ADMIN_V2_PRAISE, R.ADMIN_PRAISE],
    [RR.ADMIN_V2_SURVEYS, R.ADMIN_SURVEYS],
    [RR.ADMIN_V2_SURVEYS_WITH_SURVEY, R.ADMIN_SURVEYS_WITH_SURVEY],
    [RR.ADMIN_V2_SURVEYS_WITH_SURVEY_PRINT, R.ADMIN_SURVEYS_WITH_SURVEY_PRINT],
    [RR.ADMIN_V2_SURVEYS_CREATE, R.ADMIN_SURVEYS_CREATE],
    [RR.ADMIN_V2_SURVEYS_EDIT, R.ADMIN_SURVEYS_EDIT],
    [RR.ADMIN_V2_SURVEYS_COPY, R.ADMIN_SURVEYS_COPY],
    [RR.ADMIN_V2_OBJECTIVES_SELECT_CYCLE, R.ADMIN_OBJECTIVES_SELECT_CYCLE],
    [RR.ADMIN_V2_OBJECTIVES_CYCLE_OVERVIEW, R.ADMIN_OBJECTIVES_CYCLE_OVERVIEW],
    [RR.ADMIN_V2_OBJECTIVE_CYCLE_CREATE, R.ADMIN_OBJECTIVE_CYCLE_CREATE],
    [RR.ADMIN_V2_OBJECTIVE_CYCLE_EDIT, R.ADMIN_OBJECTIVE_CYCLE_EDIT],
    [RR.ADMIN_V2_OBJECTIVE_CYCLE_COPY, R.ADMIN_OBJECTIVE_CYCLE_COPY],
    [RR.ADMIN_V2_FEEDBACK_SELECT_CYCLE, R.ADMIN_FEEDBACK_SELECT_CYCLE],
    [RR.ADMIN_V2_FEEDBACK_WITH_CYCLE, R.ADMIN_FEEDBACK_WITH_CYCLE],
    [RR.ADMIN_V2_FEEDBACK_CYCLE_CREATE, R.ADMIN_FEEDBACK_CYCLE_CREATE],
    [RR.ADMIN_V2_FEEDBACK_CYCLE_EDIT, R.ADMIN_FEEDBACK_CYCLE_EDIT],
    [RR.ADMIN_V2_FEEDBACK_CYCLE_COPY, R.ADMIN_FEEDBACK_CYCLE_COPY],
    [RR.ADMIN_V2_FEEDBACK_TEMPLATES, R.ADMIN_FEEDBACK_TEMPLATES],
    [RR.ADMIN_V2_FEEDBACK_REQUESTED, R.ADMIN_FEEDBACK_REQUESTED],
    [RR.ADMIN_V2_FEEDBACK_REQUESTED_TEMPLATES, R.ADMIN_FEEDBACK_REQUESTED_TEMPLATES],
    [RR.ADMIN_V2_FEEDBACK_REQUESTED_SETTINGS, R.ADMIN_FEEDBACK_REQUESTED_SETTINGS],
    [RR.ADMIN_V2_REVIEWS_SELECT_CYCLE, R.ADMIN_REVIEWS_SELECT_CYCLE],
    [RR.ADMIN_V2_REVIEWS_QUESTIONNAIRES, R.ADMIN_REVIEWS_QUESTIONNAIRES],
    [RR.ADMIN_V2_REVIEWS_WITH_CYCLE, R.ADMIN_REVIEWS_WITH_CYCLE],
    [RR.ADMIN_V2_REVIEW_CYCLE_CREATE, R.ADMIN_REVIEW_CYCLE_CREATE],
    [RR.ADMIN_V2_REVIEW_CYCLE_EDIT, R.ADMIN_REVIEW_CYCLE_EDIT],
    [RR.ADMIN_V2_REVIEW_CYCLE_COPY, R.ADMIN_REVIEW_CYCLE_COPY],
    [RR.ADMIN_V2_REVIEW_GRAPH_WITH_CYCLE, R.ADMIN_REVIEW_GRAPH_WITH_CYCLE],
    [RR.ADMIN_V2_USERS_DIRECTORY, R.ADMIN_USERS_DIRECTORY],
    [RR.ADMIN_V2_USERS_DIRECTORY_CREATE, R.ADMIN_USERS_DIRECTORY_CREATE],
    [RR.ADMIN_V2_MESSAGES, R.ADMIN_MESSAGES],
    [RR.ADMIN_V2_DASHBOARD, R.ADMIN_DASHBOARD],
    [RR.ADMIN_OVERVIEW, R.ADMIN_OVERVIEW],
    [RR.ADMIN_FEATURE_SELECTION, R.ADMIN_FEATURE_SELECTION],
    [RR.ADMIN_REPORT_EMAILS, R.ADMIN_REPORT_EMAILS],
    [RR.ADMIN_MAIL_LOG, R.ADMIN_MAIL_LOG],
    [RR.ADMIN_BOUNCED_EMAILS, R.ADMIN_BOUNCED_EMAILS],
    [RR.ADMIN_MESSAGE_STATISTICS, R.ADMIN_MESSAGE_STATISTICS],
    [RR.ADMIN_MERGE_USERS, R.ADMIN_MERGE_USERS],
    [RR.ADMIN_OBJECTIVE_CATEGORIES, R.ADMIN_OBJECTIVE_CATEGORIES],
    [RR.ADMIN_OBJECTIVE_RATINGS, R.ADMIN_OBJECTIVE_RATINGS],
    [RR.ADMIN_PAYMENT_DETAILS, R.ADMIN_PAYMENT_DETAILS],
    [RR.ADMIN_BILLING, R.ADMIN_BILLING],
    [RR.ADMIN_BILLING_CHECKOUT, R.ADMIN_BILLING_CHECKOUT],
    [RR.ADMIN_BILLING_CHECKOUT_STEP, R.ADMIN_BILLING_CHECKOUT_STEP],
    [RR.ADMIN_CSV_IMPORT, R.ADMIN_CSV_IMPORT],
    [RR.ADMIN_EMAIL_MANAGE, R.ADMIN_EMAIL_MANAGE],
    [RR.ADMIN_HELPFUL_RESOURCES, R.ADMIN_HELPFUL_RESOURCES],
    [RR.ADMIN_SAMPLE_CONTENT, R.ADMIN_SAMPLE_CONTENT],
    [RR.ADMIN_SAMPLE_CONTENT_PROGRESS, R.ADMIN_SAMPLE_CONTENT_PROGRESS],
    [RR.ADMIN_HIP_CHAT, R.ADMIN_HIP_CHAT],
    [RR.ADMIN_DESIGN, R.ADMIN_DESIGN],
    [RR.ADMIN_DATETIME, R.ADMIN_DATETIME],
    [RR.ADMIN_CUSTOM_WORDING, R.ADMIN_CUSTOM_WORDING],
    [RR.INTEGRATIONS, R.INTEGRATIONS],
    [RR.INTEGRATIONS_BAMBOO, R.INTEGRATIONS_BAMBOO],
    [RR.INTEGRATIONS_BAMBOO_HISTORY, R.INTEGRATIONS_BAMBOO_HISTORY],
    [RR.INTEGRATIONS_BAMBOO_PREVIEW, R.INTEGRATIONS_BAMBOO_PREVIEW],
    [RR.INTEGRATIONS_BAMBOO_CONFIG, R.INTEGRATIONS_BAMBOO_CONFIG],
    [RR.INTEGRATIONS_GOOGLE_APPS_IMPORT, R.INTEGRATIONS_GOOGLE_WORKSPACE],
    [RR.INTEGRATIONS_ADMIN_GOOGLE_APPS_IMPORT, R.INTEGRATIONS_GOOGLE_WORKSPACE],
    [RR.INTEGRATIONS_SLACK_CONFIGURATION, R.INTEGRATIONS_SLACK_CONFIGURATION],
    [RR.INTEGRATIONS_NAMELY, R.INTEGRATIONS_NAMELY],
    [RR.INTEGRATIONS_NAMELY_HISTORY, R.INTEGRATIONS_NAMELY_HISTORY],
    [RR.INTEGRATIONS_NAMELY_PREVIEW, R.INTEGRATIONS_NAMELY_PREVIEW],
    [RR.INTEGRATIONS_NAMELY_CONFIG, R.INTEGRATIONS_NAMELY_CONFIG],
    [RR.INTEGRATIONS_PERSONIO, R.INTEGRATIONS_PERSONIO],
    [RR.INTEGRATIONS_PERSONIO_HISTORY, R.INTEGRATIONS_PERSONIO_HISTORY],
    [RR.INTEGRATIONS_PERSONIO_PREVIEW, R.INTEGRATIONS_PERSONIO_PREVIEW],
    [RR.INTEGRATIONS_PERSONIO_CONFIG, R.INTEGRATIONS_PERSONIO_CONFIG],
    [RR.INTEGRATIONS_KALLIDUS, R.INTEGRATIONS_KALLIDUS],
    [RR.INTEGRATIONS_KALLIDUS_HISTORY, R.INTEGRATIONS_KALLIDUS_HISTORY],
    [RR.INTEGRATIONS_KALLIDUS_PREVIEW, R.INTEGRATIONS_KALLIDUS_PREVIEW],
    [RR.INTEGRATIONS_KALLIDUS_CONFIG, R.INTEGRATIONS_KALLIDUS_CONFIG],
    [RR.INTEGRATIONS_CALENDAR_INTEGRATION, R.INTEGRATIONS_CALENDAR_INTEGRATION],
    [RR.INTEGRATIONS_SAML, R.INTEGRATIONS_SAML],
    [RR.INTEGRATIONS_WORKDAY, R.INTEGRATIONS_WORKDAY],
    [RR.INTEGRATIONS_WORKDAY_HISTORY, R.INTEGRATIONS_WORKDAY_HISTORY],
    [RR.INTEGRATIONS_WORKDAY_PREVIEW, R.INTEGRATIONS_WORKDAY_PREVIEW],
    [RR.INTEGRATIONS_WORKDAY_CONFIG, R.INTEGRATIONS_WORKDAY_CONFIG],
    [RR.INTEGRATIONS_SFTP, R.INTEGRATIONS_SFTP],
    [RR.INTEGRATIONS_SFTP_HISTORY, R.INTEGRATIONS_SFTP_HISTORY],
    [RR.INTEGRATIONS_SFTP_CONFIG, R.INTEGRATIONS_SFTP_CONFIG],
    [RR.RETENTION, R.RETENTION],
    [RR.INSIGHTS, R.INSIGHTS],
    [RR.ADMIN_ADVANCED, R.ADMIN_ADVANCED],
    [RR.ADMIN_USERSETTINGS, R.ADMIN_USERSETTINGS],
    [RR.ADMIN_V2_AUTOMATIONS, R.HR_AUTOMATIONS],
    ...getGuestRedirects(),
    ...getRedirectsForHR(),
];
function handleObjectiveBrowser({ location }) {
    const _a = parse(location.search), { select } = _a, queryParams = __rest(_a, ["select"]);
    if (!select || select === 'me') {
        return addQueryParams(R.OBJECTIVE_BROWSER, queryParams);
    }
    if (select === 'all') {
        return addQueryParams(R.YOUR_COMPANY_OBJECTIVES_BROWSER, queryParams);
    }
    if (select === 'team' || select === 'coworkers') {
        return addQueryParams(R.YOUR_TEAM_OBJECTIVES_BROWSER, queryParams);
    }
    const id = select === 'manager' ? getCurrentUser().managerId : select;
    return `/user/${id}?tab=objectives`;
}
function addQueryParams(path, params) {
    return path + '?' + stringify(params);
}
function getGuestRedirects() {
    const user = getCurrentUser();
    if (!user || !user.isGuest) {
        return [];
    }
    return [
        [R.DASHBOARD, R.FEEDBACK_USER],
        [R.DASHBOARD_ITEM, R.FEEDBACK_USER],
    ];
}
