import { noop } from 'lodash';
import '../base/legacy';
import './legacy';
import './mounts';
import { initPerformanceTracking } from './react/services/PerformanceTracking';
import { initSprig } from './react/services/Sprig';
import { getTracking, trackEvent } from './react/services/Tracking';
import { insertGtag } from './react/services/Tracking/gtag';
import { initAppcues } from './react/services/appcues';
import { initIntercom } from './react/services/intercom';
import init from './render';
initPerformanceTracking((loadTimeMs, initialLoad) => {
    trackEvent({
        category: 'SYSTEM',
        action: 'measure load time',
        label: `Performance Metrics (${initialLoad ? 'initial load' : 'spa'})`,
        value: Math.round(loadTimeMs),
        nonInteractive: true,
    });
});
getTracking().catch(noop);
insertGtag().catch(noop);
if (module.hot) {
    module.hot.decline();
}
init();
initAppcues();
initSprig();
initIntercom();
