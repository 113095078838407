import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconX } from 'featherico';
import Button from '../../../../components/Button';
import { FormSelect } from '../../../../components/Form/FormInputs';
import { FormItem } from '../../../../components/Form/FormItem';
import r from '../../../../services/Replacement';
const USER_PROPERTIES = {
    firstName: 'First Name',
    lastName: 'Last Name',
    description: 'Description',
    gender: 'Gender',
    role: 'Title',
    group: r('team.big'),
    department: 'Department',
    location: 'Location',
    hiringDate: 'Hiring Date',
    terminationDate: 'Termination Date',
    preferredName: 'Preferred Name',
    secondaryReviewerLogin: `${r('secondaryManager.big')} (email)`,
};
export default function CustomFieldMapping({ caption, sourceLabel, targetLabel, config, field, onDelete, nrFields, }) {
    return (_jsxs("section", { children: [_jsxs("div", { className: "admin-config-custom-mapping-container", children: [config.supportsFieldId && (_jsx("div", { children: _jsx(FormItem, { name: `${field}.customMappingSourceId`, label: `${sourceLabel} Id (Optional)`, inputProps: {
                                type: 'number',
                                placeholder: '123456',
                            }, showError: () => true }) })), _jsx("div", { children: _jsx(FormItem, { name: `${field}.customMappingSource`, label: sourceLabel, inputProps: {
                                placeholder: 'e.g. firstName',
                            }, showError: () => true }) }), _jsx("div", { children: _jsx(FormItem, { name: `${field}.customMappingTarget`, type: "select", label: targetLabel, inputComponent: FormSelect, showError: () => true, inputProps: {
                                options: [null, ...Object.keys(USER_PROPERTIES)],
                                optionToText: (property) => USER_PROPERTIES[property] || '-',
                            } }) }), _jsx("div", { style: { flex: 0, minWidth: 17, alignSelf: 'top', marginTop: '35px' }, children: nrFields > 1 && (_jsx(Button, { kind: "PLAIN", type: "button", onClick: onDelete, children: _jsx(IconX, {}) })) })] }), _jsx("aside", { children: caption })] }));
}
