import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconPlusCircle } from 'featherico';
import { isEqual } from 'lodash/fp';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Button from '../../../../components/Button';
import CheckboxContainer from '../../../../components/CheckboxContainer';
import { isFeatureEnabled } from '../../../../services/Features';
import ConfigWithCaption from './ConfigWithCaption';
import CustomFieldMapping from './CustomFieldMapping';
import { CUSTOM_FIELD, CUSTOM_FIELD_OVERWRITE_EMPTY } from './constants';
import { mkCustomMapping } from './customMapping';
export default function CustomMappingSection({ name, config }) {
    const { values } = useFormState();
    const { mutators } = useForm();
    if (!isFeatureEnabled('FEATURE_userImportCustomFields')) {
        return null;
    }
    return (_jsxs("div", { children: [_jsx("h5", { children: "Custom Fields" }), isFeatureEnabled('FEATURE_userImportCustomFieldsKeepOriginalOption') && (_jsx(CheckboxContainer, { children: _jsx(ConfigWithCaption, { name: "customMappingKeepOriginalValueIfEmpty", caption: CUSTOM_FIELD_OVERWRITE_EMPTY.caption(), label: CUSTOM_FIELD_OVERWRITE_EMPTY.label, isDisabled: false }) })), _jsx(FieldArray, { name: "customMappings", isEqual: isEqual, children: ({ fields }) => {
                    const deleteRow = (i) => (fields.length && fields.length > 1 ? fields.remove(i) : null);
                    return (_jsx(_Fragment, { children: fields.map((field, index) => (_jsx(CustomFieldMapping, { caption: CUSTOM_FIELD.caption(name), sourceLabel: CUSTOM_FIELD.sourceLabel(name), targetLabel: CUSTOM_FIELD.targetLabel, config: config, field: field, onDelete: () => deleteRow(index), nrFields: fields.length || 0, customMappingState: values.customMappings[index] }, field))) }));
                } }), _jsx("section", { style: { marginBottom: '30px' }, children: _jsx("div", { children: _jsxs(Button, { kind: "LINK", onClick: () => mutators.push('customMappings', mkCustomMapping()), children: [_jsx(IconPlusCircle, {}), " Customize another field"] }) }) })] }));
}
