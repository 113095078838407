import * as ROUTES from '../routes';
export default {
    [ROUTES.ADMIN_DESIGN]: () => import(/* webpackChunkName: 'AdminDesign' */ '../screens/admin/design/route'),
    [ROUTES.ADMIN_PAYMENT_DETAILS]: () => import(/* webpackChunkName: 'AdminPaymentDetails' */ '../screens/admin/payment-details/route'),
    [ROUTES.INTEGRATIONS_SLACK_CONFIGURATION]: () => import(/* webpackChunkName: 'AdminSlackConfiguration' */ '../screens/admin/slack-configuration/route'),
    [ROUTES.DOWNLOADS]: () => import(/* webpackChunkName: 'Downloads' */ '../screens/downloads/pages/downloads/route'),
    [ROUTES.MESSAGE_SINGLE]: () => import(/* webpackChunkName: 'Messages' */ '../screens/message/pages/message-detail-controller/route'),
    [ROUTES.OBSERVER_COMPANY_USER]: () => import(/* webpackChunkName: 'ObserverCompanyUser' */ '../screens/observer/routes/observer-company-user'),
    [ROUTES.OBSERVER_USER]: () => import(/* webpackChunkName: 'ObserverUser' */ '../screens/observer/routes/observer-user'),
    [ROUTES.OBJECTIVE_BROWSER]: () => import(/* webpackChunkName: 'ObjectiveBrowser' */ '../screens/objective/routes/your-objectives'),
    [ROUTES.OBJECTIVE_SINGLE]: () => import(/* webpackChunkName: 'ObjectiveSingle' */ '../screens/objective/objective-single/route'),
    [ROUTES.OBJECTIVE_SINGLE_BY_ID]: () => import(/* webpackChunkName: 'ObjectiveSingle' */ '../screens/objective/objective-single/only-by-id-route'),
    [ROUTES.REVIEW_GRAPH_COMPANY_WITH_CYCLE]: () => import(/* webpackChunkName: 'ReviewReportingGraph' */ '../screens/reviews/pages/review-graph/route'),
    [ROUTES.REVIEW_GRAPH_TEAM_WITH_CYCLE]: () => import(/* webpackChunkName: 'ReviewReportingGraph' */ '../screens/reviews/pages/review-graph/route'),
    [ROUTES.USER_EDIT]: () => import(/* webpackChunkName: 'UsersEdit' */ '../screens/users/pages/user-edit/route'),
};
export const restrictedRoutes = {
    [ROUTES.ADMIN_OBJECTIVES_SELECT_CYCLE]: () => import(/* webpackChunkName: 'CycleManagement' */ '../screens/objective/pages/objective-admin-page/route'),
    [ROUTES.ADMIN_REVIEW_GRAPH_WITH_CYCLE]: () => import(/* webpackChunkName: 'ReviewReportingGraph' */ '../screens/reviews/pages/review-graph/route'),
};
export const childRoutes = {
    [ROUTES.YOUR_COMPANY_MESSAGES]: () => import(/* webpackChunkName: 'Messages' */ '../screens/message/pages/message-company-tab-controller/route'),
    [ROUTES.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE]: () => import(
    /* webpackChunkName: 'CycleManagement' */ '../screens/feedback/pages/feedback-company-select-cycle/route'),
    [ROUTES.YOUR_COMPANY_FEEDBACK_WITH_CYCLE]: () => import(
    /* webpackChunkName: 'FeedbackCycleOverview' */ '../screens/feedback/pages/feedback-single-cycle/company-route'),
    [ROUTES.YOUR_COMPANY_OBJECTIVES_BROWSER]: () => import(/* webpackChunkName: 'ObjectiveBrowser' */ '../screens/objective/routes/your-company-objectives'),
    [ROUTES.YOUR_COMPANY_OBJECTIVES_OVERVIEW]: () => import(
    /* webpackChunkName: 'ObjectiveCycleOverview' */ '../screens/objective/routes/your-company-objectives-overview'),
    [ROUTES.YOUR_COMPANY_OBJECTIVES_SELECT_CYCLE]: () => import(/* webpackChunkName: 'CycleManagement' */ '../screens/objective/objective-company-select-cycle/route'),
    [ROUTES.YOUR_COMPANY_REVIEWS_SELECT_CYCLE]: () => import(/* webpackChunkName: 'CycleManagement' */ '../screens/reviews/pages/review-company-select-cycle/route'),
    [ROUTES.YOUR_COMPANY_REVIEWS_WITH_CYCLE]: () => import(
    /* webpackChunkName: 'ReviewCycleOverview' */ '../screens/reviews/pages/review-single-cycle/company-route'),
    [ROUTES.YOUR_COMPANY_USERS]: () => import(/* webpackChunkName: 'UserDirectory' */ '../screens/users/pages/user-directory/route'),
    [ROUTES.YOUR_TEAM_FEEDBACK_SELECT_CYCLE]: () => import(/* webpackChunkName: 'CycleManagement' */ '../screens/feedback/pages/feedback-select-cycle/route'),
    [ROUTES.YOUR_TEAM_FEEDBACK_WITH_CYCLE]: () => import(
    /* webpackChunkName: 'FeedbackCycleOverview' */ '../screens/feedback/pages/feedback-single-cycle/team-route'),
    [ROUTES.YOUR_TEAM_OBJECTIVES_BROWSER]: () => import(/* webpackChunkName: 'ObjectiveBrowser' */ '../screens/objective/routes/your-team-objectives'),
    [ROUTES.YOUR_TEAM_OBJECTIVES_OVERVIEW]: () => import(
    /* webpackChunkName: 'ObjectiveCycleOverview' */ '../screens/objective/routes/your-team-objectives-overview'),
    [ROUTES.YOUR_TEAM_OBJECTIVES_SELECT_CYCLE]: () => import(/* webpackChunkName: 'CycleManagement' */ '../screens/objective/objective-team-select-cycle/route'),
    [ROUTES.YOUR_TEAM_REVIEWS_SELECT_CYCLE]: () => import(/* webpackChunkName: 'CycleManagement' */ '../screens/reviews/pages/review-select-cycle/route'),
    [ROUTES.YOUR_TEAM_REVIEWS_WITH_CYCLE]: () => import(/* webpackChunkName: 'ReviewCycleOverview' */ '../screens/reviews/pages/review-single-cycle/team-route'),
    [ROUTES.ADMIN_OBJECTIVES_CYCLE_OVERVIEW]: () => import(
    /* webpackChunkName: 'ObjectiveCycleOverview' */ '../screens/objective/routes/your-company-objectives-overview'),
    [ROUTES.ADMIN_FEEDBACK_SELECT_CYCLE]: () => import(
    /* webpackChunkName: 'CycleManagement' */ '../screens/feedback/pages/feedback-company-select-cycle/route'),
    [ROUTES.ADMIN_FEEDBACK_WITH_CYCLE]: () => import(
    /* webpackChunkName: 'FeedbackCycleOverview' */ '../screens/feedback/pages/feedback-single-cycle/company-route'),
    [ROUTES.ADMIN_REVIEWS_SELECT_CYCLE]: () => import(/* webpackChunkName: 'CycleManagement' */ '../screens/reviews/pages/review-company-select-cycle/route'),
    [ROUTES.ADMIN_REVIEWS_WITH_CYCLE]: () => import(
    /* webpackChunkName: 'ReviewCycleOverview' */ '../screens/reviews/pages/review-single-cycle/company-route'),
    [ROUTES.ADMIN_USERS_DIRECTORY]: () => import(/* webpackChunkName: 'UserDirectory' */ '../screens/users/pages/user-directory/route'),
};
if (module.hot) {
    module.hot.decline();
}
