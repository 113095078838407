import { useEffect, useState } from 'react';
import { getCurrentUser, subscribe } from '../services/CurrentUser';
const createAppContext = (user) => ({
    user,
});
export const useAppContext = () => {
    const [appContext, setAppContext] = useState(createAppContext(getCurrentUser()));
    useEffect(() => {
        const unsubscribe = subscribe(updatedCurrentUser => {
            setAppContext(createAppContext(updatedCurrentUser));
        });
        return () => unsubscribe();
    }, []);
    return appContext;
};
