import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
class ClickOutside extends Component {
    constructor() {
        super(...arguments);
        this.node = createRef();
        this.handleDocumentClick = (event) => {
            if (this.node.current === null) {
                return;
            }
            if (!this.node.current.contains(event.target)) {
                this.props.onClickOutside(event);
            }
        };
    }
    controlListener(attach) {
        if (attach) {
            document.addEventListener('click', this.handleDocumentClick);
        }
        else {
            document.removeEventListener('click', this.handleDocumentClick);
        }
    }
    componentDidMount() {
        this.controlListener(this.props.enabled);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.enabled !== this.props.enabled) {
            this.controlListener(this.props.enabled);
        }
    }
    componentWillUnmount() {
        this.controlListener(false);
    }
    render() {
        return (_jsx("span", { ref: this.node, className: this.props.className, children: this.props.children }));
    }
}
ClickOutside.propTypes = {
    onClickOutside: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    enabled: PropTypes.bool.isRequired,
    className: PropTypes.string,
};
export default ClickOutside;
