import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import PageHeader from '../PageHeader';
import CycleCreateContent from './CycleCreateContent';
import CycleCreateWarnings from './CycleCreateWarnings';
function CycleCreate(props) {
    const { header, content, controls, warnings, getValidationData } = props.configuration;
    const modelSelector = props.modelSelector || (state => state[props.modelProperty].model);
    return (_jsxs("div", { children: [_jsxs("section", { className: "page_static", children: [_jsx(PageHeader, { title: header.title, documentation: header.documentation }), _jsx(CycleCreateWarnings, { warnings: warnings, modelSelector: modelSelector })] }), _jsx(CycleCreateContent, { content: content, controls: controls, modelSelector: modelSelector, getValidationData: getValidationData })] }));
}
CycleCreate.propTypes = {
    configuration: PropTypes.object,
    header: PropTypes.object,
    controls: PropTypes.object,
};
export default CycleCreate;
