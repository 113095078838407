import { difference, intersection, isUndefined, last, some } from 'lodash';
import visibleInBody from '../../app/common/services/visible-in-body';
const positions = ['', 'left', 'right', 'bottom'];
const optionsAttrKey = 'data-tooltip-options';
const contentClassName = 'data-tooltip-content';
document.body.addEventListener('mouseenter', function (e) {
    if (e.target.matches('[data-tooltip]')) {
        hoverHandler(e.target);
    }
}, true);
function hoverHandler(element) {
    resetPreferredPosition(element);
    if (checkPosition(element)) {
        return;
    }
    if (!some(positions, setAndCheckPosition.bind(null, element))) {
        resetPreferredPosition(element);
    }
}
function resetPreferredPosition(element) {
    const dataKey = 'tooltipPreferredPosition';
    const preferredPosition = element.dataset[dataKey];
    const currentPosition = last(intersection(getTooltipOptions(element), positions)) || '';
    if (isUndefined(preferredPosition)) {
        return (element.dataset[dataKey] = currentPosition);
    }
    if (preferredPosition !== currentPosition) {
        setPosition(element, preferredPosition);
    }
}
function setAndCheckPosition(element, position) {
    setPosition(element, position);
    return checkPosition(element);
}
function setPosition(element, position) {
    const options = getTooltipOptions(element);
    const newOptions = [...difference(options, positions), position];
    element.setAttribute(optionsAttrKey, newOptions.join(' '));
}
function checkPosition(element) {
    const tooltipRect = getTooltipRect(element);
    return tooltipRect && visibleInBody(tooltipRect);
}
function getTooltipOptions(element) {
    return (element.getAttribute(optionsAttrKey) || '').split(' ');
}
function getTooltipRect(element) {
    const tooltipText = element.getAttribute('data-tooltip');
    if (tooltipText) {
        return getPseudoRect(element, tooltipText);
    }
    const tooltipContent = element.getElementsByClassName(contentClassName)[0];
    return tooltipContent && tooltipContent.getBoundingClientRect();
}
function getPseudoRect(element, tooltipText) {
    const measureEl = document.createElement('div');
    measureEl.innerText = tooltipText;
    measureEl.classList.add(contentClassName);
    element.appendChild(measureEl);
    const tooltipRect = measureEl.getBoundingClientRect();
    element.removeChild(measureEl);
    return tooltipRect;
}
