import { isTrackingEnabled } from '.';
const GTAG = 'G-FT6P3MT182';
const SCRIPTID = 'ga-gtag';
export async function insertGtag() {
    if (!isTrackingEnabled()) {
        throw new Error('Tracking is disabled');
    }
    else if (document.getElementById(SCRIPTID)) {
        return;
    }
    else {
        install(GTAG);
    }
}
// Following code based on this package: github.com/idmadj/ga-gtag/blob/master/src/index.js
const install = (trackingId) => {
    const { head } = document;
    const script = document.createElement('script');
    script.id = SCRIPTID;
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    head.insertBefore(script, head.firstChild);
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', trackingId);
};
const gtag = function () {
    // Can't use arrow func + destructuring as Google expects
    // arguments objects in dataLayer (not an array of arguments).
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
};
