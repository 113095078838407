import { filter } from 'lodash/fp';
import r from '../Replacement';
class PageTitle {
    constructor() {
        this.activeTitle = document.title;
        this.unreadNotificationCount = 0;
        this.getActiveTitle = () => this.activeTitle;
        this.setPageTitle = title => {
            const docTitle = filter(x => x, [title, r('header.companyName')]).join(' - ');
            document.title = this.renderUnreadNotificationCount() + docTitle;
            this.activeTitle = title;
        };
        this.updateUnreadNotificationCount = unreadNotificationCount => {
            this.unreadNotificationCount = unreadNotificationCount;
            this.setPageTitle(this.activeTitle);
        };
        this.renderUnreadNotificationCount = () => (this.unreadNotificationCount ? `(${this.unreadNotificationCount}) ` : '');
    }
}
export const pageTitleService = new PageTitle();
