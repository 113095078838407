import { getDefaultExplanationObjective, getDefaultName, getDefaultTimePeriod, getDefaultTimelineObjective, } from '../../../../../components/CycleCreate/services';
import { EVERYONE_PROCESS } from '../../constants/process';
import { getCurrentUser } from '../../../../../services/CurrentUser';
export function getCreateModel(allUsers) {
    const currentUser = getCurrentUser();
    return {
        allUsers,
        cycleAdmins: [],
        allowedUsers: [],
        name: getDefaultName('objective'),
        explanation: getDefaultExplanationObjective(),
        timePeriod: {
            from: getDefaultTimePeriod().start,
            to: getDefaultTimePeriod().end,
        },
        timeline: getDefaultTimelineObjective(currentUser),
        disabledDates: {
            statusEditableByOwnerUntil: true,
            statusEditableByManagerUntil: true,
        },
        process: EVERYONE_PROCESS,
        options: {
            icon: true,
            keyResults: true,
            status: false, // Completion status
            completionPercentage: false,
            weighting: false,
            categories: {
                enabled: false,
                values: null,
            },
            ratings: {
                enabled: false,
                values: null,
            },
            defaultVisibility: 'protected', // protected | public
            allowEmployeesToChangeVisibility: true,
            allowPrivateObjectives: true,
            allowEmployeesToShareWithSpecificPeople: true,
            sendAutomaticReminders: false,
        },
    };
}
export function getEditModel(model) {
    return Object.assign(Object.assign({}, model), { disabledDates: {} });
}
export function getCopyModel(model) {
    return Object.assign(Object.assign({}, model), { name: `Copy of ${model.name}`, disabledDates: {} });
}
