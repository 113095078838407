import { jsx as _jsx } from "react/jsx-runtime";
import { render } from 'react-dom';
import App from './App';
export default function init() {
    const rootNode = document.getElementById('app-mount');
    renderApp();
    if (module.hot) {
        module.hot.accept('./App', renderApp);
    }
    function renderApp() {
        if (!rootNode) {
            return;
        }
        render(_jsx(App, {}), rootNode);
    }
}
