// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NotificationsPage-page_actions-m6neB {\n  padding: 10px 0;\n  font-size: 13px;\n  text-align: right;\n  --fi-size: 14px;\n}\n.NotificationsPage-list-2yNMG li {\n  border-left: 1px solid #e0dfde;\n  border-right: 1px solid #e0dfde;\n}\n.NotificationsPage-list-2yNMG li:first-child {\n  border-top: 1px solid #e0dfde;\n}\n.NotificationsPage-list-2yNMG li:last-child {\n  border-bottom: 1px solid #e0dfde;\n}\n", ""]);
// Exports
exports.locals = {
	"page_actions": "NotificationsPage-page_actions-m6neB",
	"list": "NotificationsPage-list-2yNMG"
};
module.exports = exports;
