import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import api from '../../../../api';
import PageHeader from '../../../components/PageHeader';
import PageStatic from '../../../components/PageStatic';
import PageTitle from '../../../components/PageTitle';
import { withPageData } from '../../../hoc/withPageData';
import AdminBreadcrumbs from '../components/AdminBreadcrumbs';
import AdminCompanySettings from './presenter';
function AdminCompanySettingsPage({ companySettingsAdvanced, dateTimeSettings }) {
    const title = 'Company Settings';
    return (_jsxs(PageStatic, { children: [_jsx(AdminBreadcrumbs, { page: title }), _jsx(PageTitle, { title: title }), _jsx(PageHeader, { title: title }), _jsx(AdminCompanySettings, { companySettingsAdvanced: companySettingsAdvanced, dateTimeSettings: dateTimeSettings })] }));
}
export default withPageData({
    resolve: {
        companySettingsAdvanced: () => api.companySettingsAdvanced.getCompanySettingsAdvanced(),
    },
    observe: {
        dateTimeSettings: () => api.companyDateTimeSettings.getDateTimeSettings.createObservable(),
    },
})(AdminCompanySettingsPage);
