import { intercomContactExists } from '../../api/custom/Intercom';
import history from '../../routing/history';
import { getCurrentUser } from './CurrentUser';
import { isFeatureEnabled } from './Features';
const INTERCOM_APP_ID = 'qbukee5s';
export const shouldEnableIntercom = async (currentUser = getCurrentUser()) => {
    let contactExists = false;
    if (currentUser.isHR || currentUser.isAdmin) {
        contactExists = await intercomContactExists();
    }
    return isFeatureEnabled('FEATURE_intercom') && contactExists;
};
/**
 * Loads the Intercom script (if it hasn’t been loaded yet) and returns the Intercom instance.
 */
export function loadIntercom() {
    if (window.Intercom) {
        return Promise.resolve(window.Intercom);
    }
    return new Promise((resolve, reject) => {
        (function () {
            const w = window;
            const ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            }
            else {
                const d = document;
                const i = function (...args) {
                    i.c(args);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                // @ts-expect-error
                w.Intercom = i;
                const l = function () {
                    const s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
                    const x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                    s.onload = () => {
                        resolve(w.Intercom);
                    };
                    s.onerror = () => {
                        reject();
                    };
                };
                if (document.readyState === 'complete') {
                    l();
                }
                else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                }
                else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
    });
}
export async function initIntercom(currentUser = getCurrentUser()) {
    if (!window.location.pathname.includes('onboarding-survey') && (await shouldEnableIntercom())) {
        try {
            const shouldHideLauncher = currentUser.company.payment.trialExpired;
            const intercom = await loadIntercom();
            intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: INTERCOM_APP_ID,
                name: currentUser.name,
                email: currentUser.email,
                user_hash: currentUser.intercomSignature,
                created_at: Math.round(currentUser.dateCreated / 1000),
                hide_default_launcher: shouldHideLauncher,
            });
            if (history) {
                history.listen(() => intercom('update'));
            }
        }
        catch (error) {
            // Ignore failed Intercom loading.
        }
    }
}
