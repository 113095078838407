// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Inline-dot-28rbQ {\n  margin-left: 5px;\n  height: 10px;\n  width: 10px;\n  background-color: #f23037;\n  border-radius: 50%;\n  display: inline-flex;\n}\n", ""]);
// Exports
exports.locals = {
	"dot": "Inline-dot-28rbQ"
};
module.exports = exports;
