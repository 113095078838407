import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormItem } from '../../../components/Form/FormItem';
import Link from '../../../components/Link';
import { VALIDATORS } from '../../../services/Validation';
import UserImportConfig from '../UserImport/Configuration';
import { VALIDATION_ERROR } from './constants';
export default function WorkableConfig(props) {
    return (_jsx(UserImportConfig, Object.assign({}, props, { name: "Workable", validationErrorMessage: VALIDATION_ERROR, children: _jsx(GeneralSection, {}) })));
}
function GeneralSection() {
    return (_jsxs("div", { children: [_jsx("h5", { children: "General" }), _jsxs("section", { children: [_jsxs("div", { className: "admin-config-flex-container", children: [_jsx("div", { children: _jsx(FormItem, { name: "systemSpecificProperties.workableSubdomain", label: "Subdomain", validators: [VALIDATORS.required(), VALIDATORS.urlDomain()] }) }), _jsx("div", { children: _jsx(FormItem, { name: "systemSpecificProperties.workablePartnerToken", label: "Partner Token", validators: [VALIDATORS.required()] }) })] }), _jsxs("aside", { children: ["You can find the subdomain associated with your Workable account by visiting the", ' ', _jsx(Link, { openInNewTab: true, href: "https://workable.com/backend/settings/company_profile", children: "company profile settings" }), ' ', "in your Workable account and the partner token you generate by finding the Small Improvements integration under", ' ', _jsx(Link, { openInNewTab: true, href: "https://workable.com/backend/settings/integrations", children: "the integrations settings" }), "."] })] })] }));
}
