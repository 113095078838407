import { difference, forEach, map } from 'lodash/fp';
import { unmountComponentAtNode } from 'react-dom';
import mounts from './mounts';
import render from './render';
const renderAll = forEach(render);
const getIds = map('id');
if (module.hot) {
    let currentIds = getIds(mounts);
    module.hot.accept('./mounts', () => {
        const newIds = getIds(mounts);
        const removedIds = difference(currentIds, newIds);
        currentIds = newIds;
        forEach(id => {
            const node = document.getElementById(id);
            if (node) {
                unmountComponentAtNode(node);
            }
        }, removedIds);
        renderAll(mounts);
    });
}
renderAll(mounts);
