import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { useAsync } from 'react-async-hook';
import RouteError from '../../../routing/RouteError';
import LoadingIndicatorFull from '../../components/LoadingIndicatorFull';
export const withInjector = (ComposedComponent) => {
    const WithInjector = props => {
        const { result: $injector, error } = useAsync(() => import(/* webpackChunkName: 'AngularModule' */ '../../../module').then(module => module.$injector), []);
        if (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return _jsx(RouteError, {});
        }
        if ($injector) {
            return _jsx(ComposedComponent, Object.assign({}, props, { "$injector": $injector }));
        }
        return _jsx(LoadingIndicatorFull, {});
    };
    return WithInjector;
};
