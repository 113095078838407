import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { memo } from 'react';
import { INTEGRATIONS } from '../../../../../routes';
import Breadcrumbs from '../../../../components/Breadcrumbs';
const makeBreadrumbPath = ({ path = [], page }) => {
    return [
        {
            label: 'Integrations',
            url: INTEGRATIONS,
            prependBaseUrl: true,
        },
        ...path.map(p => (Object.assign(Object.assign({}, p), { prependBaseUrl: true }))),
        { label: page },
    ];
};
const IntegrationsBreadcrumbs = props => {
    return _jsx(Breadcrumbs, { path: makeBreadrumbPath(props) });
};
export default memo(IntegrationsBreadcrumbs);
