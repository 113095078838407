import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconUser, IconVideo } from 'featherico';
import * as React from 'react';
import { useRef, useState } from 'react';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import styles from '../styles.m.less';
import GetADemoModalTabs from './GetADemoModalTabs';
export const GetADemoModal = ({ isOpen, setisOpen, userServer }) => {
    const [tab, setTab] = useState(null);
    const containerRef = useRef(null);
    const handleClose = () => {
        setTab(null);
        setisOpen(false);
    };
    return (_jsx(Modal, { isOpen: isOpen, onClose: handleClose, size: "LG_WIDTH", children: _jsxs("div", { ref: containerRef, className: classNames(styles.container, { [styles.expanded]: tab !== null }), children: [_jsxs("div", { className: styles.tabsHeadContainer, children: [userServer === 'prod-eu' ? (_jsx(SelectOptionButton, { onClick: () => setTab('demo'), title: "Request a Demo", description: "Connect with our sales team to receive a personalized demo of the tool", icon: IconUser, color: "COLOR1", tab: tab, eventTracking: { category: 'navigation header', action: 'request demo tab' } })) : (_jsx(SelectOptionButton, { onClick: () => setTab('consultation'), title: "Book a Consultation", description: "Dive into a discussion to more deeply understand your business needs around performance management", icon: IconUser, color: "COLOR1", tab: tab, eventTracking: { category: 'navigation header', action: 'request consultation tab' } })), _jsx(SelectOptionButton, { onClick: () => setTab('tutorial'), title: "Tutorial", description: "Explore and master our features with our step-by-step tutorial.", icon: IconVideo, color: "COLOR2", tab: tab, eventTracking: { category: 'navigation header', action: 'tutorial tab' } })] }), tab && _jsx(GetADemoModalTabs, { tab: tab, close: handleClose })] }) }));
};
const SelectOptionButton = ({ title, description, icon: Icon, onClick, color, tab, eventTracking, }) => {
    return (_jsxs(Button, { onClick: onClick, className: classNames(styles.selectButton, { [styles.contracted]: tab !== null }), eventTracking: eventTracking, children: [_jsx(Icon, { className: classNames(styles.icon, [color === 'COLOR1' ? styles.color1 : styles.color2]) }), _jsx("div", { className: styles.title, children: title }), tab === null && _jsx("div", { className: styles.description, children: description })] }));
};
