import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { result } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import AlertBox from '../../../../components/AlertBox';
import Link from '../../../../components/Link';
SyncedByOtherSystemWarning.propTypes = {
    config: PropTypes.object.isRequired,
};
const SYSTEMS = {
    GOOGLE_APPS: 'Google Workspace',
    CSV: 'CSV',
    BAMBOO: 'BambooHR',
    NAMELY: 'Namely',
    PERSONIO: 'Personio',
};
export default function SyncedByOtherSystemWarning({ config }) {
    const { externalUserSystem, synchronizingSystem } = config;
    const desiredSystemToUse = result(externalUserSystem.toUpperCase(), SYSTEMS);
    const currentSyncSystem = result(synchronizingSystem, SYSTEMS);
    const isWarningVisible = currentSyncSystem && currentSyncSystem !== desiredSystemToUse;
    const defaultWarning = {
        title: 'Automatic employee imports have been disabled',
        body: (_jsxs("p", { children: ["Your Small Improvements users are already being imported automatically from ", currentSyncSystem, ". If you would like to enable automatic imports from ", desiredSystemToUse, ", please disable the automatic imports option for ", currentSyncSystem, " first."] })),
    };
    const useGAppsWithOtherSystemWarning = {
        title: 'Automatic employee imports have been disabled, however, you can still use Google Workspace for single sign-on',
        body: (_jsxs(Fragment, { children: [_jsxs("p", { children: ["The option to run automatic import with ", SYSTEMS.GOOGLE_APPS, " is currently disabled because your employees are already imported automatically from ", currentSyncSystem, ". If you would also like to automatically update employee information with ", SYSTEMS.GOOGLE_APPS, " please disable the automatic imports option for ", currentSyncSystem, " first."] }), _jsxs("p", { children: [currentSyncSystem, " and ", desiredSystemToUse, " can be used in conjunction with one another.", ' ', currentSyncSystem, " will sync employee information for you but you can still setup", ' ', SYSTEMS.GOOGLE_APPS, " for single sign-on login purposes by setting up your service account below and following", ' ', _jsx(Link, { href: "https://intercomdocs.small-improvements.com/en/articles/9151077-google-workspace-integration", openInNewTab: true, children: "the instructions in our documentation." })] })] })),
    };
    const warningToShow = desiredSystemToUse === SYSTEMS.GOOGLE_APPS ? useGAppsWithOtherSystemWarning : defaultWarning;
    return isWarningVisible ? (_jsx(AlertBox, { title: warningToShow.title, colour: "YELLOW", children: warningToShow.body })) : null;
}
