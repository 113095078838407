import { PaymentDocType } from '../../../../../types/PaymentDoc';
import api from '../../../../api';
import { getFieldFromChargebeeError } from './error';
export { estimate } from '../../../../api/custom/Customer'; // Ladda breaks my Promises
export const activateNewBilling = api.company.activateNewBilling;
export const deactivateNewBilling = api.company.deactivateNewBilling;
export const getCustomer = api.customer.getCustomer;
export const getCustomer$ = api.customer.getCustomer.createObservable;
export const addBillingContact = api.customer.addBillingContact;
export const removeBillingContact = api.customer.removeBillingContact;
export const updateAccountDetails = (billingDetails) => api.customer.setBillingDetails(billingDetails).catch((res) => {
    var _a;
    const invalidField = getFieldFromChargebeeError((_a = res.response) === null || _a === void 0 ? void 0 : _a.data.message);
    return Promise.reject({ invalidField });
});
export const getInvoices$ = () => api.customerPaymentDoc.getPaymentDocs.createObservable(PaymentDocType.INVOICE);
export const getCreditNotes$ = () => api.customerPaymentDoc.getPaymentDocs.createObservable(PaymentDocType.CREDIT_NOTE);
export const getInvoiceDownloadLink = (id) => api.customerPaymentDoc.getPaymentDocDownloadLink(PaymentDocType.INVOICE, id);
export const getCreditNoteDownloadLink = (id) => api.customerPaymentDoc.getPaymentDocDownloadLink(PaymentDocType.CREDIT_NOTE, id);
export const endSubscription = api.customer.endSubscription;
export const removeCard = api.customer.removeCard;
