import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import NotificationsTrigger from '../../components/Notifications/NotificationsTrigger/withProvider';
import { useAppContext } from '../../hooks/useAppContext';
import HeaderSearchBar from '../HeaderSearchBar/container';
import BuyButton from './components/BuyButton';
import GetADemoButton from './components/GetADemoButton';
import GlobalCreate from './components/GlobalCreate';
import HelpMenu from './components/HelpMenu';
import Logo from './components/Logo';
import NavigationTrigger from './components/NavigationTrigger';
import UserMenu from './components/UserMenu';
const AppHeader = ({ toggleNavigation }) => {
    const [searchFocused, setSearchFocused] = useState(false);
    const { user } = useAppContext();
    const { companyColor } = user.company.companyDesignSettings;
    return (_jsx("div", { className: classNames('app-header-wrapper', { 'app-header-wrapper--search-focused': searchFocused }), style: { borderColor: companyColor !== null && companyColor !== void 0 ? companyColor : undefined }, children: _jsxs("header", { role: "banner", className: "app-header", children: [_jsx(NavigationTrigger, { toggleNavigation: toggleNavigation }), _jsx(Logo, { user: user }), _jsxs("div", { className: "app-header-actions", children: [!user.isGuest && (_jsxs(_Fragment, { children: [_jsx(GetADemoButton, { user: user }), _jsx(BuyButton, { user: user }), _jsx(GlobalCreate, { user: user }), _jsx(HeaderSearchBar, { onFocus: () => setSearchFocused(true), onBlur: () => setSearchFocused(false) })] })), _jsx(HelpMenu, {}), _jsx(NotificationsTrigger, {}), _jsx(UserMenu, { user: user })] })] }) }));
};
export default AppHeader;
