// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorMessage-container-1132I {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));\n  justify-content: center;\n  align-items: center;\n  grid-gap: 20px;\n  padding: 20px;\n  max-width: 800px;\n  margin: 0 auto;\n}\n.ErrorMessage-container-1132I img {\n  max-width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "ErrorMessage-container-1132I"
};
module.exports = exports;
