import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconChevronDown } from 'featherico';
import * as React from 'react';
import styles from './style.m.less';
export default function SelectSimple(_a) {
    var { isSmall, className, hasError, children, options, placeholder, value } = _a, props = __rest(_a, ["isSmall", "className", "hasError", "children", "options", "placeholder", "value"]);
    return (_jsxs("div", { className: classNames(styles.container, className, {
            [styles.isSmall]: isSmall,
        }), children: [_jsx("select", Object.assign({ className: classNames(styles.select, {
                    [styles.hasError]: hasError,
                }), value: !value && placeholder !== undefined ? 'placeholder' : value }, props, { children: options ? (_jsxs(_Fragment, { children: [placeholder !== undefined && (_jsx("option", { disabled: true, value: "placeholder", children: placeholder })), options.map(({ label, value: optionValue }) => (_jsx("option", { value: optionValue, children: label }, optionValue)))] })) : (children) })), _jsx(IconChevronDown, { className: styles.icon })] }));
}
