import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
export default function TextArea(_a) {
    var { className, textareaClassName, style, value, noresize = false, error } = _a, otherProps = __rest(_a, ["className", "textareaClassName", "style", "value", "noresize", "error"]);
    const inputProps = Object.assign(Object.assign({}, otherProps), { className: classNames(textareaClassName, { no_resize: !!noresize }), value: value || '' });
    const wrapperProps = {
        style,
        className: classNames(className, 'react-text_area', {
            'react-text_area-error': !!error,
        }),
    };
    return (_jsxs("div", Object.assign({}, wrapperProps, { children: [_jsx("textarea", Object.assign({}, inputProps)), !!error && _jsx("div", { className: "react-text_area-error_box", children: error })] })));
}
TextArea.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    textareaClassName: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    noresize: PropTypes.bool,
};
