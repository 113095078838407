import { createBrowserHistory } from 'history';
import { startsWith } from 'lodash/fp';
const history = isSinglePageApp()
    ? createBrowserHistory({
        basename: '/app',
        getUserConfirmation: getConfirmation,
    })
    : undefined;
export default history;
const config = {
    getConfirmation: defaultConfirmation,
};
function defaultConfirmation(message, callback) {
    callback(window.confirm(message)); // eslint-disable-line no-alert
}
function getConfirmation(message, callback) {
    config.getConfirmation(message, callback);
}
export function registerConfirmationHandler(handler) {
    config.getConfirmation = handler;
    return function deregisterConfirmation() {
        config.getConfirmation = defaultConfirmation;
    };
}
let pageReloadScheduled = false;
export function schedulePageReload() {
    pageReloadScheduled = true;
}
export function isPageReloadScheduled() {
    return pageReloadScheduled;
}
export function isSinglePageApp() {
    const { pathname } = window.location;
    return startsWith('/app/', pathname) || pathname === '/app';
}
