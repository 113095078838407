// For historical reasons the key scheme is: [COMPANY].[USER_ID].[STORAGE_IDENTIFIER]
export const getFullKey = (originalKey) => `small-improvements.*.${originalKey}`;
export const replaceObjectInStorage = (key, object) => {
    window.localStorage.setItem(getFullKey(key), JSON.stringify(object));
};
export function getObjectFromStorage(key, fallback = {}) {
    const item = window.localStorage.getItem(getFullKey(key)) || '';
    try {
        return JSON.parse(item);
    }
    catch (e) {
        return fallback;
    }
}
export const updateObjectInStorage = (key, object) => {
    replaceObjectInStorage(key, Object.assign(Object.assign({}, getObjectFromStorage(key)), object));
};
export const removeObjectInStorage = (key) => {
    window.localStorage.removeItem(getFullKey(key));
};
