import { extend, keys } from 'lodash';
import moment from 'moment-timezone';
import { convertTimestampInTimeZone } from '../../../../../common/services/date-helper/service';
import { getCurrentUser } from '../../../../services/CurrentUser';
const DAY_OPERATIONS = {
    ADD: 'add',
    SUBTRACT: 'subtract',
};
const DAYS_TO_ADD_FEEDBACK = {
    inviteReviewersBy: 6,
    approveReviewersFrom: 8,
    approveReviewersBy: 13,
    writeFeedbackBy: 20,
    managerRelease: 22,
    revieweeReleaseDate: 25,
    processEnds: 27,
};
const DAYS_TO_ADD_REVIEW = {
    revieweeWriteUntil: 6,
    managerWriteUntil: 8,
    managerRatingsLockBy: 8,
    processEndsForReviewees: 13,
    processEndsForReviewers: 14,
    managerShareFrom: 28,
    discussAndShareBy: 34,
    managerShareUntil: 40,
    signBy: 40,
};
const DAYS_TO_ADD_OBJECTIVE = {
    editableByManagerFrom: 0,
    editableByOwnerUntil: 60,
    statusEditableByOwnerUntil: 80,
    editableByManagerUntil: 60,
    statusEditableByManagerUntil: 80,
    defaultDueDate: 85,
};
function getDefaultTimelineFeedback(currentUser, baseDate = undefined) {
    currentUser = currentUser ? currentUser : getCurrentUser();
    const fn = getDefaultCycleDateFn(currentUser, baseDate);
    const baseDateOffset = baseDate ? 0 : 1;
    const processStarts = fn(baseDateOffset, DAY_OPERATIONS.SUBTRACT);
    const inviteReviewersBy = fn(DAYS_TO_ADD_FEEDBACK.inviteReviewersBy, DAY_OPERATIONS.ADD);
    const approveReviewersFrom = fn(DAYS_TO_ADD_FEEDBACK.approveReviewersFrom, DAY_OPERATIONS.ADD);
    const approveReviewersBy = fn(DAYS_TO_ADD_FEEDBACK.approveReviewersBy, DAY_OPERATIONS.ADD);
    const writeFeedbackBy = fn(DAYS_TO_ADD_FEEDBACK.writeFeedbackBy, DAY_OPERATIONS.ADD);
    const managerRelease = fn(DAYS_TO_ADD_FEEDBACK.managerRelease, DAY_OPERATIONS.ADD);
    const revieweeReleaseDate = fn(DAYS_TO_ADD_FEEDBACK.revieweeReleaseDate, DAY_OPERATIONS.ADD);
    const processEnds = fn(DAYS_TO_ADD_FEEDBACK.processEnds, DAY_OPERATIONS.ADD);
    return {
        processStarts,
        inviteReviewersBy,
        approveReviewersFrom,
        approveReviewersBy,
        writeFeedbackBy,
        managerRelease,
        revieweeReleaseDate,
        processEnds,
    };
}
function getDefaultTimelineReview(currentUser, baseDate, timeline = {}) {
    const fn = getDefaultCycleDateFn(currentUser, baseDate);
    const processStarts = fn(0, DAY_OPERATIONS.SUBTRACT);
    const revieweeWriteUntil = fn(DAYS_TO_ADD_REVIEW.revieweeWriteUntil, DAY_OPERATIONS.ADD);
    const managerWriteUntil = fn(DAYS_TO_ADD_REVIEW.managerWriteUntil, DAY_OPERATIONS.ADD);
    const managerRatingsLockBy = timeline.managerRatingsLockBy
        ? fn(DAYS_TO_ADD_REVIEW.managerRatingsLockBy, DAY_OPERATIONS.ADD)
        : null;
    const managerShareFrom = fn(DAYS_TO_ADD_REVIEW.managerShareFrom, DAY_OPERATIONS.ADD);
    const discussAndShareBy = fn(DAYS_TO_ADD_REVIEW.discussAndShareBy, DAY_OPERATIONS.ADD);
    const managerShareUntil = fn(DAYS_TO_ADD_REVIEW.managerShareUntil, DAY_OPERATIONS.ADD);
    const signBy = fn(DAYS_TO_ADD_REVIEW.signBy, DAY_OPERATIONS.ADD);
    const processEndsForReviewees = fn(DAYS_TO_ADD_REVIEW.processEndsForReviewees, DAY_OPERATIONS.ADD);
    const processEndsForReviewers = fn(DAYS_TO_ADD_REVIEW.processEndsForReviewers, DAY_OPERATIONS.ADD);
    return {
        processStarts,
        revieweeWriteUntil,
        managerWriteUntil,
        managerRatingsLockBy,
        managerShareFrom,
        discussAndShareBy,
        managerShareUntil,
        signBy,
        processEndsForReviewees,
        processEndsForReviewers,
    };
}
function getDefaultTimelineObjective(currentUser, baseDate = undefined) {
    const fn = getDefaultCycleDateFn(currentUser, baseDate);
    const baseDateOffset = baseDate ? 0 : 1;
    const editableByOwnerFrom = fn(baseDateOffset, DAY_OPERATIONS.SUBTRACT);
    const editableByManagerFrom = fn(baseDateOffset, DAY_OPERATIONS.SUBTRACT);
    const editableByOwnerUntil = fn(DAYS_TO_ADD_OBJECTIVE.editableByOwnerUntil, DAY_OPERATIONS.ADD);
    const statusEditableByOwnerUntil = fn(DAYS_TO_ADD_OBJECTIVE.statusEditableByOwnerUntil, DAY_OPERATIONS.ADD);
    const editableByManagerUntil = fn(DAYS_TO_ADD_OBJECTIVE.editableByManagerUntil, DAY_OPERATIONS.ADD);
    const statusEditableByManagerUntil = fn(DAYS_TO_ADD_OBJECTIVE.statusEditableByManagerUntil, DAY_OPERATIONS.ADD);
    const defaultDueDate = fn(DAYS_TO_ADD_OBJECTIVE.defaultDueDate, DAY_OPERATIONS.ADD);
    return {
        editableByOwnerFrom,
        editableByOwnerUntil,
        statusEditableByOwnerUntil,
        editableByManagerFrom,
        editableByManagerUntil,
        statusEditableByManagerUntil,
        defaultDueDate,
    };
}
const getDefaultCycleHours = (companySettings, baseDate) => {
    return moment.tz(baseDate, companySettings.timeZone).hours();
};
function getDefaultCycleDateFn(currentUser, baseDate) {
    return function (days, operation) {
        const { companySettings } = currentUser.company;
        return getCycleDate(companySettings, baseDate, operation, days, 'days', getDefaultCycleHours(companySettings, baseDate));
    };
}
const computeTimelineReview = (state, newTimeline, mode) => computeTimeline(state, newTimeline, getCurrentUser(), mode, getDefaultTimelineReview, 'processStarts');
const computeTimelineFeedback = (state, newTimeline, mode) => computeTimeline(state, newTimeline, getCurrentUser(), mode, getDefaultTimelineFeedback, 'processStarts');
const computeTimelineObjective = (state, newTimeline, mode) => computeTimeline(state, newTimeline, getCurrentUser(), mode, getDefaultTimelineObjective, 'editableByOwnerFrom');
function computeTimeline(state, newTimeline, currentUser, mode, defaultTimelineFunction, baseDate) {
    let newState, timeline;
    if (isChangeWholeTimeline(mode, state, newTimeline, baseDate)) {
        timeline = defaultTimelineFunction(currentUser, newTimeline[baseDate], state.model.timeline);
        newState = state;
    }
    else {
        timeline = extend({}, state.model.timeline, newTimeline);
        const isChangingDate = !!Object.keys(newTimeline).find(key => !!state.model.timeline[key]);
        if (isChangingDate) {
            newState = Object.assign(Object.assign({}, state), { timelineChangedOnce: true });
        }
        else {
            newState = state;
        }
    }
    return { timeline, newState };
}
function computeTimelineForNominationProcessChange(state, mode, nomination) {
    if (mode !== 'create') {
        return { timeline: state.model.timeline, newState: state };
    }
    if (nomination === 'MANAGER_NOMINATION') {
        const processStart = state.model.timeline.processStarts;
        const newTimeline = { approveReviewersFrom: processStart };
        const timeline = extend({}, state.model.timeline, newTimeline);
        const newState = Object.assign(Object.assign({}, state), { timelineChangedOnce: true });
        return { timeline, newState };
    }
    else {
        const timeline = extend({}, state.model.timeline, getDefaultTimelineFeedback());
        return { timeline, newState: state };
    }
}
function isChangeWholeTimeline(mode, state, newTimeline, baseDate) {
    const { timelineChangedOnce, model } = state;
    const isEditMode = mode !== 'edit';
    return isEditMode && !timelineChangedOnce && changesOnlyOnBaseDate(newTimeline, model.timeline, baseDate);
}
function changesOnlyOnBaseDate(newTimeline, oldTimeline, baseDate) {
    const date = newTimeline[baseDate];
    return keys(newTimeline).length === 1 && date && date !== oldTimeline[baseDate];
}
function getCycleDate(companySettings, baseDate, operation, offset, unit, hours) {
    const timestamp = moment
        .tz(baseDate, companySettings.timeZone)[operation](offset, unit)
        .hours(hours)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);
    return convertTimestampInTimeZone(timestamp, companySettings.timeZone).valueOf();
}
const addGracePeriod = (date, offsetDays, companySettings) => moment.tz(date, companySettings.timeZone).add(offsetDays, 'days').valueOf();
const getGracePeriod = (gracePeriod, timestamp) => {
    if (!gracePeriod) {
        return null;
    }
    // Use a precise difference (via 3rd parameter) for edge cases like DST transitions.
    const offsetDaysPrecise = moment(gracePeriod.timestamp.value).diff(timestamp.value, 'days', true);
    return Math.round(offsetDaysPrecise);
};
export { addGracePeriod, computeTimeline, computeTimelineFeedback, computeTimelineForNominationProcessChange, computeTimelineObjective, computeTimelineReview, getCycleDate, getDefaultTimelineFeedback, getDefaultTimelineObjective, getDefaultTimelineReview, getGracePeriod, };
