import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
const propTypes = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.any,
    onChange: PropTypes.func,
};
export const RadioButtonContainerPlain = (_a) => {
    var { className = undefined } = _a, props = __rest(_a, ["className"]);
    return (_jsx("div", Object.assign({ className: classNames(className, 'styled-radio-inputs') }, props)));
};
export default class RadioButtonContainer extends Component {
    getChildContext() {
        const { name, selected, onChange = () => { } } = this.props;
        return { radioButtonContainer: { name, selected, onChange } };
    }
    render() {
        const newProps = omit(this.props, ['name', 'selected', 'onChange']);
        return _jsx(RadioButtonContainerPlain, Object.assign({}, newProps));
    }
}
RadioButtonContainer.propTypes = propTypes;
RadioButtonContainer.childContextTypes = {
    radioButtonContainer: PropTypes.object,
};
