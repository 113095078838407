// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AnytimeFeedbackTypeSelect-container-3v0sw {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 20px;\n  padding: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "AnytimeFeedbackTypeSelect-container-3v0sw"
};
module.exports = exports;
