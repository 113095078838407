import { jsx as _jsx } from "react/jsx-runtime";
import c from 'classnames';
import { Component, createRef } from 'react';
import { scrollTo } from '../../../../services/Scroll';
import Button from '../../../Button';
import styles from '../style.m.less';
class Selector extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
        // See: https://github.com/react-component/time-picker/blob/a3d956df594ad81525321bbeffe247341034a46f/src/Select.jsx
        this.scrollToSelected = (duration) => {
            const { selectedIndex } = this.props;
            const list = this.ref.current;
            if (selectedIndex === null || !list) {
                return;
            }
            let index = selectedIndex;
            if (index < 0) {
                index = 0;
            }
            const topOption = list.children[index];
            if (topOption) {
                const to = topOption.offsetTop;
                scrollTo(list, to, duration);
            }
        };
        this.ref = createRef();
    }
    componentDidMount() {
        if (this.props.scrollToSelected) {
            this.scrollToSelected(0);
        }
    }
    componentDidUpdate(prevProps) {
        const { selectedIndex, scrollToSelected } = this.props;
        if (prevProps.selectedIndex !== selectedIndex && scrollToSelected) {
            this.scrollToSelected(200);
        }
    }
    render() {
        const { options, selectedIndex, onSelect } = this.props;
        return (_jsx("div", { className: styles.selector, ref: this.ref, children: options.map((opt, idx) => (_jsx(Button, { kind: "TRANSPARENT", onClick: () => onSelect(opt), className: c(styles.item, {
                    [styles.selectedItem]: selectedIndex === idx,
                }), type: "button", tabIndex: -1, children: opt }, opt.toString()))) }));
    }
}
Selector.defaultProps = {
    scrollToSelected: true,
};
export default Selector;
