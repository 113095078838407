import { concat, flatten, isEmpty, some, values } from 'lodash';
import moment from 'moment';
const PRIVATE = 'PRIVATE';
const SHARED = 'SHARED';
const flat = (objectOfArrays) => flatten(values(objectOfArrays));
export function isParticipant(meeting, user) {
    return some(meeting.participants, p => p.id === user.id);
}
export function getOtherParticipant(meeting, executingUser) {
    const { participants } = meeting;
    return participants.find(p => p.id !== executingUser.id);
}
export function isValid(meeting) {
    return (meeting.participants || []).length === 2;
}
export function getPrivateNotes({ notes = [] }) {
    return notes.filter(c => c.visibility === PRIVATE);
}
export function getSharedNotes({ notes = [] }) {
    return notes.filter(c => c.visibility === SHARED);
}
export function hasContent(meeting) {
    return !isEmpty(meeting) && (!isEmpty(meeting.talkingPoints) || !isEmpty(meeting.notes));
}
export function hasPrivateContent(meeting) {
    const { notes = [], talkingPoints = {} } = meeting;
    const content = concat(notes, flat(talkingPoints));
    return some(content, c => c.visibility === PRIVATE);
}
export function hasNotes(meeting) {
    return !isEmpty(meeting) && !isEmpty(meeting.notes);
}
export function hasTalkingPoints(meeting) {
    return !isEmpty(meeting) && !isEmpty(meeting.talkingPoints);
}
export function getMeetingResourceId(participant1, participant2) {
    return `${participant1.id}${participant2.id}`;
}
export function isHidePrivate(hidePrivate, content) {
    return hidePrivate && isPrivate(content);
}
export function isPrivate(content) {
    return content.visibility === PRIVATE;
}
export function isHidden(content) {
    return isPrivate(content) || content.draft;
}
export function getMeetingTitle(meeting, currentUser) {
    const dateFormat = currentUser.company.companySettings.dateFormats.shortDateWithWeekday;
    const dateLabel = moment(meeting.calendarDate).format(dateFormat);
    return `${dateLabel} - ${isEmpty(meeting.title) ? '1:1 Meeting' : meeting.title}`;
}
/**
 * @returns {Moment} The current date with the time (hours and minutes) from the supplied meeting
 * If no meeting is supplied (or it doesn't have a date), the time 10:00 is used as
 * a default (usually within office hours).
 */
export function getMeetingDateOrDefault(meeting) {
    let hour = 10;
    let minute = 0;
    if (meeting && meeting.date) {
        const meetingDate = moment(meeting.date);
        hour = meetingDate.get('hours');
        minute = meetingDate.get('minutes');
    }
    return moment().hours(hour).minutes(minute).seconds(0).milliseconds(0);
}
export function toSpaceHash(participants) {
    const userIds = participants.map(p => (typeof p === 'object' ? p.id : p));
    userIds.sort();
    return userIds.join('');
}
