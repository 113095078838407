export const EMAIL_TEMPLATE_TYPES = [
    'FEEDBACK_REQUEST_INSTRUCTION_REVIEWEE',
    'FEEDBACK_REQUEST_NUDGE_REVIEWEE',
    'NOTIFY_ADMIN_TO_REVIEW_FEEDBACK_CYCLE_AFTER_REVIEWER_SELECTION_DEADLINE_PASSED',
    'FEEDBACK_REQUEST_INFORM_MANAGER_TO_NOMINATE',
    'FEEDBACK_REQUEST_APPROVEABLE_REVIEWS_NOTIFICATION',
    'FEEDBACK_REQUEST_NOTIFY_MANAGER_ABOUT_NOMINATIONS',
    'FEEDBACK_REQUEST_APPROVEABLE_REVIEWS_NUDGE',
    'FEEDBACK_REQUEST_NUDGE_MANAGER',
    'FEEDBACK_REQUEST_OUTSTANDING_REVIEWS_NOTIFICATION',
    'FEEDBACK_REQUEST_OUTSTANDING_REVIEWS_NUDGE',
    'NUDGE_EMPLOYEE_TO_PROVIDE_SINGLE_FEEDBACK',
    'FEEDBACK_REQUEST_PARTICIPANT_DECLINED__REVIEWEE',
    'FEEDBACK_REQUEST_PARTICIPANT_DECLINED',
    'FEEDBACK_REQUEST_NOTIFICATION_ABOUT_RELEASED_FEEDBACK_MANAGER',
    'FEEDBACK_REQUEST_NOTIFICATION_ABOUT_RELEASED_FEED_BACK',
];
