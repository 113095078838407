import { IconAlignLeft, IconBullhorn, IconEdit, IconMail, IconModulePraise, IconStar } from 'featherico';
import { keyBy } from 'lodash/fp';
import replacement from '../../../react/services/Replacement';
export const getProjects = () => [
    {
        projectType: 'PRAISE_AND_KUDOS',
        projectName: replacement('praise.menu.title'),
        entityName: replacement('praise.singular'),
        icon: 'module-praise',
        iconComponent: IconModulePraise,
        title: replacement('praise.cta'),
        getTitlePlaceholder: (name = 'Mary') => `${name}, your recent work on the XYZ project helped us...`,
        createButtonTitle: replacement('praise.cta'),
    },
    {
        projectType: 'IDEAS',
        icon: 'star',
        iconComponent: IconStar,
        projectName: replacement('messages.ideas.project'),
        entityName: replacement('messages.ideas.name'),
        title: replacement('messages.ideas.button'),
        getTitlePlaceholder: () => 'I think we could improve our sales process by...',
        createButtonTitle: replacement('messages.ideas.post'),
    },
    {
        projectType: 'ANNOUNCEMENTS',
        icon: 'bullhorn',
        iconComponent: IconBullhorn,
        projectName: replacement('messages.a.project'),
        entityName: replacement('messages.a.name'),
        title: replacement('messages.a.button'),
        getTitlePlaceholder: () => 'Finally! We managed to wrap up the XYZ job, and will now...',
        createButtonTitle: replacement('messages.a.post'),
    },
    {
        projectType: 'PRIVATE_NOTES',
        icon: 'edit',
        iconComponent: IconEdit,
        projectName: replacement('messages.pn.project'),
        entityName: replacement('messages.pn.name'),
        title: replacement('messages.pn.button'),
        getTitlePlaceholder: (name = 'Mary') => `Met with ${name} today, spoke about...`,
        createButtonTitle: replacement('messages.pn.post'),
    },
    {
        projectType: 'PEER_FEEDBACK',
        icon: 'align-left',
        iconComponent: IconAlignLeft,
        projectName: replacement('messages.feedback.project'),
        entityName: replacement('messages.feedback.name'),
        title: replacement('messages.feedback.button'),
        getTitlePlaceholder: (name = 'Mary') => `${name}, I think you could improve your work by...`,
        createButtonTitle: replacement('messages.feedback.post'),
    },
    {
        projectType: 'OTHER',
        icon: 'mail',
        iconComponent: IconMail,
        projectName: replacement('messages.other.project'),
        entityName: replacement('messages.other.name'),
        title: replacement('messages.other.button'),
        getTitlePlaceholder: () => '',
        createButtonTitle: replacement('messages.other.post'),
    },
];
export const getActiveMessageProjects = (user) => {
    const companyProjects = keyBy('projectType', user.company.allProjects);
    return getProjects()
        .filter(p => { var _a; return p.projectType !== 'PRAISE_AND_KUDOS' && ((_a = companyProjects[p.projectType]) === null || _a === void 0 ? void 0 : _a.active); })
        .map(p => (Object.assign(Object.assign({}, p), companyProjects[p.projectType])));
};
