"use strict";
// this is the new password-strength-check
window.zxcvbn_load_hook = function () {
    let current = '', last = '', hint = '', details = ({ dictionary, leet, userinputs }) => {
        let dictionaryPart = dictionary
            ? `Using regular words is okay, but you should combine more words then.`
            : '';
        let leetPart = leet
            ? `It's generally a good idea to use numbers as a replacement for letters, but try to use less obvious ones.`
            : '';
        let userInputsPart = userinputs
            ? `Also, never use your name, email or the name of this website in your password.`
            : '';
        return `Tip: Longer passwords are more secure. Use different passwords for each application. Let your browser or a password manager remember them for you. ${dictionaryPart} ${leetPart} ${userInputsPart}`;
    }, score, results, pwField = document.querySelector('input[type="password"].checkStrength'), pwContainer = pwField.parentElement, pwHint = pwContainer.querySelector('.inputHint'), pwHintText = pwHint.querySelector('.inputHintText'), pwHintIcon = pwHint.querySelector('.inputHintIcon');
    pwHintIcon.setAttribute('data-tooltip-options', 'right');
    pwField.addEventListener('keyup', function () {
        var _a, _b, _c;
        current = pwField.value;
        pwHint.classList.toggle('hidden', !current);
        if (current !== last) {
            const inputs = [
                'small',
                'improvements',
                'improve',
                ((_a = document.querySelector('.companyDomain')) === null || _a === void 0 ? void 0 : _a.textContent) || '',
                ((_b = document.querySelector('input#name')) === null || _b === void 0 ? void 0 : _b.value) || '',
                ((_c = document.querySelector('input#lastName')) === null || _c === void 0 ? void 0 : _c.value) || '',
                window.location.pathname.split('/')[5] || '',
            ];
            last = current;
            results = window.zxcvbn(current, inputs);
            if (score !== results.score) {
                score = results.score;
                switch (score) {
                    case 0:
                        hint = 'Very weak';
                        break;
                    case 1:
                        hint = 'Weak';
                        break;
                    case 2:
                        hint = 'So-so';
                        break;
                    case 3:
                        hint = 'Good';
                        break;
                    case 4:
                        hint = 'Great!';
                        break;
                }
                pwHintText.textContent = hint;
                pwHint.setAttribute('data-score', score);
            }
            if (current && score !== 3 && score !== 4) {
                const newDetails = details({
                    leet: _.find(results.match_sequence, { l33t: true }),
                    userinputs: _.find(results.match_sequence, { dictionary_name: 'user_inputs' }),
                    dictionary: _.find(results.match_sequence, { pattern: 'dictionary' }),
                });
                pwHintIcon.setAttribute('data-tooltip', newDetails);
            }
            else {
                pwHintIcon.setAttribute('data-tooltip', '');
            }
        }
    });
};
