// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Badge-badge-3-c84 {\n  display: inline-block;\n  background-position: center;\n  background-size: cover;\n  line-height: 0;\n}\n.Badge-badgeImage-xNVjc {\n  display: block;\n  width: 100%;\n  height: auto;\n}\n.Badge-customBadgeImage-36iBJ {\n  display: block;\n  width: 100%;\n  height: auto;\n  border-radius: 50%;\n}\n", ""]);
// Exports
exports.locals = {
	"badge": "Badge-badge-3-c84",
	"badgeImage": "Badge-badgeImage-xNVjc",
	"customBadgeImage": "Badge-customBadgeImage-36iBJ"
};
module.exports = exports;
