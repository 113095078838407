import { jsx as _jsx } from "react/jsx-runtime";
import { createElement } from 'react';
import { Route } from 'react-router';
import AngularRouteFetcher from '../../../routing/AngularRouteFetcher';
import { childRoutes } from '../../../routing/angularRoutes';
export function getAngularChildRoutes(routes) {
    return routes.map(route => createElement(Route, {
        exact: true,
        path: route,
        key: route,
        render: ({ match }) => _jsx(AngularRouteFetcher, { match: match, stateGetter: childRoutes[route] }),
    }));
}
