import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { compose, isEmpty, join, map, toPairs } from 'lodash/fp';
import PropTypes from 'prop-types';
import AlertBox from '../../../components/AlertBox';
import PageStaticSection from '../../../components/PageStaticSection';
import { get, getWithCount } from '../../../services/Pluralize';
import { enumerateRoles, isSuperUser } from '../../../services/User';
import AdminLink from './AdminLink';
import styles from './style.m.less';
AdminOverview.propTypes = {
    categories: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};
export default function AdminOverview({ currentUser, categories, errorCount }) {
    const hasDisabledLinks = Object.values(categories).some(category => category.some(link => !link.enabled));
    return (_jsxs(_Fragment, { children: [_jsxs(PageStaticSection, { children: [errorCount > 0 && (_jsx(AlertBox, { colour: "RED", children: _jsx(ErrorSummary, { count: errorCount }) })), isSuperUser(currentUser) ||
                        (hasDisabledLinks && (_jsx(AlertBox, { colour: "YELLOW", children: _jsx(RoleWarning, { user: currentUser }) })))] }), compose(map(([title, links]) => _jsx(AdminOverviewCategory, { links: links, title: title }, title)), toPairs)(categories)] }));
}
function AdminOverviewCategory({ title, links }) {
    return (_jsxs(PageStaticSection, { className: styles.section, children: [_jsx("h1", { className: classNames('page_static-section-heading', `apc-admin-overview-${title.replace(/ /g, '')}`), children: title }), _jsx("div", { className: styles.gridContainer, children: map(link => (_createElement(AdminLink, Object.assign({}, link, { key: link.id, target: link.category === 'Promotions & Reviews' ? '_blank' : '' }))), links) })] }));
}
function ErrorSummary({ count }) {
    return (_jsxs("p", { children: ["There ", get('is', count, 'are'), " ", getWithCount('section', count), " with errors on this Administration page. Please review all highlighted sections below in order to avoid unexpected issues."] }));
}
function RoleWarning({ user }) {
    const roles = compose(join(', '), map(r => r.name), enumerateRoles)(user);
    return (_jsxs("p", { children: [_jsx("strong", { children: "Please note:" }), "\u00A0Due to your user permissions", !isEmpty(roles) && ` (${roles})`, " you cannot access all options.", ' ', _jsx("a", { href: "https://intercomdocs.small-improvements.com/en/articles/9146183-hr-admins-tech-admins-and-hr-assistants#h_7859d735ca", children: "Learn more about SI roles and permissions" }), "."] }));
}
