export const ASSESSMENT_TYPES = {
    SELF_ASSESSMENT: 'SELF_ASSESSMENT',
    ASSESSMENT: 'ASSESSMENT',
    CONTRIBUTOR_ASSESSMENT: 'CONTRIBUTOR_ASSESSMENT',
};
export const QUESTION_TYPES = {
    GRAPH_RATING: 'GRAPH_RATING',
    RATING: 'RATING', // overall rating
    SECONDARY_RATING: 'SECONDARY_RATING', // overall rating
    TEXT: 'TEXT',
    TEXT_WITH_RATING: 'TEXT_WITH_RATING',
    SCALES: 'SCALES',
    HEADING: 'HEADING',
    SLIDER: 'SLIDER',
    CHECKBOXES: 'CHECKBOXES',
    DROPDOWN: 'DROPDOWN',
};
export const ASSESSMENT_STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    STARTED: 'STARTED',
    WRITTEN: 'WRITTEN',
    SHARED: 'SHARED',
    SIGNED: 'SIGNED',
};
export const REVIEW_STATUS = {
    NOT_STARTED: 'Not started',
    STARTED: 'Started',
    WRITTEN: 'Written',
    SHARED: 'Shared',
    SIGNED: 'Signed',
};
export const ACTION_TYPES = {
    SHARING: 'SHARING',
    SIGNING: 'SIGNING',
    HIDING: 'HIDING',
};
export const REASONS_NOT_SIGNABLE = {
    ALREADY_SIGNED: 'ALREADY_SIGNED',
    ASSESSMENT_NOT_SHARED: 'ASSESSMENT_NOT_SHARED',
    SELF_ASSESSMENT_NOT_SHARED: 'SELF_ASSESSMENT_NOT_SHARED',
    INDIRECT_MANAGERS_CANNOT_SIGN: 'INDIRECT_MANAGERS_CANNOT_SIGN',
    EXCEEDED_CHARACTER_LIMIT: 'EXCEEDED_CHARACTER_LIMIT',
};
export const REASONS_NOT_SHAREABLE = {
    INDIRECT_MANAGERS_CANNOT_SHARE: 'INDIRECT_MANAGERS_CANNOT_SHARE',
    BEFORE_SHARE_FROM_DATE: 'BEFORE_SHARE_FROM_DATE',
    NOT_MAIN_REVIEWER_AND_NO_SPECIAL_PERMISSION: 'NOT_MAIN_REVIEWER_AND_NO_SPECIAL_PERMISSION',
    EXCEEDED_CHARACTER_LIMIT: 'EXCEEDED_CHARACTER_LIMIT',
};
export const anonymousUser = {
    id: 'anonymous--id',
    name: 'Anonymous',
    firstName: 'Anonymous',
    lastName: '',
    logo: '/images/characters/avatar-anonymous.svg',
    gender: 'unspecified',
    isActive: true,
    isPlaceholder: true,
    managerId: null,
};
