// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ToggleNavigation-list-3zTGH {\n  display: flex;\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  position: relative;\n  z-index: 0;\n}\n.ToggleNavigation-listItem-261qu {\n  margin: 0;\n}\n.ToggleNavigation-listItem-261qu + .ToggleNavigation-listItem-261qu {\n  margin-left: -1px;\n}\n.ToggleNavigation-listItem-261qu:first-child .ToggleNavigation-item-1xP9q {\n  border-radius: 4px 0 0 4px;\n}\n.ToggleNavigation-listItem-261qu:last-child .ToggleNavigation-item-1xP9q {\n  border-radius: 0 4px 4px 0;\n}\n.ToggleNavigation-listItem-261qu .ToggleNavigation-item-1xP9q:active,\n.ToggleNavigation-listItem-261qu .ToggleNavigation-item-1xP9q:focus {\n  border-radius: 4px;\n}\n.ToggleNavigation-item-1xP9q {\n  display: block;\n  height: 28px;\n  padding: 5px 8px;\n  line-height: 18px;\n  border: 1px solid #a3a2a1;\n  background-color: #ffffff;\n  color: #1a1a1a;\n  position: relative;\n}\n.ToggleNavigation-item-1xP9q,\n.ToggleNavigation-item-1xP9q:hover,\n.ToggleNavigation-item-1xP9q:focus,\n.ToggleNavigation-item-1xP9q:active {\n  text-decoration: none;\n}\n.ToggleNavigation-item-1xP9q:hover {\n  background: #edeceb;\n}\n.ToggleNavigation-item-1xP9q:active,\n.ToggleNavigation-item-1xP9q:focus {\n  z-index: 2;\n  outline: none;\n  border-color: #0071d4;\n  box-shadow: 0 0 0 1px #0071d4;\n}\n.ToggleNavigation-itemActive-3H8qv {\n  cursor: default;\n  color: #ffffff;\n  z-index: 1;\n}\n.ToggleNavigation-itemActive-3H8qv,\n.ToggleNavigation-itemActive-3H8qv:hover {\n  background: #0071d4;\n}\n.ToggleNavigation-itemActive-3H8qv:active,\n.ToggleNavigation-itemActive-3H8qv:focus {\n  border-color: #0f4e96;\n  box-shadow: 0 0 0 1px #0f4e96;\n}\n", ""]);
// Exports
exports.locals = {
	"list": "ToggleNavigation-list-3zTGH",
	"listItem": "ToggleNavigation-listItem-261qu",
	"item": "ToggleNavigation-item-1xP9q",
	"itemActive": "ToggleNavigation-itemActive-3H8qv ToggleNavigation-item-1xP9q"
};
module.exports = exports;
