export default function confirmIdentity(continueTo = getCurrentUrl()) {
    window.location.replace(getConfirmIdentityURL(continueTo));
}
export function getConfirmIdentityURL(continueTo = getCurrentUrl()) {
    return '/confirm-identity?continue=' + encodeURIComponent(continueTo);
}
function getCurrentUrl() {
    const { pathname, search, hash } = window.location;
    return pathname + search + hash;
}
