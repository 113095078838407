import { debounce } from 'lodash';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createQuery, SEARCH, searchWithActions } from '../../../services/HeaderSearchBar';
import { redirect } from '../../../services/Router';
import { actionCreators } from '../ducks';
import SearchInput from './presenter';
function mapStateToProps(state) {
    return {
        query: state.headerSearchBar.query,
        showResults: state.headerSearchBar.showResults,
        inputVisible: state.headerSearchBar.inputVisible,
        status: state.headerSearchBar.status,
        mode: state.headerSearchBar.mode,
        selectedItem: state.headerSearchBar.selectedItem,
        items: state.headerSearchBar.result,
    };
}
function mapDispatchToProps(dispatch, props) {
    const onFocus = x => {
        props.onFocus();
        return x;
    };
    const onBlur = x => {
        props.onBlur();
        return x;
    };
    const boundActions = bindActionCreators({
        doUpdateQuery: actionCreators.doUpdateQuery,
        doClearResult: actionCreators.doClearResult,
        doHideResults: compose(onBlur, actionCreators.doHideResults),
        doShowResults: compose(onFocus, actionCreators.doShowResults),
        doSearch: actionCreators.doSearch,
        doSearchSuccess: actionCreators.doSearchSuccess,
        doSearchError: actionCreators.doSearchError,
        doSelectNextItem: actionCreators.doSelectNextItem,
        doSelectPrevItem: actionCreators.doSelectPrevItem,
        doOpenSearch: compose(onFocus, actionCreators.doOpenSearch),
        doSelectItem: actionCreators.doSelectItem,
    }, dispatch);
    const search = query => searchWithActions(query, boundActions.doSearch, boundActions.doSearchSuccess, boundActions.doSearchError);
    const searchFn = query => (query !== '' ? search(createQuery(SEARCH, query)) : boundActions.doClearResult());
    return {
        actions: Object.assign(Object.assign({}, boundActions), { search, debouncedSearch: debounce(searchFn, 500), doRedirect: redirect }),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
