import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { API_URL } from '../../../../../../app-config/constants';
import api from '../../../../../api';
import { get } from '../../../../../api/request';
import LoadingIndicatorFull from '../../../../components/LoadingIndicatorFull';
import { withData } from '../../../../hoc/withData';
import { getCompanyTemplates } from '../../../Reviews/services/templates';
import { EMAIL_TEMPLATE_TYPES } from '../../constants/email-templates';
import ReviewCycleCreate from '../../lazy';
import { getEditModel } from '../../services';
import { getDefaultQuestionnaire } from '../../services/defaultQuestionnaire';
function ReviewCycleEditPage({ cycle, users, emailTemplates, questionnaireTemplates }) {
    const { model: backendModel } = cycle;
    const companyFrontendTemplates = getCompanyTemplates(questionnaireTemplates, getDefaultQuestionnaire());
    const model = getEditModel(backendModel, users, emailTemplates, companyFrontendTemplates);
    return _jsx(ReviewCycleCreate, { mode: "edit", model: model, questionnaireTemplates: questionnaireTemplates });
}
const BASE = `${API_URL}/review-cycles/guided`;
export default compose(setPropTypes({
    cycleId: PropTypes.string.isRequired,
}), withData({
    resolve: {
        cycle: ({ cycleId }) => get(`${BASE}/${cycleId}`),
        users: () => api.usersMedium.getAll(),
        questionnaireTemplates: () => api.reviewQuestionnaire.list(),
        emailTemplates: ({ cycleId }) => api.emailTemplate.getTemplates(EMAIL_TEMPLATE_TYPES, { reviewCycleId: cycleId }),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
}))(ReviewCycleEditPage);
