import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { map } from 'lodash/fp';
import api from '../../../api';
import { withData } from '../../hoc/withData';
import Avatar from '../Avatar';
import LoadingBar from '../LoadingBar';
import MailTo from '../MailTo';
import styles from './style.m.less';
function HrList({ hrs }) {
    return (_jsx("div", { className: styles.container, children: map(user => (_jsxs("div", { className: styles.user, children: [_jsx(Avatar, { user: user, size: "XL", className: styles.avatar }), _jsxs("div", { children: [_jsxs("div", { className: styles.nameAndRoleContainer, children: [_jsx("span", { className: styles.name, children: user.name }), _jsx("span", { className: styles.role, children: user.isHR ? 'HR Admin' : 'HR Assistant' })] }), _jsx("div", { className: styles.mail, children: _jsx(MailTo, { href: user.email }) })] })] }, user.id)), hrs) }));
}
export default withData({
    resolve: {
        hrs: ({ includeAssistants = false }) => api.usersHR.getAllActiveHrs(includeAssistants),
    },
    pendingComponent: () => _jsx(LoadingBar, {}),
})(HrList);
