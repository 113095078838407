import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { memo } from 'react';
import { ADMIN_OVERVIEW } from '../../../../../routes';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { useAppContext } from '../../../../hooks/useAppContext';
import { isAnyAdmin } from '../../../../services/User';
export const makeBreadrumbPath = ({ path = [], page }) => {
    return [
        {
            label: 'Settings',
            url: ADMIN_OVERVIEW,
            prependBaseUrl: true,
        },
        ...path.map(p => (Object.assign(Object.assign({}, p), { prependBaseUrl: true }))),
        { label: page },
    ];
};
const AdminBreadcrumbs = props => {
    const { user } = useAppContext();
    return isAnyAdmin(user) ? _jsx(Breadcrumbs, { path: makeBreadrumbPath(props) }) : null;
};
export default memo(AdminBreadcrumbs);
