import { useState } from 'react';
let id = 0;
const useIdGen = () => {
    const [idGen] = useState(() => {
        const newId = id++;
        return (prefix) => (prefix ? prefix + '-' : '') + 'useId-' + newId;
    });
    return idGen;
};
export default useIdGen;
