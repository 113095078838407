export default {
    MADLIBS: 'MadLibs',
    STACKED_RANKING: 'StackedRankings',
    QUESTION: 'Question',
    RATED_QUESTION: 'LikertScale',
    HEADING: 'Heading',
    CHECKBOXES: 'Checkbox',
    DROPDOWN: 'Dropdown',
    SLIDER: 'Slider',
    REVIEW_GRAPH: 'ReviewGraph',
    REVIEW_QUESTION: 'ReviewQuestion',
    REVIEW_SCALE: 'ReviewScale',
    REVIEW_HEADING: 'ReviewHeading',
    REVIEW_SLIDER: 'ReviewSlider',
    REVIEW_CHECKBOXES: 'ReviewCheckboxes',
    REVIEW_DROPDOWN: 'ReviewDropdown',
    CONFIDENTIAL_QUESTION: 'ConfidentialQuestion',
};
