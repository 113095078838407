// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoAssessment-noAssessment-1N6oF {\n  text-align: initial;\n  max-width: 650px;\n}\n", ""]);
// Exports
exports.locals = {
	"noAssessment": "NoAssessment-noAssessment-1N6oF"
};
module.exports = exports;
