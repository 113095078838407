// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NavigationTabs-container-1dvZr {\n  position: relative;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #e0dfde;\n  overflow-y: hidden;\n  overflow-x: auto;\n  margin-top: 10px;\n}\n.NavigationTabs-tabItem-2auQN {\n  color: #4d4d4c;\n  display: block;\n  padding: 0 10px 10px;\n  border: none;\n  border-bottom: 2px solid transparent;\n  background: transparent;\n  white-space: nowrap;\n  cursor: pointer;\n}\n.NavigationTabs-tabItem-2auQN:hover:not(.NavigationTabs-selected-EK170),\n.NavigationTabs-tabItem-2auQN:focus {\n  text-decoration: none;\n  color: #1a1a1a;\n  outline: none;\n}\n.NavigationTabs-tabItem-2auQN:hover:not(.NavigationTabs-selected-EK170):not(.NavigationTabs-selected-EK170),\n.NavigationTabs-tabItem-2auQN:focus:not(.NavigationTabs-selected-EK170) {\n  border-color: #c7c6c4;\n}\n.NavigationTabs-selected-EK170 {\n  position: relative;\n  cursor: default;\n  color: #4d4d4c;\n  border-color: var(--companyColor);\n  font-weight: 500;\n  transition: none;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.NavigationTabs-selected-EK170:hover {\n  text-decoration: none;\n  background: none;\n}\n.NavigationTabs-deactivated-2XLOe,\n.NavigationTabs-deactivated-2XLOe:hover,\n.NavigationTabs-deactivated-2XLOe:focus {\n  text-decoration: line-through;\n}\n.NavigationTabs-disabled-119pR {\n  color: #a3a2a1;\n  pointer-events: none;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "NavigationTabs-container-1dvZr",
	"tabItem": "NavigationTabs-tabItem-2auQN",
	"selected": "NavigationTabs-selected-EK170",
	"deactivated": "NavigationTabs-deactivated-2XLOe",
	"disabled": "NavigationTabs-disabled-119pR"
};
module.exports = exports;
