import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
function CheckboxContainer(props) {
    const newProps = Object.assign(Object.assign({}, props), { className: classNames(props.className, 'styled-checkboxes') });
    return _jsx("div", Object.assign({}, newProps));
}
CheckboxContainer.propTypes = {
    className: PropTypes.string,
};
export default CheckboxContainer;
