import { any, every, get, sortBy } from 'lodash/fp';
import moment from 'moment';
import { assertNever } from '../../../../../types/helpers';
import colours from '../../../../colours';
import { isFeatureEnabled } from '../../../services/Features';
import replacement from '../../../services/Replacement';
export const getNoteWithAuthor = (feedback, allowNoteFromReviewee = true) => {
    switch (feedback.type) {
        case 'REQUESTED':
            return { note: feedback.noteFromRequester || '', author: feedback.requester };
        case 'CYCLE':
            if (allowNoteFromReviewee &&
                !feedback.noteFromHr &&
                feedback.noteFromReviewee &&
                feedback.reviewees.length === 1) {
                return { note: feedback.noteFromReviewee, author: feedback.reviewees[0] };
            }
            return { note: feedback.noteFromHr || '' };
        default:
            return assertNever(feedback);
    }
};
export const getSecondaryNote = (feedback) => {
    switch (feedback.type) {
        case 'CYCLE':
            return feedback.noteFromReviewee || '';
        default:
            return '';
    }
};
export const getReleaseDate = (feedback) => {
    switch (feedback.type) {
        case 'REQUESTED': // if true, its released immediately
            return feedback.releasedToReviewee ? undefined : feedback.deadline;
        case 'CYCLE':
            return feedback.releaseToYouBy ? feedback.releaseToYouBy : undefined;
        default:
            return assertNever(feedback);
    }
};
export function isCycleFeedbackArray(array) {
    return every(feedback => feedback.type === 'CYCLE', array);
}
export const aggregateFeedbackByReviewee = (initial, rest) => {
    const list = [initial, ...rest];
    const reviewees = list.flatMap(f => f.reviewees);
    const canViewDetails = any(get('canViewDetails'), list);
    return Object.assign(Object.assign({}, initial), { canViewDetails, reviewees: reviewees, canNominateReviewers: false, noteFromReviewee: '' });
};
export const getTypeLabel = (type) => {
    switch (type) {
        case 'CYCLE':
            return replacement('fr.singular.title');
        case 'REQUESTED':
            return isFeatureEnabled('FEATURE_anytimeFeedbackGive') ? replacement('anyfb.name') : 'Feedback Request';
        default:
            return assertNever(type);
    }
};
export const getCreatedYear = (feedback) => moment(feedback.createdAt).year();
export const getEndDate = (feedback) => {
    switch (feedback.type) {
        case 'REQUESTED':
            return feedback.deadline;
        case 'CYCLE':
            return feedback.cycleEndsAt;
        default:
            return assertNever(feedback);
    }
};
export const getTypeName = (feedback) => {
    switch (feedback.type) {
        case 'REQUESTED':
            return isFeatureEnabled('FEATURE_anytimeFeedbackGive') ? replacement('anyfb.name') : 'Feedback Request';
        case 'CYCLE':
            return `${replacement('fr.review.singular.title')} Cycle`;
        default:
            return assertNever(feedback);
    }
};
export const feedbackWillNotChangeFurther = ({ status: s }) => s === 'COMPLETED' || s === 'CYCLE_ENDED' || s === 'DEADLINE_PASSED';
export const getReviewersSortedByProgress = (feedback) => sortBy(reviewer => {
    const progress = feedback.progressByReviewer[reviewer.id];
    switch (progress) {
        case undefined:
            return 0;
        case 'NOT_STARTED':
            return 0;
        case 'WRITING':
            return 1;
        case 'DECLINED':
            return 2;
        case 'HIDDEN':
            return 3;
        case 'WRITTEN':
            return 4;
        default:
            return assertNever(progress);
    }
}, feedback.reviewers);
export const isReviewee = (currentUserId, feedback) => {
    return any((reviewee) => reviewee.id === currentUserId, feedback.reviewees);
};
export const isReviewer = (currentUserId, feedback) => {
    return any((reviewer) => reviewer.id === currentUserId, feedback.reviewers);
};
export const getReviewees = (feedbackDetails) => feedbackDetails.flatMap(f => f.reviewees);
export const showGeneralFeedbackWording = isFeatureEnabled('requestFeedback') &&
    (!isFeatureEnabled('feedback') || isFeatureEnabled('FEATURE_anytimeFeedbackGive'));
export const getProgressLabel = (progress) => {
    switch (progress) {
        case 'WRITING':
            return 'Started';
        case 'NOT_STARTED':
            return 'Not started yet';
        case 'DECLINED':
            return 'Declined';
        case 'HIDDEN':
            return 'Unknown';
        case 'WRITTEN':
            return 'Done';
    }
};
export const getProgressColor = (progress) => {
    switch (progress) {
        case 'WRITING':
            return colours.SIDataYellow;
        case 'NOT_STARTED':
            return colours.SIDataBadred;
        case 'DECLINED':
            return colours.SIDataOrange;
        case 'HIDDEN':
            return colours.SIGray2;
        case 'WRITTEN':
            return colours.SIDataGoodgreen;
    }
};
export const isByRevieweeNominationProcess = (nominationProcess) => {
    switch (nominationProcess) {
        case 'REVIEWEE':
        case 'REVIEWEE_WITHOUT_APPROVAL':
            return true;
        default:
            return false;
    }
};
