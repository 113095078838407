import { compose, filter, has, keyBy, map, uniqBy, values } from 'lodash/fp';
import api from '../../../api';
import * as ROUTES from '../../../routes';
import { interpolateAppUrl } from '../Url';
export function isIc(user) {
    return !hasBasicHr(user) && !isManager(user);
}
export function isAnyAdmin(user) {
    return isAdmin(user) || hasBasicHr(user);
}
export function hasManager(user) {
    return !!user.managerId;
}
export function getRoleInfo(user) {
    return {
        ADMIN: {
            isActive: isAdmin(user),
            key: 'ADMIN',
            name: 'Tech Admin',
        },
        HR: {
            isActive: isHr(user),
            key: 'HR',
            name: 'HR Admin',
        },
        ASSISTANT: {
            isActive: isAssistant(user),
            key: 'ASSISTANT',
            name: 'HR Assistant',
        },
    };
}
export function enumerateRoles(user) {
    return compose(filter(r => r.isActive), values, getRoleInfo)(user);
}
export function isAdmin(user) {
    return user.isAdmin;
}
export function hasAnyRole(user) {
    return hasBasicHr(user) || isGuest(user) || !isActive(user);
}
export function hasBasicHr(user) {
    return isHr(user) || isAssistant(user);
}
export function isSuperUser(user) {
    return isHr(user) && isAdmin(user);
}
export function isHr(user) {
    return user.isHR;
}
export function isAssistant(user) {
    return user.isAssistant;
}
export function isGuest(user) {
    return user.isGuest;
}
export function isActive(user) {
    return user.isActive;
}
export function isFake(user) {
    return user.loginname.endsWith('.fake');
}
export function isAnonymous(user) {
    const ANONYMOUS_ID = 'anonymous--id';
    return user.id === ANONYMOUS_ID;
}
export function isDeleted(user) {
    const DELETED_USER_ID = 'deleted-user-id';
    return user.id === DELETED_USER_ID;
}
export function isTaskUser(user) {
    return user.id === 'automation-bot-id' || (!!user.id && user.id.startsWith('TASK_'));
}
export function isManager(user) {
    return getDirectReports(user).length > 0;
}
export function isWithoutManager(user) {
    return !user.managerId;
}
export function getDirectReports(user) {
    return user.reports || [];
}
export function getUrlToProfile(user) {
    return interpolateAppUrl(ROUTES.USER_PROFILE, { id: user.id });
}
export async function getTeamMembersWithProfileUrl(user) {
    const teamMembers = await getTeamMembers(user);
    return teamMembers.map(teamMember => (Object.assign(Object.assign({}, teamMember), { profileUrl: getUrlToProfile(teamMember) })));
}
export async function getTeamMembers(user) {
    let teamMates = [];
    if (isManager(user)) {
        teamMates = getReports(user);
    }
    else if (user.managerId) {
        const manager = await api.users.getById(user.managerId);
        let reports = [];
        reports = getReports(manager).filter(u => u.id !== user.id);
        teamMates = [...reports, manager];
    }
    else if (user.manager) {
        // basic manager info
        teamMates = [user.manager];
    }
    const secondaryReports = !isManager(user) && hasSecondaryReports(user) ? getSecondaryReports(user) : [];
    const teamMembers = [...teamMates, ...secondaryReports];
    return uniqBy('id', teamMembers);
}
export function isTeamMember(members) {
    const membersIndex = keyBy(u => u.id, members);
    return (user) => has(user.id, membersIndex);
}
export function getManager(user) {
    if (user.manager) {
        return [user.manager];
    }
    else {
        return [];
    }
}
function getReports(user) {
    return [...getDirectReports(user), ...getSecondaryReports(user)];
}
export function getSecondaryReports(user) {
    return map(markAsSecondary, user.secondaryReviewees);
}
function markAsSecondary(user) {
    return Object.assign(Object.assign({}, user), { isSecondaryReviewee: true });
}
export function hasSecondaryReports(user) {
    return getSecondaryReports(user).length > 0;
}
export async function getManagersReports(user) {
    if (hasManager(user)) {
        const manager = await api.users.getById(user.managerId);
        return getDirectReports(manager);
    }
    else {
        return [];
    }
}
export function isUserEqual(user1, user2) {
    return user1.id === user2.id;
}
