// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button-dot-Wg3Ff {\n  position: absolute;\n  top: -5px;\n  left: 100%;\n  margin-left: -13px;\n  max-width: 25px;\n  height: 18px;\n  min-width: 18px;\n  border-radius: 10px;\n  border: 2px solid #ffffff;\n  background: #f23037;\n  color: #ffffff;\n  font-size: 10px;\n  text-align: center;\n  padding: 0 3px;\n  line-height: 1.5;\n}\n", ""]);
// Exports
exports.locals = {
	"dot": "Button-dot-Wg3Ff"
};
module.exports = exports;
