import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import c from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import Spinner from '../Spinner';
import styles from './style.m.less';
let idCounter = 0;
function generateId() {
    if (idCounter > 100000) {
        idCounter = 0;
    }
    return `react-checkbox-${idCounter++}`;
}
function Checkbox(_a) {
    var { id: idProp, label, description, className, pending = false, disabled = false, hiddenLabel = false, compact = false, indeterminate = false, checked } = _a, rest = __rest(_a, ["id", "label", "description", "className", "pending", "disabled", "hiddenLabel", "compact", "indeterminate", "checked"]);
    const id = idProp || generateId();
    const descriptionId = description ? `${id}-description` : undefined;
    const inputProps = Object.assign(Object.assign({ onChange: () => { }, 'aria-describedby': descriptionId, 'aria-label': hiddenLabel && typeof label === 'string' ? label : undefined, 'aria-checked': indeterminate ? 'mixed' : checked, checked: checked }, rest), { disabled: disabled || pending, id, type: 'checkbox', ref: input => {
            if (input) {
                input.indeterminate = indeterminate;
            }
        } });
    return (_jsxs("span", { className: className, children: [_jsx("input", Object.assign({}, inputProps)), _jsx("label", { htmlFor: id, className: c({
                    [styles.pending]: pending,
                    compact: compact,
                    hiddenLabel: hiddenLabel,
                }), children: !hiddenLabel && (_jsxs(_Fragment, { children: [pending && _jsx(Spinner, { className: styles.pendingSpinner }), label] })) }), description && (_jsx("div", { id: descriptionId, className: styles.description, children: description }))] }));
}
Checkbox.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.node,
    description: PropTypes.node,
    id: PropTypes.string,
    pending: PropTypes.bool,
    checked: PropTypes.bool,
};
export default Checkbox;
