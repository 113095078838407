import CycleCreateExceptions from './CycleCreateExceptions';
import CycleEditExceptions from './CycleEditExceptions';
import FeedbackTemplateAdminExceptions from './FeedbackTemplateAdminExceptions';
import PraiseWallExceptions from './PraiseWallExceptions';
export const ALL_REGISTERED_EXCEPTIONS = {
    CYCLE_CREATE: 'CYCLE_CREATE',
    CYCLE_EDIT: 'CYCLE_EDIT',
    FEEDBACK_TEMPLATE_ADMIN: 'FEEDBACK_TEMPLATE_ADMIN',
    MEETINGS: 'MEETINGS',
    PRAISE_WALL: 'PRAISE_WALL',
};
export const SPECIFIC_EXCEPTION_COMPONENTS = {
    CYCLE_CREATE: CycleCreateExceptions,
    CYCLE_EDIT: CycleEditExceptions,
    FEEDBACK_TEMPLATE_ADMIN: FeedbackTemplateAdminExceptions,
    PRAISE_WALL: PraiseWallExceptions,
};
