import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { map } from 'lodash/fp';
import Checkbox from '../../../../components/Checkbox';
import DatePicker from '../../../../components/DateTime/DatePicker';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { Option, Select } from '../../../../components/Select';
import { getCurrentUser } from '../../../../services/CurrentUser';
import moment from 'moment';
import { euAllowExternalServices, grantSiImpersonationUntil, notifyReviewersEveryNHours } from '../constants';
import { getAllowExternalServices, getGrantSiImpersonation, getGrantSiImpersonationUntil, getNotifyReviewersEveryNHours, setAllowExternalServices, setGrantSiImpersonation, setGrantSiImpersonationUntil, setNotifyReviewersEveryNHours, } from '../model';
import styles from '../style.m.less';
import SettingsRow from './SettingsRow';
const GrantImpersonationSettings = ({ model, setModel }) => {
    function formatTimestampToDateString(timestamp) {
        if (!timestamp) {
            return undefined;
        }
        return moment(timestamp).format('YYYY-MM-DD');
    }
    return (_jsxs(SettingsRow, { label: "Allow Small Improvements staff in", children: [_jsx("h3", { className: "hidden-md", children: "Allow Small Improvements staff in" }), _jsx(Checkbox, { label: "Allow Small Improvements staff in", type: "checkbox", id: "grantSiImpersonation", checked: getGrantSiImpersonation(model), onChange: e => setModel(setGrantSiImpersonation(e.target.checked, model)), hiddenLabel: true }), _jsx(FormLabel, { htmlFor: "grantSiImpersonation", children: "Grant SI staff access at all" }), _jsx("br", {}), _jsx(FormLabel, { htmlFor: "grantSiImpersonationUntil", children: "Grant Access Until" }), _jsx("br", {}), _jsx("span", { className: styles.pickerStart, children: _jsx(DatePicker, { date: formatTimestampToDateString(getGrantSiImpersonationUntil(model)), id: "grantSiImpersonationUntil", onChange: (date) => setModel(setGrantSiImpersonationUntil(date, model)), isClearable: true }) }), "(blank means permanently)", _jsx("br", {}), _jsx("p", { className: "caption", children: grantSiImpersonationUntil })] }));
};
const ReviewerNotificationSettings = ({ model, setModel }) => {
    const ReviewerNotificationProps = {
        selected: getNotifyReviewersEveryNHours(model),
        optionToText: (hour) => `${hour}`,
        onSelect: (hour) => setModel(setNotifyReviewersEveryNHours(hour, model)),
        className: styles.hourSelect,
    };
    return (_jsxs(SettingsRow, { label: _jsx(FormLabel, { htmlFor: "notifyReviewersEveryNHours", children: "360\u00B0 Feedback Reviewer Notification Interval" }), children: [_jsx("h3", { className: "hidden-md", children: "Notify Reviewers" }), _jsxs("div", { className: styles.flexAlignCenter, children: [_jsx("span", { className: styles.pickerStart, children: _jsx(Select, Object.assign({}, ReviewerNotificationProps, { children: map(hour => {
                                return (_jsx(Option, { value: hour, children: hour }, hour));
                            }, [1, 2, 4, 6, 8, 12, 24]) })) }), "Hours"] }), _jsx("p", { className: "caption", children: notifyReviewersEveryNHours })] }));
};
const ExternalServicesSettings = ({ model, setModel }) => (_jsxs(SettingsRow, { label: "Help us", children: [_jsx("h3", { className: "hidden-md", children: "Help us" }), _jsx(Checkbox, { label: "Allow external services", type: "checkbox", id: "euAllowExternalServices", checked: getAllowExternalServices(model), onChange: e => setModel(setAllowExternalServices(e.target.checked, model)), hiddenLabel: true }), _jsx(FormLabel, { htmlFor: "euAllowExternalServices", children: "Allow external services" }), _jsx("p", { className: "caption", children: euAllowExternalServices })] }));
const AdvancedSettings = ({ model, setModel }) => {
    const euServerOrLocal = getCurrentUser().company.server;
    return (_jsxs("div", { children: [_jsx("h2", { children: "Advanced Settings" }), _jsx("table", { className: "settingsTable", children: _jsxs("tbody", { children: [_jsx(GrantImpersonationSettings, { model: model, setModel: setModel }), _jsx(ReviewerNotificationSettings, { model: model, setModel: setModel }), (euServerOrLocal === 'local' || euServerOrLocal === 'prod-eu') && (_jsx(ExternalServicesSettings, { model: model, setModel: setModel }))] }) })] }));
};
export default AdvancedSettings;
