import { pick } from 'lodash/fp';
import { Component, createElement } from 'react';
import { setDisplayName, shallowEqual, wrapDisplayName } from 'recompose';
export const mapProps = propsMapper => BaseComponent => {
    const MapProps = props => createElement(BaseComponent, propsMapper(props));
    if (process.env.NODE_ENV !== 'production') {
        return setDisplayName(wrapDisplayName(BaseComponent, 'mapProps'))(MapProps);
    }
    return MapProps;
};
export const shouldUpdate = test => BaseComponent => {
    class ShouldUpdate extends Component {
        shouldComponentUpdate(nextProps) {
            return test(this.props, nextProps);
        }
        render() {
            return createElement(BaseComponent, this.props);
        }
    }
    if (process.env.NODE_ENV !== 'production') {
        return setDisplayName(wrapDisplayName(BaseComponent, 'shouldUpdate'))(ShouldUpdate);
    }
    return ShouldUpdate;
};
export const onlyUpdateForKeys = propKeys => {
    const pickProps = pick(propKeys);
    const hoc = shouldUpdate((props, nextProps) => !shallowEqual(pickProps(nextProps), pickProps(props)));
    if (process.env.NODE_ENV !== 'production') {
        return BaseComponent => setDisplayName(wrapDisplayName(BaseComponent, 'onlyUpdateForKeys'))(hoc(BaseComponent));
    }
    return hoc;
};
export const pure = BaseComponent => {
    const hoc = shouldUpdate((props, nextProps) => !shallowEqual(props, nextProps));
    if (process.env.NODE_ENV !== 'production') {
        return setDisplayName(wrapDisplayName(BaseComponent, 'pure'))(hoc(BaseComponent));
    }
    return hoc(BaseComponent);
};
