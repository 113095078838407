import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import classNames from 'classnames';
import { debounce } from 'lodash/fp';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../api';
import Link from '../../components/Link';
import NavigationList from './List';
import SupportLink from './SupportLink';
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
const useShouldNavigationScroll = () => {
    const [shouldScroll, setShouldScroll] = useState(false);
    const [element, setElement] = useState(null);
    const ref = useCallback((newElement) => {
        setElement(newElement);
    }, []);
    useEffect(() => {
        if (!element) {
            return;
        }
        const resizeHandler = debounce(500, () => {
            setShouldScroll(element.scrollHeight > element.clientHeight);
        });
        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(element);
        window.addEventListener('resize', resizeHandler);
        return () => {
            resizeObserver.disconnect();
            window.removeEventListener('resize', resizeHandler);
        };
    }, [element]);
    return { shouldScroll, ref };
};
const MainNavigationInner = ({ children }) => {
    const { shouldScroll, ref } = useShouldNavigationScroll();
    return (_jsx("div", { className: classNames('main_navigation-inner', { 'main_navigation-inner--scroll': shouldScroll }), ref: ref, children: children }));
};
const MainNavigation = ({ closeNavigation }) => {
    return (_jsxs("div", { className: "main-navigation-container", children: [_jsx("button", { className: "menu_overlay", "aria-label": "Close navigation menu.", onClick: closeNavigation }), _jsx("div", { className: "main_navigation", children: _jsxs(MainNavigationInner, { children: [_jsx("div", { className: "main_navigation-list", children: _jsx(NavigationList, {}) }), _jsx("footer", { className: "main_navigation-footer", children: _jsxs("menu", { className: "main_navigation-footer-menu", children: [_jsx("li", { className: "main_navigation-footer-item", children: _jsx(Link, { href: "https://www.small-improvements.com/release-notes/", onClick: () => api.userSettings.setCheckedReleaseNotes(), rel: "noopener noreferrer", target: "_blank", className: "main_navigation-footer-link", eventTracking: {
                                                category: 'navigation footer',
                                                action: 'click',
                                                label: `what's new`,
                                            }, children: "What's New" }) }), _jsx("li", { className: "main_navigation-footer-item", children: _jsx(Link, { href: "https://intercomdocs.small-improvements.com/en/", openInNewTab: true, className: "main_navigation-footer-link", eventTracking: {
                                                category: 'navigation footer',
                                                action: 'click',
                                                label: 'help center',
                                            }, children: "Help Center" }) }), _jsx("li", { className: "main_navigation-footer-item", children: _jsx(SupportLink, {}) }), _jsx("li", { className: "main_navigation-footer-item", children: _jsx(Link, { href: "/welcome", className: "main_navigation-footer-link", eventTracking: {
                                                category: 'navigation footer',
                                                action: 'click',
                                                label: 'small improvements',
                                            }, children: "Small Improvements" }) })] }) })] }) })] }));
};
export default MainNavigation;
