export function noun(billingPeriod) {
    switch (billingPeriod) {
        case 1:
            return 'month';
        case 2:
            return '2 months';
        case 3:
            return 'quarter';
        case 6:
            return 'half-year';
        case 12:
            return 'year';
        case 24:
            return '2 years';
        default:
            return `${billingPeriod} months`;
    }
}
/**
 * Sums up the fields in a all of a priceInfoDtos units
 */
export const sumBy = (info, field) => info.units.map(u => u[field]).reduce((a, b) => a + b, 0);
/**
 * Lets you map over the units in a priceInfoDto and then sums up the results
 */
export const mapReduce = (info, map) => info.units.map(map).reduce((a, b) => a + b, 0);
