import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { registerConfirmationHandler } from '../../../routing/history';
import InterceptorModal from './InterceptorModal';
export default class GlobalInterceptorModal extends Component {
    constructor() {
        super(...arguments);
        this.unregister = null;
        this.state = {
            callback: null,
        };
        this.callbacks = {
            onConfirm: () => {
                const callback = this.state.callback;
                if (!callback)
                    throw new Error('OnConfirm called while not waiting for confirmation');
                this.setState({ callback: null, message: undefined }, () => {
                    callback(true);
                });
            },
            onCancel: () => {
                const callback = this.state.callback;
                if (!callback)
                    throw new Error('OnCancel called while not waiting for confirmation');
                this.setState({ callback: null, message: undefined }, () => {
                    callback(false);
                });
            },
        };
        this.getConfirmation = (message, callback) => {
            this.setState({ callback, message });
        };
    }
    componentDidMount() {
        this.unregister = registerConfirmationHandler(this.getConfirmation);
    }
    componentWillUnmount() {
        this.unregister();
        this.unregister = null;
    }
    render() {
        return (_jsx(InterceptorModal, { isOpen: !!this.state.callback, callbacks: this.callbacks, message: this.state.message }));
    }
}
