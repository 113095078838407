import { jsx as _jsx } from "react/jsx-runtime";
import * as ROUTES from '../../../../../../routes';
import AdminNavigationContainer from '../../../../../components/AdminNavigationContainer';
import { useAppContext } from '../../../../../hooks/useAppContext';
import { isFeatureEnabled } from '../../../../../services/Features';
export const COMPANY_TEMPLATES = 'Company Templates';
export const USER_TEMPLATES = 'Personal Templates';
export const SHARED = 'Shared with you';
export const INSIGHTS = 'Insights';
export const SETTINGS = 'Settings';
export const EMAILS = 'Emails';
export default function Nav({ value, mode = 'ADMIN' }) {
    const appContext = useAppContext();
    const NAV_OPTIONS = [];
    const currentUser = appContext.user;
    const isAdminMode = mode === 'ADMIN';
    if (isAdminMode) {
        if (currentUser.permissions.canViewMeetingInsights) {
            NAV_OPTIONS.push({ href: ROUTES.BASE + ROUTES.ADMIN_MEETINGS_INSIGHTS, value: INSIGHTS });
        }
        if (currentUser.permissions.canManageMeetingTemplates) {
            NAV_OPTIONS.push({ href: ROUTES.BASE + ROUTES.ADMIN_MEETINGS_TEMPLATES, value: COMPANY_TEMPLATES });
        }
        if (currentUser.isHR) {
            NAV_OPTIONS.push({ href: ROUTES.BASE + ROUTES.ADMIN_MEETINGS_SETTINGS, value: SETTINGS });
        }
        if (currentUser.isHR) {
            NAV_OPTIONS.push({ href: ROUTES.BASE + ROUTES.ADMIN_MEETINGS_EMAILS, value: EMAILS });
        }
    }
    else {
        if (currentUser.permissions.canViewSharedMeetings) {
            NAV_OPTIONS.push({ href: ROUTES.BASE + ROUTES.YOUR_COMPANY_MEETINGS_SHARED, value: SHARED });
        }
        if (isFeatureEnabled('FEATURE_meetingUserTemplates')) {
            NAV_OPTIONS.push({
                href: ROUTES.BASE + ROUTES.YOUR_COMPANY_MEETINGS_MY_TEMPLATES,
                value: USER_TEMPLATES,
            });
        }
    }
    if (NAV_OPTIONS.length <= 1) {
        return null;
    }
    return _jsx(AdminNavigationContainer, { navOptions: NAV_OPTIONS, value: value });
}
