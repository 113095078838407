import { jsx as _jsx } from "react/jsx-runtime";
import { trackEvent } from '../../services/Tracking';
import { PanelCheckboxButton } from '../PanelCheckboxButton';
import styles from './style.m.less';
export default function VerticalCheckboxes({ options, checkedValues, onChange, eventTracking, }) {
    return (_jsx("fieldset", { className: styles.verticalCheckboxes, children: options.map(option => {
            const checked = checkedValues.includes(option.value);
            return (_jsx(PanelCheckboxButton, { label: option.label, name: "interests", checked: checked, disabled: option.disabled, onClick: () => {
                    if (eventTracking) {
                        trackEvent(Object.assign(Object.assign({}, eventTracking), { label: option.trackingLabel }));
                    }
                    onChange(option.value, !checked);
                } }, option.value));
        }) }));
}
