import { omit, reduce, some } from 'lodash';
import { map } from 'lodash/fp';
import { API_URL } from '../../../../../../app-config/constants';
import api from '../../../../../api';
import { feedback as routes } from '../../../../../api-routes/constant';
// TODO: Replace with real ladda integration
import { get, post } from '../../../../../api/request';
import { invalidateEmailTemplateApi } from '../../../EmailTemplates/service';
import { HR_NOMINATION } from '../../constants/nomination-process';
const asApiUrl = url => `${API_URL}${url}`;
export async function createFeedbackCycle(model, inform) {
    const postData = {
        model: rectifyModel(model),
        informNominatorsAfterCreation: inform,
    };
    const newFeedbackCycle = post(asApiUrl(routes.cycle.create.path), { postData });
    invalidateEmailTemplateApi();
    return newFeedbackCycle;
}
export async function updateFeedbackCycle(model) {
    const postData = { model: rectifyModel(model) };
    const updatedFeedbackCycle = await post(asApiUrl(routes.cycle.update.path), { postData });
    invalidateEmailTemplateApi();
    return updatedFeedbackCycle;
}
export function pollFeedbackCycleCreateStatus(id) {
    return get(asApiUrl(`${routes.cycle.path}/${id}/feedback-requests/count`));
}
const toSingleRequired = section => (Object.assign(Object.assign({}, section), { required: section.requiredForManager && section.requiredForReviewee }));
export function rectifyModel(model) {
    return Object.assign(Object.assign({}, model), { visibility: rectifyVisiblity(model), nominationLimit: rectifyNominationLimit(model), nominationSuggestion: rectifyNominationSuggestion(model), nominationNote: rectifyNominationNote(model), timeline: rectifyTimeline(model), emailTemplates: model.emailTemplates.filter(template => !!(template.cycleId && template.modified)), sections: map(toSingleRequired, model.sections) });
}
export function rectifyTimeline(model) {
    const { timeline, disabledDates } = model;
    const datesToOmit = reduce(disabledDates, (result, value, key) => {
        if (value) {
            result.push(key);
        }
        return result;
    }, []);
    return omit(timeline, datesToOmit);
}
export function rectifyNominationLimit(model) {
    const { nomination, nominationLimit } = model;
    if (nomination === HR_NOMINATION) {
        return {
            enabled: false,
            limit: null,
        };
    }
    else {
        return nominationLimit;
    }
}
export function rectifyNominationSuggestion(model) {
    const { nomination, nominationSuggestion } = model;
    if (nomination === HR_NOMINATION) {
        return {
            enabled: false,
            lowerLimit: null,
            upperLimit: null,
        };
    }
    else {
        return nominationSuggestion;
    }
}
export function rectifyNominationNote(model) {
    const { nominationNote } = model;
    if (nominationNote.enabled) {
        return nominationNote;
    }
    return { enabled: false, note: '' };
}
export function canToggleSplitOption(visibility) {
    const { reviewee, directManager, indirectManager } = visibility;
    const allGroups = [reviewee, directManager, indirectManager];
    return some(allGroups, group => group.enabled && group.hideAuthor);
}
export function rectifyVisiblity(model) {
    const { visibility, nomination } = model;
    const { reviewee, directManager, indirectManager } = visibility;
    const { split } = reviewee;
    const newRevieweeVis = rectifyRevieweeVisibilityGroup(reviewee);
    const shouldSplit = split && canToggleSplitOption(visibility);
    return {
        reviewee: Object.assign(Object.assign({}, newRevieweeVis), { split: shouldSplit, informAboutDecline: newRevieweeVis.informAboutDecline && !newRevieweeVis.hideSelectionProcess }),
        directManager: rectifyManagerVisibilityGroup(directManager, indirectManager, nomination),
        indirectManager: rectifyManagerVisibilityGroup(indirectManager, directManager, nomination),
    };
}
function rectifyRevieweeVisibilityGroup(group) {
    if (!group.enabled) {
        const rectified = reduce(omit(group, ['hideSelectionProcess', 'informAboutDecline']), (mem, v, k) => {
            mem[k] = false;
            return mem;
        }, {});
        return Object.assign(Object.assign({}, rectified), { hideSelectionProcess: group.hideSelectionProcess, informAboutDecline: group.informAboutDecline });
    }
    return Object.assign(Object.assign({}, group), { hideSelectionProcess: group.hideAuthor && group.hideSelectionProcess });
}
function rectifyManagerVisibilityGroup(group, otherGroup, nomination) {
    let rectified = group;
    if (!group.enabled) {
        rectified = reduce(omit(group, ['hideSelectionProcess']), (mem, v, k) => {
            mem[k] = false;
            return mem;
        }, {});
    }
    const canHideSelectionList = !(group.enabled && otherGroup.enabled && !group.hideAuthor && !otherGroup.hideAuthor);
    const hideSelectionProcess = nomination === HR_NOMINATION && canHideSelectionList && group.hideSelectionProcess;
    return Object.assign(Object.assign({}, rectified), { hideSelectionProcess });
}
const BASE = `${API_URL}/feedback-cycles/guided`;
const decodeSection = section => (Object.assign(Object.assign({}, section), { requiredForManager: section.required, requiredForReviewee: section.required }));
const decodeModel = model => (Object.assign(Object.assign({}, model), { sections: map(decodeSection, model.sections) }));
export const loadFeedbackCycle = cycleId => {
    return get(`${BASE}/${cycleId}`).then(cycle => (Object.assign(Object.assign({}, cycle), { model: decodeModel(cycle.model) })));
};
export const loadQuestionnaireTemplates = () => api.feedbackTemplateCycle.getAllActive().then(map(template => (Object.assign(Object.assign({}, template), { sections: map(decodeSection, template.sections) }))));
