import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import styles from './style.m.less';
export default function EntityPreview({ title, subtitle }) {
    return (_jsxs("section", { className: styles.preview, children: [_jsx("h1", { className: styles.title, children: title }), _jsx("div", { className: styles.subtitle, children: subtitle })] }));
}
EntityPreview.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.node,
};
