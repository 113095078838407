import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
const CustomBackdrop = () => {
    const { companyBackgroundId } = useAppContext().user.company.companyDesignSettings;
    if (!companyBackgroundId) {
        return null;
    }
    const src = `url(/imageservice?id=${companyBackgroundId}&crop=false&size=-1)`;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "custom_backdrop no-print", style: { backgroundImage: src } }), _jsxs("div", { className: "custom_backdrop custom_backdrop--blur-container no-print", children: [_jsx("div", { className: "custom_backdrop--filter" }), _jsx("div", { className: "custom_backdrop--blur", style: { backgroundImage: src } })] })] }));
};
export default CustomBackdrop;
