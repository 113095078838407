import { filter, flatten, map, partition, sortBy } from 'lodash';
import api from '../../../api';
import { getLatest as getLatestCycles } from '../../../api/custom/Cycles';
import { getCurrentUser } from '../CurrentUser';
import { getUrl as getUrlToCycle } from '../Cycle';
import { getTeamMembers, getUrlToProfile, hasBasicHr } from '../User';
import { searchForCycles } from './search-cycles';
import { searchForUsers } from './search-users';
export { getPages } from './pages';
export const SEARCH = 'SEARCH';
export const TEAM = 'TEAM';
export const CYCLE_SPECIFIC_REVIEWEES = 'CYCLE_SPECIFIC_REVIEWEES';
export const CYCLE = 'CYCLE';
export function createQuery(type, value) {
    return {
        type,
        value,
    };
}
export function fetch(query) {
    switch (query.type) {
        case TEAM:
            return getTeam(query.value);
        case CYCLE:
            return getCycles();
        case SEARCH:
            return search(query.value);
        default:
            throw new Error('Invalid query');
    }
}
function search(query) {
    let results = Promise.resolve({ query, result: [], config: Object.assign({}, getSearchConfig()) })
        .then(searchForUsers)
        .then(searchForCycles);
    return results.then(orderByScoreDesc).then(createResult(SEARCH));
}
const cyclesCache = {
    timestamp: 0,
    value: null,
};
function getCycles() {
    if (!isCacheValid(cyclesCache, 60)) {
        const cycles = getLatestCycles(3)
            .then(removeDisabledCycles)
            .then(asResult)
            .then(createResult(CYCLE))
            .then(addUrlToCycle)
            .then(sortCycles);
        cyclesCache.value = cycles;
        cyclesCache.timestamp = Date.now();
    }
    return cyclesCache.value;
}
function isCacheValid(cache, secondsTTL) {
    return Date.now() - cache.timestamp < secondsTTL * 1000;
}
function sortCycles(cycles) {
    cycles.data = sortBy(cycles.data, cycle => {
        return {
            FEEDBACK: 0,
            REVIEW: 1,
            OBJECTIVE: 2,
        }[cycle.value.type];
    });
    return cycles;
}
function addUrlToCycle(cycles) {
    cycles.data = map(cycles.data, cycle => {
        cycle.value.url = getUrlToCycle(getCurrentUser(), cycle.value);
        return cycle;
    });
    return cycles;
}
function asResult(x) {
    return { result: x };
}
function getTeam(user) {
    const team = Promise.resolve(getTeamMembers(user)).then(asResult).then(tagItems(TEAM)).then(sortAlphabetic);
    const cycleSpecificReviewees = api.reviewees.getReviewees().then(asResult).then(tagItems(CYCLE_SPECIFIC_REVIEWEES));
    return Promise.all([team, cycleSpecificReviewees])
        .then(([x, y]) => [...x, ...y])
        .then(createResultWithoutTaggingItems(TEAM))
        .then(addUrlToUser);
}
function sortAlphabetic(data) {
    const partitions = partition(data, user => !user.value.isSecondaryReviewee);
    const sortedPartitions = map(partitions, teamPartition => {
        return sortBy(teamPartition, user => user.value.name);
    });
    return flatten(sortedPartitions);
}
function addUrlToUser(team) {
    team.data = map(team.data, user => {
        user.value.url = getUrlToProfile(user.value);
        return user;
    });
    return team;
}
function getSearchConfig() {
    const user = getCurrentUser();
    return {
        searchForUsers: {
            includeDeactivated: hasBasicHr(user),
            includeGuests: true,
        },
    };
}
function withType(type) {
    return x => ({
        type,
        value: x,
    });
}
function withId(nextFn) {
    return x => {
        const withTypeAdded = nextFn(x);
        const withTypeAndId = Object.assign(Object.assign({}, withTypeAdded), { id: getId(withTypeAdded) });
        return withTypeAndId;
    };
}
function getId(item) {
    return item.value.id;
}
function createResult(type) {
    return data => ({
        data: tagItems(type)(data),
        type,
    });
}
function createResultWithoutTaggingItems(type) {
    return data => ({
        data,
        type,
    });
}
function tagItems(type) {
    return data => {
        return map(data.result, withId(withType(type)));
    };
}
function removeDisabledCycles(cycles) {
    const enabledFeatures = getCurrentUser().company.enabledFeatures;
    if (!enabledFeatures.objective) {
        cycles = filter(cycles, c => c.type !== 'OBJECTIVE');
    }
    if (!enabledFeatures.feedback) {
        cycles = filter(cycles, c => c.type !== 'FEEDBACK');
    }
    if (!enabledFeatures.review) {
        cycles = filter(cycles, c => c.type !== 'REVIEW');
    }
    return cycles;
}
function orderByScoreDesc(result) {
    return Object.assign(Object.assign({}, result), { result: orderUsersByScoreDesc(result.result) });
}
function orderUsersByScoreDesc(users) {
    return sortBy(users, user => -user.score);
}
export function searchWithActions(query, startAction, successAction, errorAction) {
    startAction(query);
    return fetch(query).then(result => successAction(query, result), error => errorAction(query, error));
}
