import * as urlBuilder from '../../../common/services/url-builder';
function attachProfileInfo(currentUser, user, useFirstName = false) {
    return Object.assign({ link: getLink(user), displayedName: getDisplayedName(currentUser, user, useFirstName) }, user);
}
function getDisplayedName(currentUser, user, useFirstName, anonymous) {
    if (!user) {
        return '';
    }
    const { firstName, name, deleted } = user;
    if (deleted) {
        return '[Deleted User]';
    }
    const displayName = useFirstName ? firstName || name : name;
    const appendix = getAppendix(currentUser, user, anonymous);
    return `${displayName}${appendix}`;
}
function getAppendix(currentUser, user, anonymous) {
    const isCurrent = isCurrentUser(currentUser, user);
    const isAnonymous = user.anonymous === true || anonymous;
    const appendices = [
        isCurrent ? ' (You)' : '',
        isCurrent && isAnonymous ? ' [Anonymously]' : '',
        user.isActive === false ? ' [Deactivated]' : '',
    ];
    return appendices.join('');
}
function getLink(user) {
    const { task, deleted, anonymous } = user;
    if (task || deleted || anonymous) {
        return;
    }
    return urlBuilder.linkToNewUserProfile(user.id);
}
function isCurrentUser(currentUser, user) {
    return currentUser && currentUser.id === user.id;
}
export { attachProfileInfo, getDisplayedName, getLink, isCurrentUser };
