import { MODES } from '../../CycleCreateContent';
export function createControlActions(dispatch, { create, copy, edit, openInform, goToOverview, canInform, isCycleStarted, onSuccess }) {
    const onSave = (model, mode) => {
        const cycleHasStarted = isCycleStarted(model);
        if ((mode === MODES.create || mode === MODES.copy) && cycleHasStarted && canInform(model)) {
            dispatch(openInform(cycleHasStarted));
        }
        else {
            const ON_SAVE_ACTIONS = {
                [MODES.create]: create,
                [MODES.copy]: copy,
                [MODES.edit]: edit,
            };
            dispatch(ON_SAVE_ACTIONS[mode]({ model, inform: false, cycleHasStarted }));
        }
    };
    return {
        actions: {
            onSave,
            onSuccess,
            onCancel: goToOverview,
        },
    };
}
