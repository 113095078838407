import { get, some } from 'lodash/fp';
import { parse } from 'query-string';
import { getCurrentUser } from '../../../services/CurrentUser';
export const isReviewee = (user, review) => get('reviewee.user.id', review) === user.id;
export const isUserMainReviewer = (user, review) => get('mainReviewer.id', review) === user.id;
export const isUserContributingReviewer = (user, review) => some({ id: user.id }, review.contributingReviewers);
export const isSelfInvolved = (user, review) => isReviewee(user, review) || isUserMainReviewer(user, review) || isUserContributingReviewer(user, review);
export const getAuthorId = (location, review) => {
    if (!review.useIndividualAssessments) {
        // Legacy behavior: Always edit for main reviewer, unless you're the reviewee.
        return isReviewee(getCurrentUser(), review) ? review.reviewee.user.id : review.mainReviewer.id;
    }
    const { editAs } = parse(location.search);
    return editAs || getCurrentUser().id;
};
