import { jsx as _jsx } from "react/jsx-runtime";
import { includes, isEmpty } from 'lodash/fp';
import api from '../../../api';
import { withData } from '../../hoc/withData';
import { getCurrentUser } from '../../services/CurrentUser';
import Loader from '../Loader';
import { AdminHelpListResolved } from './AdminHelpListResolved';
export default withData({
    resolve: {
        admins: ({ adminIds }) => {
            const nonNullIds = adminIds.filter(e => e);
            return isEmpty(nonNullIds) || includes(getCurrentUser().id, nonNullIds)
                ? Promise.resolve([])
                : api.usersMedium.getByIds(nonNullIds);
        },
    },
    pendingComponent: () => _jsx(Loader, {}),
})(AdminHelpListResolved);
