import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import LoadingBar from '../../../../components/LoadingBar';
import Modal from '../../../../components/Modal';
import { withData } from '../../../../hoc/withData';
import { compileAngular, getAngularService } from '../../../../services/AngularService';
History.propTypes = {
    history: PropTypes.array,
    importDetails: PropTypes.shape({
        cycles: PropTypes.array,
        entries: PropTypes.array,
        state: PropTypes.string,
    }),
};
export default withData({
    resolve: {
        history: ({ apiImport, companyId }) => apiImport.getHistory(companyId),
        _components: () => Promise.all([
            getAngularService(() => import(
            /* webpackChunkName: 'AdminUserImportPage' */ '../../../../../screens/admin/user-import/logs-table/logs-table-directive')),
            getAngularService(() => import(
            /* webpackChunkName: 'AdminUserImportPage' */ '../../../../../screens/admin/user-import/import-table/import-table-directive')),
        ]),
    },
    pendingComponent: () => _jsx(LoadingBar, { label: "Loading history\u2026" }),
})(History);
function History({ apiImport, history, name }) {
    const [importDetails, setImportDetails] = useState(null);
    const showImportDetails = useCallback(id => apiImport.getHistoryDetails(id).then(setImportDetails), [apiImport]);
    const hideImportDetails = useCallback(() => setImportDetails(null), []);
    return (_jsxs("div", { children: [_jsx(ImportDetails, { importDetails: importDetails, hideImportDetails: hideImportDetails, name: name }), _jsx(HistoryTable, { history: history, showImportDetails: showImportDetails, name: name })] }));
}
function HistoryTable({ history, showImportDetails, name }) {
    const template = `<logs-table name="${name}"
        logs="$ctrl.history"
        load-details="$ctrl.showImportDetails">
    </logs-table>`;
    return compileAngular(template, { history, showImportDetails });
}
function ImportDetails({ importDetails, hideImportDetails, name }) {
    return (_jsx(Modal, { isOpen: !!importDetails, onClose: hideImportDetails, topAligned: true, children: _jsx(DetailsTable, { importDetails: importDetails, name: name }) }));
}
function DetailsTable({ importDetails, name }) {
    const template = `<import-table name="${name}"
        import-result="$ctrl.importDetails"
        is-log="true">
    </import-table>`;
    return compileAngular(template, { importDetails });
}
