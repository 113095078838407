import singleton from '../../../common/models/popup-alert/singleton';
const DEFAULT_DURATION = 7000;
export const SHORT_DURATION = 4000;
let timeoutId;
export default function triggerPopupAlert(reactElement, type, options = {}) {
    const { timeoutMs = DEFAULT_DURATION, surviveNavigation } = options;
    window.clearTimeout(timeoutId);
    singleton.setMessage(reactElement, type, surviveNavigation);
    if (timeoutMs) {
        timeoutId = window.setTimeout(() => {
            singleton.dismiss();
        }, timeoutMs);
    }
}
