import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@emotion/css';
import vars from '../../../../../vars';
import { FormCheckbox, FormToggle } from '../../../../components/Form/FormInputs';
import { FormItem } from '../../../../components/Form/FormItem';
const toggleMargin = css({
    marginTop: `-${vars.standardpadding}`,
    marginBottom: `${vars.standardpadding}`,
    label: 'toggle-margin',
});
export default function ConfigWithCaption({ name, caption, label, isDisabled, isToggle = false }) {
    return (_jsxs("section", { children: [_jsx("div", { children: _jsx(FormItem, { name: name, type: "checkbox", label: label, inputComponent: isToggle ? FormToggle : FormCheckbox, inputProps: isToggle
                        ? {
                            disabled: isDisabled,
                            wrapperClassName: toggleMargin,
                        }
                        : {
                            disabled: isDisabled,
                        } }) }), _jsx("aside", { className: isToggle ? toggleMargin : undefined, children: caption })] }));
}
