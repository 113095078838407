import { flatten, groupBy, mapValues } from 'lodash';
import replacement from '../../../react/services/Replacement';
import * as R from '../../../routes';
import { getAdminRouteForNavigation } from '../../../routes-pairs';
import { getCurrentUser } from '../CurrentUser';
import { interpolateAppUrl } from '../Url';
import { hasBasicHr, isManager } from '../User';
export function getPages(mode, items) {
    if (mode === 'SEARCH') {
        return getPagesByItems(items);
    }
    else {
        return getPagesByMode(mode);
    }
}
function getPagesByItems(items) {
    if (items.length === 0) {
        const user = getCurrentUser();
        const showCycles = hasBasicHr(user) || isManager(user);
        return flatten([getTeamPages(), showCycles ? getCyclePages() : []]);
    }
    let distribution = mapValues(groupBy(items, x => x.value.type), x => x.length);
    const pages = [];
    if (distribution.OBJECTIVE > 0) {
        pages.push(getObjectivePages());
    }
    if (distribution.FEEDBACK > 0) {
        pages.push(getFeedbackPages());
    }
    if (distribution.REVIEW > 0) {
        pages.push(getReviewPages());
    }
    if (distribution.USER > 0) {
        pages.push(getTeamPages());
    }
    return flatten(pages);
}
function getPagesByMode(mode) {
    return {
        TEAM: getTeamPages(),
        CYCLE: getCyclePages(),
    }[mode];
}
function getTeamPages() {
    return [
        {
            type: 'PAGE',
            value: {
                name: `${replacement('company.big')} Directory`,
                url: interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_USERS), {}),
            },
        },
    ];
}
function getCyclePages() {
    return flatten([getObjectivePages(), getReviewPages(), getFeedbackPages()]);
}
function getObjectivePages() {
    if (!getCurrentUser().company.enabledFeatures.objective) {
        return [];
    }
    return [
        {
            type: 'PAGE',
            value: getObjectiveOverview(getCurrentUser()),
        },
    ];
}
function getReviewPages() {
    if (!getCurrentUser().company.enabledFeatures.review) {
        return [];
    }
    return [
        {
            type: 'PAGE',
            value: getReviewOverview(getCurrentUser()),
        },
    ];
}
function getFeedbackPages() {
    if (!getCurrentUser().company.enabledFeatures.feedback) {
        return [];
    }
    return [
        {
            type: 'PAGE',
            value: getFeedbackOverview(getCurrentUser()),
        },
    ];
}
function getObjectiveOverview(user) {
    if (hasBasicHr(user)) {
        return {
            name: replacement('objective.singular.big') + ' Cycles',
            url: interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_OBJECTIVES_SELECT_CYCLE), {}),
        };
    }
    else if (isManager(user)) {
        return {
            name: replacement('objective.singular.big') + ' Team Overview',
            url: interpolateAppUrl(R.YOUR_TEAM_OBJECTIVES_SELECT_CYCLE, {}),
        };
    }
    else {
        return {
            name: replacement('objective.singular.big') + ' Browser',
            url: interpolateAppUrl(R.YOUR_TEAM_OBJECTIVES_BROWSER, {}),
        };
    }
}
function getReviewOverview(user) {
    if (hasBasicHr(user)) {
        return {
            name: replacement('pr.singular.short') + ' Cycles',
            url: interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_REVIEWS_SELECT_CYCLE), {}),
        };
    }
    else {
        return {
            name: replacement('pr.singular.short') + ' Team Overview',
            url: interpolateAppUrl(R.YOUR_TEAM_REVIEWS_SELECT_CYCLE, {}),
        };
    }
}
function getFeedbackOverview(user) {
    if (hasBasicHr(user)) {
        return {
            name: replacement('fr.short.big') + ' Cycles',
            url: interpolateAppUrl(getAdminRouteForNavigation(R.YOUR_COMPANY_FEEDBACK_SELECT_CYCLE), {}),
        };
    }
    else {
        return {
            name: replacement('fr.short.big') + ' Team Overview',
            url: interpolateAppUrl(R.YOUR_TEAM_FEEDBACK_SELECT_CYCLE, {}),
        };
    }
}
