export var PaymentDocType;
(function (PaymentDocType) {
    PaymentDocType["CREDIT_NOTE"] = "CREDIT_NOTE";
    PaymentDocType["INVOICE"] = "INVOICE";
})(PaymentDocType || (PaymentDocType = {}));
export var PaymentDocStatus;
(function (PaymentDocStatus) {
    // Invoice
    PaymentDocStatus["PAID"] = "PAID";
    PaymentDocStatus["POSTED"] = "POSTED";
    PaymentDocStatus["PAYMENT_DUE"] = "PAYMENT_DUE";
    PaymentDocStatus["NOT_PAID"] = "NOT_PAID";
    PaymentDocStatus["VOIDED"] = "VOIDED";
    PaymentDocStatus["PENDING"] = "PENDING";
    // Credit Note
    PaymentDocStatus["ADJUSTED"] = "ADJUSTED";
    PaymentDocStatus["REFUNDED"] = "REFUNDED";
    PaymentDocStatus["REFUND_DUE"] = "REFUNDED_DUE";
    PaymentDocStatus["_UNKNOWN"] = "_UNKNOWN";
})(PaymentDocStatus || (PaymentDocStatus = {}));
