import { trackEvent } from '../Tracking';
export const initNavattic = (user) => {
    window.navatticQueue = [];
    window.navattic = new Proxy({
        identify: () => { },
        onEvent: () => { },
    }, {
        get: function (target, property) {
            return function (...args) {
                var _a;
                (_a = window.navatticQueue) === null || _a === void 0 ? void 0 : _a.push({ function: property, arguments: args });
            };
        },
    });
    window.navattic.identify({
        'user.fullName': user.firstName,
        'user.email': user.email,
    });
};
export const navatticOnEvent = (callback) => {
    window.navattic.onEvent(function (eventData) {
        if (eventData.event === 'COMPLETE_FLOW' && eventData.step.indx !== 12) {
            trackEvent({
                category: 'NAVATTIC_TUTORIAL',
                action: 'leave navattic tutorial',
                label: 'last step before droping out',
                value: eventData.step.indx,
            });
            callback();
        }
        if (eventData.event === 'CONVERTED') {
            trackEvent({
                category: 'NAVATTIC_TUTORIAL',
                action: 'finish navattic tutorial',
                label: 'completed navattic tutorial until end',
                value: eventData.step.indx,
            });
            callback();
        }
    });
};
