// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingIndicator-fullHead-aIgKd {\n  stroke-dasharray: 25.09327698;\n  stroke-dashoffset: 25.09327698;\n  animation: LoadingIndicator-standardPath-36vKl 1s ease-in 0.5s forwards;\n}\n.LoadingIndicator-fullBody-x6K90 {\n  stroke-dasharray: 23.56021881;\n  stroke-dashoffset: 0;\n  animation: LoadingIndicator-fullBodyPath-1EH9M 1s ease-in;\n}\n.LoadingIndicator-halfHead-2G2iR {\n  stroke-dasharray: 13.87116623;\n  stroke-dashoffset: 13.87116623;\n  animation: LoadingIndicator-standardPath-36vKl 0.5s ease-in 1.25s forwards;\n}\n.LoadingIndicator-halfBody-1NbPD {\n  stroke-dasharray: 9.28011227;\n  stroke-dashoffset: -9.28011227;\n  animation: LoadingIndicator-standardPath-36vKl 0.5s ease-in 1.5s forwards;\n}\n@keyframes LoadingIndicator-standardPath-36vKl {\n  100% {\n    stroke-dashoffset: 0;\n  }\n}\n@keyframes LoadingIndicator-fullBodyPath-1EH9M {\n  0% {\n    stroke-dashoffset: -23.56021881;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"fullHead": "LoadingIndicator-fullHead-aIgKd",
	"standardPath": "LoadingIndicator-standardPath-36vKl",
	"fullBody": "LoadingIndicator-fullBody-x6K90",
	"fullBodyPath": "LoadingIndicator-fullBodyPath-1EH9M",
	"halfHead": "LoadingIndicator-halfHead-2G2iR",
	"halfBody": "LoadingIndicator-halfBody-1NbPD"
};
module.exports = exports;
