import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconCheck } from 'featherico';
import PropTypes from 'prop-types';
import * as React from 'react';
import Button, { buttonPropTypes } from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import styles from './style.m.less';
export const DISABLED = 'DISABLED';
export const ENABLED = 'ENABLED';
export const SAVING = 'SAVING';
export const SAVED = 'SAVED';
const DEFAULT_LABELS = {
    DISABLED: 'Save',
    ENABLED: 'Save',
    SAVING: 'Saving',
    SAVED: 'Saved',
};
const STATES = Object.keys(DEFAULT_LABELS);
const LABEL_PREFIXES = {
    DISABLED: null,
    ENABLED: null,
    SAVING: _jsx(LoadingIndicator, {}),
    SAVED: _jsx(IconCheck, {}),
};
const SaveButton = props => {
    const { state = ENABLED, labels, onClick, className, kind, disabled, tooltip, tooltipOptions, type = 'submit' } = props, otherProps = __rest(props, ["state", "labels", "onClick", "className", "kind", "disabled", "tooltip", "tooltipOptions", "type"]);
    const mergedLabels = Object.assign(Object.assign({}, DEFAULT_LABELS), labels);
    const isEnabled = state === ENABLED;
    const defaultKind = isEnabled ? 'PRIMARY' : 'DEFAULT';
    const buttonProps = Object.assign({ kind: kind || defaultKind, disabled: !isEnabled || disabled, onClick: isEnabled ? onClick : undefined, 'data-tooltip': tooltip, 'data-tooltip-options': tooltipOptions, className: classNames(className, { [styles.saved]: state === SAVED }), type }, otherProps);
    return (_jsxs(Button, Object.assign({}, buttonProps, { children: [LABEL_PREFIXES[state], " ", mergedLabels[state]] })));
};
export default SaveButton;
SaveButton.propTypes = Object.assign(Object.assign({}, buttonPropTypes), { state: PropTypes.oneOf(STATES), 
    // labels: PropTypes.object,
    tooltip: PropTypes.string, tooltipOptions: PropTypes.string });
