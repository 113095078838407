import moment from 'moment';
import QUESTION_TEMPLATES from '../../../../../screens/feedback/constants/question-templates';
import { getDefaultExplanationFeedback, getDefaultName, getDefaultTimelineFeedback, } from '../../../../components/CycleCreate/services';
import { getCurrentUser } from '../../../../services/CurrentUser';
import SECTION_TYPES from '../../../FeedbackBouquet/constants/sectionTypes';
import { generateSectionId } from '../../../FeedbackBouquet/services/section';
import { REVIEWEE_NOMINATION, REVIEWEE_NOMINATION_WITHOUT_APPROVAL } from '../../constants/nomination-process';
export function getCreateModel(allUsers, emailTemplates, hrUsers, hasTemplates) {
    const currentUser = getCurrentUser();
    const { name } = getInfoDefault();
    const timeline = getDefaultTimelineFeedback(currentUser);
    const sections = hasTemplates ? [] : getSectionsDefault();
    const explanation = getDefaultExplanationFeedback();
    return {
        allUsers,
        hrUsers,
        name,
        explanation,
        timeline,
        sections,
        automations: {
            autoNudgeRevieweesToNominate: {
                enabled: false,
                weekday: 'MO',
                hourInCompanyTimeZone: 10,
            },
            autoNudgeReviewersToProvideFeedback: {
                enabled: false,
                weekday: 'MO',
                hourInCompanyTimeZone: 10,
            },
        },
        emailTemplates,
        scheduleInformOnCycleStart: false,
        automaticallyRemindManagersToApprove: false,
    };
}
export const canSetScheduleInformOnCycleStart = (now, model) => moment(model.timeline.processStarts).isAfter(now) &&
    [REVIEWEE_NOMINATION, REVIEWEE_NOMINATION_WITHOUT_APPROVAL].includes(model.nomination);
export const getScheduleInformOnCycleStart = (now, model) => canSetScheduleInformOnCycleStart(now, model) && model.scheduleInformOnCycleStart;
function getSectionsDefault() {
    return [
        {
            id: generateSectionId(),
            type: SECTION_TYPES.QUESTION,
            question: QUESTION_TEMPLATES[0],
        },
        {
            id: generateSectionId(),
            type: SECTION_TYPES.QUESTION,
            question: QUESTION_TEMPLATES[1],
        },
    ];
}
function getInfoDefault() {
    const id = Math.floor(Math.random() * 1000).toString();
    const name = getDefaultName('feedback');
    return { id, name };
}
