// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RoutesWrapper-content-22aS7 {\n  opacity: 0;\n}\n.RoutesWrapper-zenWrapper-S3Rpl {\n  transition: opacity 0.3s;\n  position: absolute;\n  left: 0;\n  right: 0;\n  min-height: 100vh;\n  z-index: 601;\n  background-color: white;\n}\n.RoutesWrapper-zenWrapperHidden-3xnwe {\n  opacity: 0;\n}\n.RoutesWrapper-zenWrapperEntering-3_Mbm,\n.RoutesWrapper-zenWrapperEntered-3TNPA {\n  opacity: 1;\n}\n.RoutesWrapper-zenWrapperEntered-3TNPA .RoutesWrapper-content-22aS7 {\n  animation: RoutesWrapper-fadeIn-1QCQh 0.5s 0.2s forwards;\n}\n.RoutesWrapper-mainWrapperHidden-3qJA8 {\n  visibility: hidden;\n}\n@keyframes RoutesWrapper-fadeIn-1QCQh {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"content": "RoutesWrapper-content-22aS7",
	"zenWrapper": "RoutesWrapper-zenWrapper-S3Rpl",
	"zenWrapperHidden": "RoutesWrapper-zenWrapperHidden-3xnwe",
	"zenWrapperEntering": "RoutesWrapper-zenWrapperEntering-3_Mbm",
	"zenWrapperEntered": "RoutesWrapper-zenWrapperEntered-3TNPA",
	"fadeIn": "RoutesWrapper-fadeIn-1QCQh",
	"mainWrapperHidden": "RoutesWrapper-mainWrapperHidden-3qJA8"
};
module.exports = exports;
