// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./notFound.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./accessDenied.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./internalError.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".RouteError-background-48cYv {\n}\n.RouteError-background-48cYv:before {\n  position: fixed;\n  content: '';\n  left: 0;\n  bottom: 0;\n  right: 0;\n  top: 0;\n  background: no-repeat center bottom #faf9f7;\n  background-size: auto 50%;\n}\n.RouteError-notFound-1MNr1 {\n}\n.RouteError-notFound-1MNr1:before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-color: #cceaff;\n}\n.RouteError-accessDenied-1wDlU {\n}\n.RouteError-accessDenied-1wDlU:before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-color: #ff8269;\n}\n.RouteError-internalError-2qxMq {\n}\n.RouteError-internalError-2qxMq:before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-color: #132745;\n}\n.RouteError-card-oielK {\n  max-width: 650px;\n  margin: 100px auto;\n}\n", ""]);
// Exports
exports.locals = {
	"background": "RouteError-background-48cYv route-error-background",
	"notFound": "RouteError-notFound-1MNr1 RouteError-background-48cYv route-error-background",
	"accessDenied": "RouteError-accessDenied-1wDlU RouteError-background-48cYv route-error-background",
	"internalError": "RouteError-internalError-2qxMq RouteError-background-48cYv route-error-background",
	"card": "RouteError-card-oielK"
};
module.exports = exports;
