// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ToTopButton-button-3hwJV {\n  position: fixed;\n  left: 50%;\n  width: 90px;\n  margin-left: -45px;\n  transition: transform 450ms ease-in, opacity 250ms ease-in;\n  background-color: #66b6ff;\n  transform: translate3d(0, -30px, 0);\n  top: 0;\n  z-index: 1;\n  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);\n  border-radius: 20px;\n  padding: 6px 0;\n  text-align: center;\n  font-size: 13px;\n  color: #ffffff;\n  border: 1px solid rgba(0, 0, 0, 0);\n  cursor: pointer;\n  opacity: 0;\n  --fi-size: 14px;\n}\n@media (min-width: 650px) {\n  .ToTopButton-button-3hwJV:hover {\n    background-color: #4caaff;\n  }\n}\n.ToTopButton-si-content-width-min-m-2qpDI .ToTopButton-button-3hwJV:hover {\n  background-color: #4caaff;\n}\n.ToTopButton-button-3hwJV:active,\n.ToTopButton-button-3hwJV:focus {\n  outline: none;\n  box-shadow: 0 0 0 1px #0071d4;\n}\n.ToTopButton-visible-O8reE {\n  transform: translate3d(0, 60px, 0);\n  opacity: 1;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "ToTopButton-button-3hwJV",
	"si-content-width-min-m": "ToTopButton-si-content-width-min-m-2qpDI",
	"visible": "ToTopButton-visible-O8reE"
};
module.exports = exports;
