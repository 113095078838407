import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '../../../components/Button';
import DeprecatedButtonContainer from '../../../components/ButtonContainer';
import Modal from '../../../components/Modal';
import { goToUrl } from '../../../services/Router';
export default function UserLoggedOutModal(props) {
    const modalProps = {
        onClose: props.onClose,
        isOpen: props.isOpen,
        title: 'Please log in again',
        size: 'SM_WIDTH',
    };
    const goToLoginPage = () => {
        // Redirect to app home, or to current page if we know it!
        if (window.location.pathname.indexOf('wicket:interface=') !== -1) {
            goToUrl('/app/home');
        }
        else {
            window.location.reload();
        }
    };
    return (_jsxs(Modal, Object.assign({}, modalProps, { children: ["You\u2019ve been logged out. Please make a copy of unsaved content before you leave this page or log in again.", _jsxs(DeprecatedButtonContainer, { children: [_jsx(Button, { onClick: () => props.onClose(), children: "Close to copy content" }), _jsx(Button, { kind: "PRIMARY", onClick: goToLoginPage, children: "Go to login page" })] })] })));
}
