import api from '../../../../api';
import { constObservable } from '../../../../services/observables';
import { useAppContext } from '../../../hooks/useAppContext';
import { useObservable } from '../../../hooks/useObservable';
import { isFeatureEnabled } from '../../../services/Features';
import { isAssistant, isHr } from '../../../services/User';
const isHrForSurveys = (user) => isHr(user) || isAssistant(user);
export function useShowSurveysTab() {
    const { user } = useAppContext();
    // If a user could possibly have had a survey shared with them and they're not HR
    // then we load their surveys so that we can decide if any surveys are visible.
    const shouldLoadSharedSurveys = !isHrForSurveys(user) && isFeatureEnabled('surveys');
    const { value: sharedSurveys } = useObservable(() => (shouldLoadSharedSurveys ? api.surveys.getAll.createObservable() : constObservable([])), [shouldLoadSharedSurveys]);
    const ishHrForSurveys = isFeatureEnabled('surveys') && isHrForSurveys(user);
    const hasSharedSurveys = isFeatureEnabled('surveys') && sharedSurveys && sharedSurveys.length > 0;
    return !ishHrForSurveys && hasSharedSurveys;
}
