import { get, set } from 'lodash/fp';
export const create = (companySettings, dateTimeSettings, buttonState) => ({
    companySettings,
    dateTimeSettings,
    buttonState,
});
export const getCompanySettingsAdvanced = get('companySettings');
export const getName = get('companySettings.name');
export const setName = set('companySettings.name');
export const getSubdomain = get('companySettings.subdomain');
export const setSubdomain = set('companySettings.subdomain');
export const getGrantSiImpersonation = get('companySettings.grantSiImpersonation');
export const setGrantSiImpersonation = set('companySettings.grantSiImpersonation');
export const getGrantSiImpersonationUntil = get('companySettings.grantSiImpersonationUntil');
export const setGrantSiImpersonationUntil = set('companySettings.grantSiImpersonationUntil');
export const getAllowExternalServices = get('companySettings.euAllowExternalServices');
export const setAllowExternalServices = set('companySettings.euAllowExternalServices');
export const getNotifyReviewersEveryNHours = get('companySettings.notifyReviewersEveryNHours');
export const setNotifyReviewersEveryNHours = set('companySettings.notifyReviewersEveryNHours');
// Date Time Settings
export const getDateTimeSettings = get('dateTimeSettings');
// Timezones
export const getSelectedTimezone = get('dateTimeSettings.selectedTimeZone');
export const setSelectedTimezone = set('dateTimeSettings.selectedTimeZone');
export const getAllTimezones = get('dateTimeSettings.allTimeZones');
export const getPreferredTimezones = get('dateTimeSettings.preferredTimeZones');
// Timeformats
export const getSelectedTimeFormat = get('dateTimeSettings.selectedTimeFormat');
export const setSelectedTimeFormat = set('dateTimeSettings.selectedTimeFormat');
export const getSelectedDateFormat = get('dateTimeSettings.selectedDateFormat');
export const setSelectedDateFormat = set('dateTimeSettings.selectedDateFormat');
export const getTimeFormats = get('dateTimeSettings.availableTimeFormats');
export const getDateFormats = get('dateTimeSettings.availableDateFormats');
export const getDateTimeFormat = (model) => getSelectedDateFormat(model).format + ' ' + getSelectedTimeFormat(model).format;
export const is12hrFormat = (model) => getSelectedTimeFormat(model).format.endsWith('a');
// Minute Step Size
export const getMinuteStepSize = get('dateTimeSettings.minuteStepSize');
export const setMinuteStepSize = set('dateTimeSettings.minuteStepSize');
// End of Business
export const getEndOfBusinessHour = get('dateTimeSettings.endOfBusinessHour');
export const setEndOfBusinessHour = set('dateTimeSettings.endOfBusinessHour');
// Start of Business
export const getStartOfBusinessHour = get('dateTimeSettings.startOfBusinessHour');
export const setStartOfBusinessHour = set('dateTimeSettings.startOfBusinessHour');
// Button State
export const getButtonState = get('companySettings.buttonState');
export const setButtonState = set('companySettings.buttonState');
