import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { useCallback } from 'react';
import api from '../../../../../api';
import { YOUR_COMPANY_FEEDBACK_SELECT_CYCLE } from '../../../../../routes';
import { getAdminRouteForNavigation } from '../../../../../routes-pairs';
import RouteError from '../../../../../routing/RouteError/index';
import { handleApiErrors, withData } from '../../../../hoc/withData';
import { goToUrl } from '../../../../services/Router';
import { prependAppPath } from '../../../../services/Url';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { individualizedErrors } from '../FeedbackProvide';
const DEFAULT_CLOSE_PATH = prependAppPath(getAdminRouteForNavigation(YOUR_COMPANY_FEEDBACK_SELECT_CYCLE));
function FeedbackPreviewPage({ component: CycleFeedbackProvidePreviewFromRequest, prevPath, response, }) {
    const onCloseRedirect = useCallback(() => {
        goToUrl(prevPath || DEFAULT_CLOSE_PATH);
    }, [prevPath]);
    return (_jsx(CycleFeedbackProvidePreviewFromRequest, { canSeeEditButton: true, onClose: onCloseRedirect, cycleCallback: () => Promise.resolve(response) }));
}
const errorComponent = handleApiErrors(individualizedErrors, RouteError);
export default withData({
    observe: {
        response: ({ cycleId }) => api.feedbackCycles.getCyclePreview.createObservable(cycleId),
    },
    resolve: {
        component: () => import(/* webpackChunkName: 'FeedbackProvideZen' */ '../../components/Preview').then(({ CycleFeedbackProvidePreviewFromRequest }) => CycleFeedbackProvidePreviewFromRequest),
    },
    pendingComponent: LoadingIndicator,
    errorComponent,
})(FeedbackPreviewPage);
