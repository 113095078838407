import { castArray, compact, filter, find, flatMap, forEach, groupBy, keyBy, map, some } from 'lodash/fp';
function managersOf(users, usersToExpand) {
    usersToExpand = castArray(usersToExpand);
    const forOneUser = (user) => findUser(users, user.managerId);
    return compact(map(forOneUser, usersToExpand));
}
function managementChainOfId(users, userId) {
    const user = findUser(users, userId);
    return user ? managementChainOf(users, user) : [];
}
function managementChainOf(users, user) {
    const forOneUser = (u) => {
        const manager = findUser(users, u.managerId);
        if (manager) {
            return [manager].concat(managementChainOf(users, manager));
        }
        else {
            return [];
        }
    };
    if (Array.isArray(user)) {
        return map(forOneUser, user);
    }
    else {
        return forOneUser(user);
    }
}
function findUser(users, id) {
    return find(u => u.id === id, users);
}
function directReportsOf(users, usersToExpand) {
    usersToExpand = castArray(usersToExpand);
    const forOneUser = (manager) => filter(({ managerId }) => managerId === manager.id, users);
    return flatMap(forOneUser, usersToExpand);
}
function indirectReportsOf(users, usersToExpand) {
    usersToExpand = castArray(usersToExpand);
    const usersByManagerId = groupBy('managerId', users);
    const trees = map(buildSubtree.bind(null, users, usersByManagerId, undefined), usersToExpand);
    return map((tree) => tree.user, flatMap(flattenTree, flatMap((tree) => tree.children, trees)));
}
function buildSubtree(users, usersByManagerId, maxLevel, initialUser) {
    if (maxLevel !== undefined) {
        maxLevel -= 1;
    }
    const user = users.find(u => u.id === initialUser.id);
    if (maxLevel !== undefined && maxLevel <= 0) {
        return { user, children: [] };
    }
    else {
        const children = map(buildSubtree.bind(null, users, usersByManagerId, maxLevel), usersByManagerId[user.id]);
        const parent = { user, children: [] };
        parent.children = map(child => (Object.assign(Object.assign({}, child), { parent })), children);
        return parent;
    }
}
function buildTree(users, rootManager, maxLevel) {
    const usersByManagerId = groupBy('managerId', users);
    return buildSubtree(users, usersByManagerId, maxLevel, rootManager);
}
function flattenTree(manager) {
    return [manager].concat(flatMap(flattenTree, manager.children));
}
function buildTreesFromUsers(users) {
    const userExists = (id) => users.some(user => user.id === id);
    const rootUsers = filter(user => !userExists(user.managerId), users);
    return map(user => buildTree(users, user), rootUsers);
}
function sortTreesByUser(compare, trees) {
    trees.sort((a, b) => compare(a.user, b.user));
    forEach(tree => sortTreesByUser(compare, tree.children), trees);
}
function buildTeamsFromUsers(users) {
    const isManager = (user) => some({ managerId: user.id }, users);
    const managers = filter(user => isManager(user), users);
    return flatMap(manager => {
        const children = filter(user => user.managerId === manager.id, users);
        const childrenObjects = map(child => ({ user: child, children: [] }), children);
        return [{ user: manager, children: childrenObjects }];
    }, managers);
}
function groupManagersAndNonManagers(users) {
    const userMap = keyBy(user => user.id, users);
    const nonManagers = new Set(users);
    const managers = new Set();
    users.forEach(user => {
        if (!user.managerId) {
            return;
        }
        const manager = userMap[user.managerId];
        if (!manager) {
            return;
        }
        nonManagers.delete(manager);
        managers.add(manager);
    });
    return {
        managers: [...managers],
        nonManagers: [...nonManagers],
    };
}
export { buildTeamsFromUsers, buildTree, buildTreesFromUsers, directReportsOf, flattenTree, groupManagersAndNonManagers, indirectReportsOf, managementChainOf, managementChainOfId, managersOf, sortTreesByUser, };
