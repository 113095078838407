import { jsx as _jsx } from "react/jsx-runtime";
import api from '../../../../../../api';
import { withData } from '../../../../../hoc/withData';
import ObjectiveCycleCreate from '../../lazy';
import { getCreateModel } from '../../services';
function ObjectiveCycleCreatePage({ allUsers }) {
    const model = getCreateModel(allUsers);
    return _jsx(ObjectiveCycleCreate, { mode: "create", model: model });
}
export default withData({
    resolve: {
        allUsers: () => api.usersMedium.getAll(),
    },
    pendingComponent: () => null,
})(ObjectiveCycleCreatePage);
