import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { debounce as debounceFn } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import InputError from './InputError';
import InputPrefix from './InputPrefix';
import styles from './style.m.less';
export default class Input extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.generateDebouncedHandler(props);
        this.state = { value: props.value };
        this.nativeInput = null;
    }
    onChange(event) {
        this.setState({ value: event.target.value });
        event.persist();
        this.debouncedChange(event);
    }
    generateDebouncedHandler(props) {
        const { onChange, debounce } = props;
        this.debouncedChange = debounce === undefined ? onChange : debounceFn(onChange, debounce);
    }
    UNSAFE_componentWillReceiveProps(props) {
        this.generateDebouncedHandler(props);
        this.setState({ value: props.value });
    }
    componentDidMount() {
        if (this.props.autoFocus) {
            setTimeout(() => (this.nativeInput ? this.nativeInput.focus() : null));
        }
    }
    render() {
        const { error, type = 'text', className, name, id, style, placeholder, onClick, onFocus, onBlur, onKeyDown, onKeyUp, getRef, required, disabled, min, max, minLength, maxLength, prefix, prefixSize, autoComplete, autoFocus, dataIntercomTarget, inputMode, } = this.props;
        const inputProps = {
            style: {
                paddingLeft: prefix && prefixSize ? Number(prefixSize) + 10 + 'px' : null,
            },
            value: this.state.value,
            name,
            id,
            onChange: this.onChange,
            onClick,
            onFocus,
            onBlur,
            onKeyDown,
            onKeyUp,
            placeholder,
            type,
            required,
            disabled,
            min,
            max,
            minLength,
            maxLength,
            'aria-labelledby': this.props['aria-labelledby'],
            'aria-label': this.props['aria-label'],
            'aria-invalid': error ? true : null,
            ref: node => {
                this.nativeInput = node;
                if (getRef) {
                    getRef(node);
                }
            },
            autoComplete,
            autoFocus,
            'data-intercom-target': dataIntercomTarget,
            inputMode,
        };
        return (_jsxs("div", { children: [_jsxs(InputWrapper, { className: className, hasError: !!error, style: style, children: [_jsx(InputPrefix, { prefix: prefix, prefixSize: prefixSize }), _jsx("input", Object.assign({}, inputProps))] }), _jsx(InputError, { error: error })] }));
    }
}
Input.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.any,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    prefix: PropTypes.string,
    prefixSize: PropTypes.number,
    autoComplete: PropTypes.string,
    inputMode: PropTypes.string,
};
export function InputWrapper({ className = '', hasError = false, children, style = undefined }) {
    return (_jsx("div", { className: classNames(styles.inputWrapper, className, 'react-input', {
            [styles.inputWrapperWithError]: hasError,
        }), style: style, children: children }));
}
