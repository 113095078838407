import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '../../Button';
import Link from '../../Link';
import NotificationsList from '../NotificationsList';
import NotificationsMarkAllAsReadButton from '../NotificationsMarkAllAsReadButton';
import styles from './style.m.less';
const NOTIFICATIONS_URL = '/app/notifications';
export default function NotificationsDropdown({ onCloseClick }) {
    return (_jsx("div", { className: styles.container, children: _jsxs("div", { className: styles.inner, children: [_jsxs("h1", { children: [_jsx("a", { href: NOTIFICATIONS_URL, onClick: onCloseClick, children: "Notifications" }), _jsxs("div", { className: styles.header_buttons, children: [_jsx(NotificationsMarkAllAsReadButton, {}), "\u00A0\u00B7\u00A0", _jsx(Button, { kind: "PLAIN", onClick: onCloseClick, eventTracking: { category: 'notifications', action: 'close' }, children: "Close" })] })] }), _jsx(NotificationsList, { isDropdown: true }), _jsx(EndOfList, { onClick: onCloseClick })] }) }));
}
function EndOfList({ onClick }) {
    return (_jsx(Link, { href: NOTIFICATIONS_URL, onClick: onClick, className: styles.see_all, eventTracking: { category: 'notifications', action: 'see all' }, children: "See all notifications" }));
}
