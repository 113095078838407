import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import LoadingIndicatorFull from '../react/components/LoadingIndicatorFull';
import { withInjector } from '../react/hoc/withInjector';
import AngularRouteRenderer from './AngularRouteRenderer';
import RouteError from './RouteError';
const AngularRouteFetcher = ({ $injector, match, stateGetter }) => {
    const { result, error } = useAsync(() => stateGetter().then(module => module.default), [stateGetter]);
    const state = useMemo(() => {
        if (!result) {
            return null;
        }
        $injector.loadNewModules(result.modules);
        return Object.assign(Object.assign({}, result.state), { url: match.path });
    }, [result, match.path, $injector]);
    if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return _jsx(RouteError, {});
    }
    if (state) {
        return _jsx(AngularRouteRenderer, { match: match, state: state, "$injector": $injector });
    }
    return _jsx(LoadingIndicatorFull, {});
};
export default withInjector(AngularRouteFetcher);
