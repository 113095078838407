import { jsx as _jsx } from "react/jsx-runtime";
import { IconBullhorn, IconMessageCircle, IconModule1On1, IconModule360, IconModuleObjectives, IconModulePraise, IconModuleReviews, IconQuestionScales, IconUserPlus, } from 'featherico';
export const ICON_BY_TYPE = {
    MESSAGE: IconMessageCircle,
    PRAISE: IconModulePraise,
    MEETING: IconModule1On1,
    REVIEW: IconModuleReviews,
    OBJECTIVE: IconModuleObjectives,
    FEEDBACK: IconModule360,
    USER: IconUserPlus,
    APP: IconBullhorn,
    PULSE: IconQuestionScales,
};
export default function ModuleIcon({ type }) {
    const Icon = ICON_BY_TYPE[type];
    return _jsx(Icon, {});
}
