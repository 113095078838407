import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCurrentUser } from '../../../services/CurrentUser';
import { getPages } from '../../../services/HeaderSearchBar';
import { scrollTo } from '../../../services/ScrollInList';
import { hasBasicHr, isManager } from '../../../services/User';
import Navigation from '../Navigation';
import PageList from '../PageList';
import ResultList from '../ResultList';
import styles from '../ResultList/style.m.less';
export default function Result({ mode, items, selectedItem, status, shouldShowLoader, doHideResults, doSelectItem, preventScroll, }) {
    const pages = getPages(mode, items);
    const totalNumberOfItems = items.length + pages.length;
    const shouldSeeNavigation = hasBasicHr(getCurrentUser()) || isManager(getCurrentUser());
    return (_jsxs("div", { className: 'header_search_bar-result ' + 'header_search_bar-result--' + mode, ref: scrollTo(10, 10, styles.itemSelected, selectedItem, totalNumberOfItems, !preventScroll), children: [mode !== 'SEARCH' && shouldSeeNavigation ? _jsx(Navigation, {}) : _jsx("noscript", {}), status === 'LOADED' || !shouldShowLoader ? (_jsx(ResultList, { items: items, doHideResults: hideAndClear, selectedItem: selectedItem, mode: mode, doSelectItem: doSelectItem, totalNumberOfItems: totalNumberOfItems })) : (_jsx("noscript", {})), (status === 'LOADED' || !shouldShowLoader) && pages.length > 0 ? (_jsx(PageList, { pages: pages, doHideResults: hideAndClear, doSelectItem: doSelectItem, selectedItem: selectedItem, offset: items.length, totalNumberOfItems: totalNumberOfItems })) : (_jsx("noscript", {})), status === 'LOADING' && shouldShowLoader ? (_jsx("small", { className: "header_search_bar-loading_text", children: "Loading..." })) : (_jsx("noscript", {}))] }));
    function hideAndClear() {
        doHideResults();
    }
}
