import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import replacement from '../../../services/Replacement';
import { PraiseBadgeIcon } from '../../PopupAlert/icons';
import EntityPreview from '../EntityPreview';
import { convertRichText } from '../MessageAlert';
import PopupAlertContent from '../PopupAlertContent';
export default function PraiseAlert({ praise }) {
    const title = `${replacement('praise.header')} Created`;
    const href = `/app/praise/${praise.id}`;
    const body = praise.message && convertRichText(praise.message);
    return (_jsx(PopupAlertContent, { title: title, href: href, icon: _jsx(PraiseBadgeIcon, { praise: praise }), children: _jsx(EntityPreview, { title: praise.title || body, subtitle: praise.title ? body : '' }) }));
}
PraiseAlert.propTypes = {
    praise: PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
    }).isRequired,
};
