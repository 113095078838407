import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { intercomContactExists } from '../../../../api/custom/Intercom';
import Link from '../../../components/Link';
import { isFeatureEnabled } from '../../../services/Features';
import { loadIntercom } from '../../../services/intercom';
const SUPPORT_LINK = 'mailto:help@small-improvements.com';
const IntercomSupportLink = ({ inHeader, onClick }) => {
    const handleClick = async (event) => {
        event.preventDefault();
        onClick === null || onClick === void 0 ? void 0 : onClick();
        const useIntercom = isFeatureEnabled('FEATURE_intercom') && (await intercomContactExists());
        if (useIntercom) {
            const intercom = await loadIntercom();
            intercom('showSpace', 'home');
        }
        else {
            // Use mail link as fallback.
            window.open(SUPPORT_LINK, '_blank', 'noopener');
        }
    };
    return (_jsx(Link, { onClick: handleClick, className: inHeader ? '' : 'main_navigation-footer-link', href: SUPPORT_LINK, title: "Click here to get in touch with customer support!", eventTracking: {
            category: inHeader ? 'navigation header help' : 'navigation footer',
            action: 'click',
            label: 'get help as admin: intercom',
        }, children: inHeader ? 'Get Help' : 'Need Support?' }));
};
export default IntercomSupportLink;
