// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GlobalCreate-globalCreate-2tFdv {\n  margin-right: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"globalCreate": "GlobalCreate-globalCreate-2tFdv app-header-button-content app-header-global-create"
};
module.exports = exports;
