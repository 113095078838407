// Creates an Observable object that publishes a constant value once.
export function constObservable(value) {
    return {
        subscribe: (publishHandler) => {
            publishHandler.apply(null, [value]);
            return {
                unsubscribe: () => undefined,
            };
        },
    };
}
export const mapObservable = (mapFn, observable) => ({
    subscribe: (publishHandler, errorHandler) => observable.subscribe(x => publishHandler(mapFn(x)), errorHandler),
});
