import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { isUndefined } from 'lodash/fp';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppContext } from '../../hooks/useAppContext';
import { trackEvent } from '../../services/Tracking';
import styles from './style.m.less';
export default function NavigationTabs({ tabs, className, trackingCategory }) {
    const appContext = useAppContext();
    return (_jsx("div", { className: classNames(styles.container, className), children: tabs.map(tab => (_jsx(NavigationTab, { tab: tab, user: appContext.user, trackingCategory: trackingCategory }, typeof tab.link === 'string' ? tab.link : tab.link.pathname))) }));
}
function NavigationTab({ tab, user, trackingCategory }) {
    const featureEnabled = !tab.feature || featureActive(tab.feature, user);
    const disabled = !(tab.enabled || tab.enabled === undefined);
    if (!tab.visible || (!featureEnabled && !isAnyAdmin(user))) {
        return null;
    }
    return (_jsx(NavLink, { to: tab.link, exact: tab.exact, className: classNames(styles.tabItem, {
            [styles.deactivated]: !featureEnabled,
            [styles.disabled]: disabled,
        }), isActive: isUndefined(tab.active) ? undefined : () => !!tab.active, activeClassName: styles.selected, tabIndex: disabled ? -1 : undefined, onClick: () => {
            if (trackingCategory) {
                trackEvent({ category: trackingCategory, action: 'toggle tab', label: tab.trackingLabel });
            }
        }, children: tab.label }));
}
function featureActive(feature, user) {
    return user.company.enabledFeatures[feature];
}
function isAnyAdmin(user) {
    const { isAdmin, isAssistant, isHR } = user;
    return isAdmin || isAssistant || isHR;
}
