import { connect } from 'react-redux';
import { actionCreators } from '../ducks';
import NotificationsMarkAllAsReadButton from './presenter';
function mapStateToProps() {
    return {};
}
function mapDispatchToProps(dispatch) {
    return {
        onClick: () => dispatch(actionCreators.doMarkAllAsRead()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMarkAllAsReadButton);
