import { jsx as _jsx } from "react/jsx-runtime";
import { useAppContext } from '../../hooks/useAppContext';
import { hasBasicHr, isFake } from '../../services/User';
import TestDriveMode from './components/TestDriveMode/lazy';
export const TestDriveModeContainer = () => {
    const { user } = useAppContext();
    if (!user.company.hasSampleUsers || !(hasBasicHr(user) || isFake(user))) {
        return null;
    }
    return _jsx(TestDriveMode, { currentUser: user });
};
