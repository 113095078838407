import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { startCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import * as React from 'react';
import { getInputProps } from '../../../services/Validation';
import { FormField } from '../FormField';
import { FormInputWithWrapper } from '../FormInputs';
import { FormLabel } from '../FormLabel';
import styles from './style.m.less';
export const FormItemSeparator = () => _jsx("hr", { className: styles.hr });
export const FormHeader = ({ children }) => (_jsxs(_Fragment, { children: [_jsx(FormItemSeparator, {}), _jsx("h2", { className: styles.header, children: children })] }));
export function metaToErrorMessage(meta, showError) {
    if (meta && meta.error && showError(meta)) {
        return meta.error;
    }
    const showSubmitError = meta && meta.submitError && !meta.dirtySinceLastSubmit;
    if (showSubmitError) {
        return meta.submitError;
    }
    return undefined;
}
export function FormItem({ name, id, type, label, subLabel, info, inputComponent: InputComponent, inputProps, validators, parse, format, allowNull, value, showError = meta => !!meta.touched, }) {
    const prettyLabel = label !== undefined ? label : startCase(name);
    const { required } = getInputProps(validators);
    const isSelectionElement = ['checkbox', 'radio'].includes(type);
    return (_jsx(FormField, { name: name, label: prettyLabel, validators: validators, parse: parse, format: format, value: value, type: type, allowNull: allowNull, children: ({ input, meta }) => (_jsxs(_Fragment, { children: [!isSelectionElement && prettyLabel && (_jsx(FormLabel, { htmlFor: id || name, subLabel: subLabel, info: info, children: prettyLabel })), _jsx(InputComponent, Object.assign({}, input, { name,
                    id: id || name,
                    label,
                    type,
                    required,
                    error: metaToErrorMessage(meta, showError) }, inputProps))] })) }));
}
FormItem.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    subLabel: PropTypes.node,
    info: PropTypes.string,
    type: PropTypes.string,
    inputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    inputProps: PropTypes.object,
    allowNull: PropTypes.bool,
    validators: PropTypes.arrayOf(PropTypes.shape({
        validate: PropTypes.func,
    })),
};
FormItem.defaultProps = {
    inputComponent: FormInputWithWrapper,
    type: 'text',
};
