// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HrList-container-2fNXB {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  align-items: center;\n}\n.HrList-avatar-3BP7w {\n  margin-right: 10px;\n}\n.HrList-user-2_s6X {\n  width: calc(50% - 20px / 2);\n  max-width: 50%;\n  margin-bottom: 20px;\n  display: flex;\n  align-items: center;\n}\n.HrList-nameAndRoleContainer-3j84B {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: baseline;\n}\n.HrList-name-vQtBJ {\n  font-weight: 500;\n  margin-right: 5px;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.HrList-role-1ZN3f {\n  font-size: 13px;\n  color: #7a7a79;\n  --fi-size: 14px;\n}\n.HrList-mail-3r6zj {\n  font-size: 13px;\n  word-break: break-word;\n  --fi-size: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "HrList-container-2fNXB",
	"avatar": "HrList-avatar-3BP7w",
	"user": "HrList-user-2_s6X",
	"nameAndRoleContainer": "HrList-nameAndRoleContainer-3j84B",
	"name": "HrList-name-vQtBJ",
	"role": "HrList-role-1ZN3f",
	"mail": "HrList-mail-3r6zj"
};
module.exports = exports;
