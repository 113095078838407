// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingIcon-container-1U8qy {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.LoadingIcon-icon-JHYt9 {\n  width: 70px;\n  height: 70px;\n  stroke-width: var(--fi-stroke-s);\n  color: #e0dfde;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "LoadingIcon-container-1U8qy",
	"icon": "LoadingIcon-icon-JHYt9"
};
module.exports = exports;
