import { extend, findIndex, reduce } from 'lodash';
import { getShortId } from '../../../../services/id';
export function applyAddSection(sections, section, idx) {
    return [...sections.slice(0, idx), section, ...sections.slice(idx)];
}
export function applyDuplicateSection(sections, section, idx) {
    const sectionWithOwnId = Object.assign(Object.assign({}, section), { id: generateSectionId() });
    return applyAddSection(sections, sectionWithOwnId, idx);
}
export function applyMoveSection(sections, fromPosition, toPosition) {
    const movingSection = sections[fromPosition];
    const displacedSection = sections[toPosition];
    return reduce(sections, (result, value, key) => {
        function getSectionOnMove() {
            switch (key) {
                case fromPosition:
                    return displacedSection;
                case toPosition:
                    return movingSection;
                default:
                    return value;
            }
        }
        result[key] = getSectionOnMove();
        return result;
    }, []);
}
export function applyChangeSection(sections, section, key) {
    const updatedSection = extend({}, sections[key], section);
    return [...sections.slice(0, key), updatedSection, ...sections.slice(key + 1)];
}
export function applyRemoveSection(sections, key) {
    return [...sections.slice(0, key), ...sections.slice(key + 1)];
}
export function applyAddSectionInEdit(sectionsInEditMode, sectionsInEditNeverSaved, id, neverSaved) {
    sectionsInEditMode = [...sectionsInEditMode, id];
    if (neverSaved) {
        sectionsInEditNeverSaved = [...sectionsInEditNeverSaved, id];
    }
    return { sectionsInEditMode, sectionsInEditNeverSaved };
}
export function applyRemoveSectionInEdit(sectionsInEditMode, sectionsInEditNeverSaved, sectionId) {
    const index = findIndex(sectionsInEditMode, id => id === sectionId);
    const indexNeverUsed = findIndex(sectionsInEditNeverSaved, id => id === sectionId);
    if (indexNeverUsed !== -1) {
        sectionsInEditNeverSaved = [
            ...sectionsInEditNeverSaved.slice(0, index),
            ...sectionsInEditNeverSaved.slice(index + 1),
        ];
    }
    sectionsInEditMode = [...sectionsInEditMode.slice(0, index), ...sectionsInEditMode.slice(index + 1)];
    return { sectionsInEditMode, sectionsInEditNeverSaved };
}
export function generateSectionId() {
    return getShortId();
}
