// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingIndicator-border-2YGM7 {\n  stroke-dasharray: 65;\n  stroke-dashoffset: 65;\n  animation: LoadingIndicator-buildPath-10qO- linear 0.4s 0.3s forwards;\n}\n.LoadingIndicator-latch-2xaEm {\n  stroke-dasharray: 12;\n  stroke-dashoffset: 12;\n  animation: LoadingIndicator-buildPath-10qO- 0.4s linear 0.7s forwards;\n}\n.LoadingIndicator-circle-1nSMl {\n  opacity: 0;\n  animation: LoadingIndicator-bounceCircle-15dxa 0.4s 1.1s forwards;\n}\n.LoadingIndicator-text-2aBTY {\n  stroke-dasharray: 8 8;\n  stroke-dashoffset: -8;\n  animation: LoadingIndicator-animateText-2qNmQ 1s linear 1.5s infinite forwards;\n}\n@keyframes LoadingIndicator-animateText-2qNmQ {\n  0% {\n    stroke-dashoffset: -8;\n  }\n  12%,\n  25% {\n    stroke-dashoffset: 0;\n  }\n  50% {\n    stroke-dashoffset: 8;\n  }\n  100% {\n    stroke-dashoffset: 8;\n  }\n}\n.LoadingIndicator-text1-_PVfZ {\n}\n.LoadingIndicator-text2-1fRoC {\n  animation-delay: 1.625s;\n}\n@keyframes LoadingIndicator-buildPath-10qO- {\n  100% {\n    stroke-dashoffset: 0;\n  }\n}\n@keyframes LoadingIndicator-bounceCircle-15dxa {\n  0% {\n    transform: translate(4px, -4px);\n  }\n  30% {\n    opacity: 1;\n  }\n  50% {\n    transform: translate(4px, 14px);\n  }\n  100% {\n    transform: translate(4px, 2px);\n    opacity: 1;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"border": "LoadingIndicator-border-2YGM7",
	"buildPath": "LoadingIndicator-buildPath-10qO-",
	"latch": "LoadingIndicator-latch-2xaEm",
	"circle": "LoadingIndicator-circle-1nSMl",
	"bounceCircle": "LoadingIndicator-bounceCircle-15dxa",
	"text": "LoadingIndicator-text-2aBTY",
	"animateText": "LoadingIndicator-animateText-2qNmQ",
	"text1": "LoadingIndicator-text1-_PVfZ LoadingIndicator-text-2aBTY",
	"text2": "LoadingIndicator-text2-1fRoC LoadingIndicator-text-2aBTY"
};
module.exports = exports;
