import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as React from 'react';
import Button from '../../Button';
import styles from './style.m.less';
export default function PopupAlertContent({ icon, title, href, onButtonClick, buttonLabel, children }) {
    return (_jsxs("section", { className: styles.outerFlexRow, children: [icon && _jsx("div", { className: styles.icon, children: icon }), _jsxs("div", { className: styles.content, children: [_jsx("h1", { className: styles.title, children: title }), children] }), _jsxs("div", { className: styles.info, children: [href ? (_jsx("a", { href: href, className: styles.button, "aria-hidden": true, children: buttonLabel || 'View' })) : null, onButtonClick ? (_jsx(Button, { kind: "PLAIN", onClick: onButtonClick, className: styles.button, "aria-hidden": true, children: buttonLabel || 'View' })) : null] })] }));
}
PopupAlertContent.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.node.isRequired,
    buttonLabel: PropTypes.string,
    href: PropTypes.string,
    onButtonClick: PropTypes.func,
    children: PropTypes.node.isRequired,
};
