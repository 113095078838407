import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
/** @jsxImportSource @emotion/react */
import { FORM_ERROR } from 'final-form';
import AlertBox from '../../../components/AlertBox';
import { Form } from '../../../components/Form';
import { FormErrorMessage } from '../../../components/Form/FormErrorMessage';
import { FormButtonContainer, FormCheckbox, FormCheckboxContainer, FormRichTextArea, FormSelect, } from '../../../components/Form/FormInputs';
import { FormItem } from '../../../components/Form/FormItem';
import { FormLabel } from '../../../components/Form/FormLabel';
import SaveButton, { DISABLED, ENABLED, SAVING } from '../../../components/SaveButton';
import r from '../../../services/Replacement';
const REPORT_FREQ_OPTIONS = [1, 2];
const frequencyToOptionName = (f) => {
    if (f === 1) {
        return 'once per week';
    }
    else {
        return 'once every two weeks';
    }
};
export default function AdminReportSubscriptionForm({ onSubmit }) {
    const handleSubmit = (form) => onSubmit(form).catch(() => ({
        [FORM_ERROR]: 'We were unable to subscribe you to this report. Please reload and try again.',
    }));
    const showDisclaimer = (form) => {
        return form.feedback || form.meetings || form.objectives;
    };
    return (_jsx(Form, { onSubmit: handleSubmit, initialValues: {
            feedback: false,
            objectives: false,
            reviews: true,
            meetings: false,
            reportFrequencyWeeks: 1,
            otherInfo: undefined,
        }, children: ({ submitting, invalid, submitError, values }) => (_jsxs("div", { children: [_jsx(FormLabel, { children: "Which features would you like to receive reporting about?" }), _jsxs(FormCheckboxContainer, { children: [_jsx(FormItem, { name: "reviews", type: "checkbox", label: r('pr.menu.title'), inputComponent: FormCheckbox }), _jsx(FormItem, { name: "feedback", type: "checkbox", label: r('fr.menu.title'), inputComponent: FormCheckbox }), _jsx(FormItem, { name: "objectives", type: "checkbox", label: r('objective.menu.title'), inputComponent: FormCheckbox }), _jsx(FormItem, { name: "meetings", type: "checkbox", label: r('meeting.name.plural'), inputComponent: FormCheckbox }), _jsx("div", { css: { visibility: showDisclaimer(values) ? 'visible' : 'hidden' }, children: _jsxs(AlertBox, { colour: "YELLOW", children: ["Disclaimer: in the early stage of this feature, it\u2019s only possible to provide you with reports on ", r('pr.menu.title'), ". Your feedback will help us expand this feature in the future."] }) })] }), _jsx(FormLabel, { children: "How frequently would you like to receive these reports?" }), _jsx(FormItem, { name: "reportFrequencyWeeks", label: "", inputComponent: FormSelect, inputProps: {
                        optionToText: frequencyToOptionName,
                        options: REPORT_FREQ_OPTIONS,
                    } }), _jsx(FormLabel, { children: "Would you or your team like to receive reports on other topics? If you have more details to share about how a specific report would help, we\u2019d love to hear more." }), _jsx(FormItem, { name: "otherInfo", label: "", subLabel: "(optional)", inputComponent: FormRichTextArea, inputProps: {
                        hideToolbar: true,
                        rows: 3,
                        disabled: submitting,
                        onSubmitThroughKeyboard: handleSubmit,
                    } }), _jsxs(FormButtonContainer, { children: [_jsx(SaveButton, { state: submitting ? SAVING : ENABLED, labels: {
                                [ENABLED]: `Subscribe to reports`,
                                [DISABLED]: `Subscribe to reports`,
                                [SAVING]: `Subscribing`,
                            }, disabled: invalid }), submitError ? _jsx(FormErrorMessage, { errorMessage: submitError }) : null] })] })) }));
}
