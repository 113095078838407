import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { IconChevronsRight } from 'featherico';
import { map } from 'lodash';
import { preventJavascriptUrls } from '../../../../services/security';
import { modulo } from '../../../services/Math';
import { trackEvent } from '../../../services/Tracking';
import styles from '../ResultList/style.m.less';
export default function PageList({ pages, selectedItem, offset, totalNumberOfItems, doHideResults, doSelectItem }) {
    return (_jsx("ul", { className: "nakedList header_search_bar-page_list", children: map(pages, (page, index) => (_jsx(PageItem, { page: page, itemIsSelected: isSelected(selectedItem, offset, totalNumberOfItems, index), doHideResults: () => {
                trackEvent({
                    category: 'navigation header search bar',
                    action: 'go to page',
                    label: page.value.name,
                });
                return doHideResults;
            }, doSelectItem: () => doSelectItem(index + offset, true) }, page.value.url))) }));
}
function isSelected(selectedItem, offset, totalNumberOfItems, index) {
    if (selectedItem === null) {
        return false;
    }
    return index + offset === modulo(selectedItem, totalNumberOfItems);
}
function PageItem({ page, doHideResults, itemIsSelected, doSelectItem }) {
    const itemClasses = classNames(styles.item, styles.itemSmall, {
        [styles.itemSelected]: itemIsSelected,
    });
    return (_jsx("li", { className: itemClasses, onMouseOver: doSelectItem, children: _jsxs("a", { tabIndex: "-1", href: preventJavascriptUrls(page.value.url), className: styles.linkPage, onClick: doHideResults, children: [_jsx(IconChevronsRight, {}), "\u00A0", page.value.name] }) }));
}
