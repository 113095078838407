export const CONFIRMED_WICKET_ROUTES = [
    '/deleteEntireCompany',
    '/mergeCompany',
    '/admin/console/editSystem',
    '/admin/console/editAppraisal',
    '/admin/console/editReviewCycle',
    '/admin/console/editUser',
    '/admin/console/feedbackCycleObserver',
    '/admin/console/requestFeedbackObserver',
    '/admin/console/anytimeFeedbackObserver',
    '/admin/console/reviewCycleObserver',
    '/admin/console/objectiveCycleObserver',
    '/admin/console/editObjectiveCycle',
    '/admin/console/userObserver',
    '/admin/console/userResourceObserver',
    '/admin/console/feedbackRequestObserver',
    '/admin/console/draftsObserver',
    '/admin/console/notificationsObserver',
    '/admin/settings/advanced',
    '/admin/integrations/saml',
    '/admin/security',
    '/admin/settings/usersettings',
    '/admin/progress',
    '/admin/events',
    '/admin/bounces',
    '/admin/feedback/edit',
    '/admin/email/edit',
    '/admin/email/view',
    '/editCompanyBillingBackend',
    '/editCompanyBackend',
    '/editCompanyBackendAdvanced',
    '/renameCompanyDomain',
    '/recentCompaniesObserver',
    '/companyObserver',
];
export const REGULAR_WICKET_ROUTES = [
    '/feedbackYouProvided',
    '/appraisalView',
    '/manageSessions',
    '/user-settings',
    '/backups',
    '/newUser',
    '/resetCompanyInProgress',
    '/vacuumSampleCompany',
    '/testEmail',
    '/testCredentials',
    '/guest',
    '/appraisalDetails',
    '/appraisal',
    '/editReviewPreview',
    '/wicket',
];
export default REGULAR_WICKET_ROUTES.concat(CONFIRMED_WICKET_ROUTES);
