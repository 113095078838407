import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import loadScript from '../../services/loadScript';
export default function BookConsultation() {
    var _a;
    const { user } = useAppContext();
    useEffect(() => {
        loadScript('https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js');
    }, []);
    const params = new URLSearchParams({
        embed: 'true',
        email: user.email,
        lastname: (_a = user.lastName) !== null && _a !== void 0 ? _a : '',
        firstname: user.firstName,
    });
    return (_jsx("div", { className: "meetings-iframe-container", "data-src": `https://meetings.hubspot.com/si-lsanders/15-min-discovery?${params}` }));
}
