import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { compose, setPropTypes } from 'recompose';
import api from '../../../../../api';
import RouteError from '../../../../../routing/RouteError';
import { mapProps, pure } from '../../../../hoc/recompose';
import { withData } from '../../../../hoc/withData';
import { getCurrentUser } from '../../../../services/CurrentUser';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getAuthorId } from '../../services/review';
import NoAssessment from './NoAssessment';
const errorComponent = ({ error }) => {
    if (!error) {
        return null;
    }
    switch (get('response.data.message', error)) {
        case 'REVIEWER_NOT_FOUND':
            return _jsx(NoAssessment, { adminsToContact: get('response.data.adminsToContact', error) });
        default:
            return _jsx(RouteError, { error: error });
    }
};
export default compose(setPropTypes({
    reviewId: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
}), withData({
    resolve: {
        review: ({ reviewId }) => api.reviews.getReview(reviewId),
    },
}), withData({
    resolve: {
        ownReviewerAssessment: ({ reviewId, review: { useIndividualAssessments }, location: { search } }) => {
            const { editAs } = parse(search);
            if (useIndividualAssessments && !editAs) {
                return api.reviewAssessments.createReviewerAssessmentForSelf(reviewId);
            }
            else {
                return Promise.resolve();
            }
        },
    },
}), withData({
    pendingComponent: LoadingIndicator,
    errorComponent: errorComponent,
    resolve: {
        component: () => import(/* webpackChunkName: 'ReviewsEditPage' */ './presenter'),
    },
    observe: {
        assessments: ({ reviewId }) => api.reviewAssessments.getAssessmentsForReview.createObservable(reviewId),
        review: ({ reviewId }) => api.reviews.getReview.createObservable(reviewId),
    },
}), mapProps(props => (Object.assign(Object.assign({}, props), { authorId: getAuthorId(props.location, props.review), isQuestionnairePreview: false, currentUser: getCurrentUser() }))), pure)((_a) => {
    var { component: { default: Component } } = _a, props = __rest(_a, ["component"]);
    return _jsx(Component, Object.assign({}, props));
});
