import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const propTypes = {
    value: PropTypes.any,
};
const contextTypes = {
    radioButtonContainer: PropTypes.object,
};
export const RadioButtonPlain = (_a) => {
    var { name, value, label, className = undefined, style = undefined, onChange } = _a, otherProps = __rest(_a, ["name", "value", "label", "className", "style", "onChange"]);
    const id = name + '-' + value;
    const inputProps = Object.assign(Object.assign({}, otherProps), { type: 'radio', name,
        value,
        id, onChange: event => onChange(event.target.value) });
    return label ? (_jsxs("div", { className: className, style: style, children: [_jsx("input", Object.assign({}, inputProps)), _jsx("label", { htmlFor: id, children: label })] })) : (_jsx("input", Object.assign({}, inputProps)));
};
export default function RadioButton(props, context) {
    const { name, selected, onChange } = context.radioButtonContainer;
    const { value, label, className, style } = props;
    const mergedProps = Object.assign(Object.assign({}, props), { name,
        value, checked: selected === value, onChange: () => onChange(value), label,
        className,
        style });
    return _jsx(RadioButtonPlain, Object.assign({}, mergedProps));
}
RadioButton.propTypes = propTypes;
RadioButton.contextTypes = contextTypes;
