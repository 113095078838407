// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PeopleTab-peopleTab-2h3UW {\n  margin-top: 20px;\n}\n.PeopleTab-userList-OvbY9 {\n  display: flex;\n  flex-wrap: wrap;\n}\n.PeopleTab-userItem-zgzBp {\n  display: flex;\n  flex-direction: column;\n  margin: 0 10px 20px 0;\n}\n.PeopleTab-userShortInfo-3qDI3 {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  width: 300px;\n}\n.PeopleTab-userImage-2SJ4F {\n  height: 60px;\n  margin: 0 10px 0 0;\n  width: 60px;\n  border-radius: 50%;\n  display: block;\n}\n.PeopleTab-userName-1JVAQ {\n  margin: 5px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"peopleTab": "PeopleTab-peopleTab-2h3UW",
	"userList": "PeopleTab-userList-OvbY9",
	"userItem": "PeopleTab-userItem-zgzBp",
	"userShortInfo": "PeopleTab-userShortInfo-3qDI3",
	"userImage": "PeopleTab-userImage-2SJ4F",
	"userName": "PeopleTab-userName-1JVAQ"
};
module.exports = exports;
