// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VerticalCheckboxes-verticalCheckboxes-xc7td {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"verticalCheckboxes": "VerticalCheckboxes-verticalCheckboxes-xc7td"
};
module.exports = exports;
