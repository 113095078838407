import { compose, filter, get, head, last, map, omit, toPairs } from 'lodash/fp';
import { API_URL } from '../../../../../../../app-config/constants';
import { objective as routes } from '../../../../../../api-routes/constant';
// TODO: Replace with real ladda integration
import { post } from '../../../../../../api/request';
import { liftM2 } from '../../../../../services/Combinators';
const asApiUrl = url => `${API_URL}${url}`;
const CREATE_URL = asApiUrl(routes.cycle.create.path);
const UPDATE_URL = asApiUrl(routes.cycle.update.path);
const getDisabledDates = compose(map(head), filter(last), toPairs, get('disabledDates'));
export const rectifyTimeline = liftM2(omit, getDisabledDates, get('timeline'));
export function createObjectiveCycle(model, inform, staffSelection) {
    const postData = { model: rectifyModel(model, staffSelection), inform };
    return post(CREATE_URL, { postData });
}
export function updateObjectiveCycle(model) {
    const STAFF_SELECTION = null;
    const postData = { model: rectifyModel(model, STAFF_SELECTION) };
    return post(UPDATE_URL, { postData });
}
export function rectifyModel(model, staffSelection) {
    const { options, allowedUsers } = model;
    return Object.assign(Object.assign({}, model), { timeline: rectifyTimeline(model), allowedUsers: rectifyAllowedUsers(allowedUsers, staffSelection), options: rectifyOptions(options) });
}
function rectifyAllowedUsers(allowedUsers, staffSelection) {
    return staffSelection === 'STAFF_SELECTION_ALL_STAFF' ? null : allowedUsers;
}
function rectifyOptions(options) {
    const { categories, ratings } = options;
    return Object.assign(Object.assign({}, options), { categories: rectifyCustomizableItem(categories), ratings: rectifyCustomizableItem(ratings) });
}
function rectifyCustomizableItem(item) {
    const { enabled } = item;
    if (enabled) {
        return item;
    }
    else {
        return { enabled, values: null };
    }
}
