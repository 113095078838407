import SECTION_TYPES from './sectionTypes';
export const DEFAULT_CHECKBOX_REVIEWER_QUESTION = `Which learning and development activities would help {{ firstName }} achieve {{ herHis }} goals and succeed in {{ herHis }} role?`;
export const DEFAULT_DROPDOWN_REVIEWER_QUESTION = 'Which skills would {{ firstName }} focus on improving?';
export const DEFAULT_SLIDER_REVIEWER_QUESTION = `{{ firstName }} consistently treats people with respect.`;
export function getDefaultRatingsReview() {
    return [
        {
            id: '0',
            text: 'Very good',
        },
        {
            id: '1',
            text: 'Good',
        },
        {
            id: '2',
            text: 'Fair',
        },
        {
            id: '3',
            text: 'Needs improvement',
        },
    ];
}
export function getDefaultRatingsScale() {
    return [
        {
            id: '0',
            text: 'Strongly agree',
        },
        {
            id: '1',
            text: 'Agree',
        },
        {
            id: '2',
            text: 'Neither agree nor disagree',
        },
        {
            id: '3',
            text: 'Disagree',
        },
        {
            id: '4',
            text: 'Strongly disagree',
        },
    ];
}
export function getDefaultCheckboxesScale() {
    return [
        {
            id: '0',
            text: 'In-house training',
        },
        {
            id: '1',
            text: 'External training',
        },
        {
            id: '2',
            text: 'On-the-job coaching',
        },
        {
            id: '3',
            text: 'Mentorship from senior staff',
        },
        {
            id: '4',
            text: 'Exposure to another role',
        },
        {
            id: '5',
            text: 'Professional coaching/counselling',
        },
    ];
}
export function getDefaultDropdownScale() {
    return [
        { id: 0, text: 'Communication and presentation skills' },
        { id: 1, text: 'Technical skills' },
        { id: 2, text: 'Data analytics skills' },
        { id: 3, text: 'Management training and leadership skills' },
        { id: 4, text: 'Product knowledge' },
        { id: 5, text: 'Emotional and stress management skills' },
        { id: 6, text: 'Feedback skills' },
    ];
}
export default {
    [SECTION_TYPES.REVIEW_QUESTION]: {
        confidential: false,
        questionType: 'TEXT',
        manager: {
            question: 'What is the team members key strength?',
            ratingsEnabled: false,
            ratings: getDefaultRatingsReview(),
        },
        reviewee: {
            question: 'What is my key strength?',
            ratingsEnabled: false,
            ratings: getDefaultRatingsReview(),
        },
        requiredForManager: false,
        requiredForReviewee: false,
    },
    [SECTION_TYPES.REVIEW_SCALE]: {
        confidential: false,
        questionType: 'SCALES',
        manager: {
            question: '{{ firstName }} consistently treats people with respect.',
            allowComment: true,
            scale: getDefaultRatingsScale(),
        },
        reviewee: {
            question: 'I consistently treat people with respect.',
            allowComment: true,
            scale: getDefaultRatingsScale(),
        },
        requiredForManager: false,
        requiredForReviewee: false,
    },
    [SECTION_TYPES.REVIEW_HEADING]: {
        confidential: false,
        questionType: 'HEADING',
        manager: {},
        reviewee: {},
    },
    [SECTION_TYPES.REVIEW_SLIDER]: {
        confidential: false,
        questionType: 'SLIDER',
        manager: {
            question: DEFAULT_SLIDER_REVIEWER_QUESTION,
            allowComment: true,
        },
        reviewee: {
            question: 'I consistently treat people with respect.',
            allowComment: true,
        },
        sliderSteps: 5,
        sliderLabels: ['Disagree', 'Agree'],
        requiredForManager: false,
        requiredForReviewee: false,
    },
    [SECTION_TYPES.REVIEW_CHECKBOXES]: {
        confidential: false,
        questionType: 'CHECKBOXES',
        manager: {
            question: DEFAULT_CHECKBOX_REVIEWER_QUESTION,
            allowComment: true,
            scale: getDefaultCheckboxesScale(),
        },
        reviewee: {
            question: `Which learning and development activities would help you achieve your goals and succeed in your role?`,
            allowComment: true,
            scale: getDefaultCheckboxesScale(),
        },
        requiredForManager: false,
        requiredForReviewee: false,
    },
    [SECTION_TYPES.REVIEW_DROPDOWN]: {
        confidential: false,
        questionType: 'DROPDOWN',
        manager: {
            question: DEFAULT_CHECKBOX_REVIEWER_QUESTION,
            allowComment: true,
            ratings: getDefaultDropdownScale(),
        },
        reviewee: {
            question: 'Which skills would you be most interested in receiving coaching/mentorship in?',
            allowComment: true,
            ratings: getDefaultDropdownScale(),
        },
        requiredForManager: false,
        requiredForReviewee: false,
    },
    [SECTION_TYPES.CONFIDENTIAL_QUESTION]: {
        confidential: true,
        questionType: 'TEXT',
        manager: {
            question: 'What is the team members key strength?',
            ratingsEnabled: false,
            ratings: getDefaultRatingsReview(),
        },
        requiredForManager: false,
        requiredForReviewee: false,
    },
};
