import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Suspense } from 'react';
import Modal from '../Modal';
class ModalErrorBoundary extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            error: null,
            showModalOnError: true,
        };
        this.closeModal = () => {
            this.setState({ showModalOnError: false });
        };
    }
    static getDerivedStateFromError(error) {
        return { error, showModalOnError: true };
    }
    render() {
        if (this.state.error) {
            return (_jsx(Modal, { isOpen: this.props.enableErrorModal && this.state.showModalOnError, onClose: this.closeModal, status: "error", size: "SM_WIDTH", title: "Something Went Wrong", children: _jsx("p", { children: "Your last action couldn't be performed. Please reload the page and try again." }) }));
        }
        return this.props.children;
    }
}
const SuspenseWithErrorModal = ({ children, fallback, enableErrorModal = true }) => (_jsx(ModalErrorBoundary, { enableErrorModal: enableErrorModal, children: _jsx(Suspense, { fallback: fallback !== null && fallback !== void 0 ? fallback : null, children: children }) }));
export default SuspenseWithErrorModal;
