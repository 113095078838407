export const AUTOMATIC_IMPORTS = {
    label: 'Run automatic imports (every 2 hours)',
    caption: (name) => `Automatically synchronize ${name} with Small Improvements.
        This happens ca. every 2 hours. So please be aware that after enabling it,
        the new ${name} data is not synced immediately!`,
};
export const DEACTIVATE_ACCOUNTS = {
    label: 'Deactivate all Small Improvements accounts not imported',
    caption: () => `This setting controls whether Small Improvements accounts will be deactivated
        if they do not appear in the imported accounts.`,
};
export const PREFERRED_NAME = {
    label: 'If available, set preferred name as first name',
    caption: () => `If you don’t want the employee’s legal first name to end up in Small Improvements, and would rather use their preferred/chosen name, check this box.`,
};
export const PASSWORD_EMAIL = {
    label: 'Send password emails when new users are imported',
};
export const PROFILE_PICTURES = {
    label: (name) => `Use the ${name} user profile images`,
    caption: (name) => `Enable all users' profile pictures to be taken from ${name}
        (they won't be able to edit their personal profile pictures in Small Improvements).
        It's safe to try this out: once you disable this setting the regular Small Improvements user images will be used again.`,
};
export const REACTIVATE_ACCOUNTS = {
    label: 'Reactivate accounts in Small Improvements',
    caption: (name) => `This setting controls whether deactivated Small Improvements accounts will be reactivated
        if the corresponding ${name} account is active. Keep in mind that if you delete an account in Small Improvements
        it will be recreated by the import even without this setting enabled.`,
};
export const SYNC_GENDER = {
    label: 'Sync Gender',
    caption: (name) => `This setting controls whether the gender field is imported from ${name}. If unchecked then changes to the employee's gender in ${name} won't carry over to Small Improvements.`,
};
export const CUSTOM_FIELD = {
    targetLabel: 'Small Improvements Field',
    sourceLabel: (name) => `${name} Field`,
    caption: (name) => `Optionally use a different field from ${name} to overwrite the default.`,
};
export const CUSTOM_FIELD_OVERWRITE_EMPTY = {
    label: 'Keep original value if a configured custom field is empty',
    caption: () => `This setting controls whether the custom field value should overwrite even if not set. For instance, if you have a custom mapping from "preferred first name" to "first name", enabling this will use the "first name" if no "preferred first name" is set.`,
};
export const ENABLE_REMOVE_BOSS = {
    label: 'Enable removal of managers in Small Improvements',
    caption: (name) => `This setting controls whether managers who have been removed from an employee in ${name} will be removed from Small Improvements as well, leaving them with no manager.`,
};
export const UPDATE_EXISTING_USERS = {
    label: 'Update existing user core fields (except manager relationship)',
    caption: () => `This setting indicates whether existing users in Small Improvements should be updated by users with
    matching email addresses from the import data. Enabling this does not affect manager relationships of
    existing users.`,
};
export const UPDATE_BOSSES_OF_EXISTING_USERS = {
    label: 'Update managers of existing users',
    caption: () => `This setting indicates whether existing users in Small Improvements should have their managers updated
    based on the given managers from the import data.`,
};
export const SET_BOSS_OF_NEW_USERS = {
    label: 'Set managers of newly imported users',
    caption: () => `This setting indicates whether new users imported to Small Improvements should have their managers
    imported.`,
};
export const USE_COMPANY_HIRING_DATE_FORMAT = {
    label: 'Use the company-wide date format for the hiring and termination date',
    caption: () => `This setting controls whether to use the date format specified in the "Date and Time" settings under
    Administration or to use the default import formats.`,
};
