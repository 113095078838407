import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import Button from '../Button';
import Link from '../Link';
import styles from './style.m.less';
export function ToggleNavigation({ className, children }) {
    return _jsx("ul", { className: classNames(styles.list, className), children: children });
}
export function ToggleNavigationLink(_a) {
    var { active, className } = _a, props = __rest(_a, ["active", "className"]);
    return (_jsx("li", { className: styles.listItem, children: _jsx(Link, Object.assign({ className: classNames(active ? styles.itemActive : styles.item, className) }, props)) }));
}
export function ToggleNavigationButton(_a) {
    var { active, className } = _a, props = __rest(_a, ["active", "className"]);
    return (_jsx("li", { className: styles.listItem, children: _jsx(Button, Object.assign({ kind: "PLAIN", className: classNames(active ? styles.itemActive : styles.item, className) }, props)) }));
}
