import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconHelpCircle } from 'featherico';
import * as React from 'react';
import { isAssistant, isHr, isManager } from '../../services/User';
import AlertBox from '../AlertBox';
import LinkButton from '../LinkButton';
import styles from './style.m.less';
const CollectFeedbackHeaderBox = ({ cta, url, alertText = 'Send us feedback' }) => {
    return (_jsxs(AlertBox, { colour: "BLUE", compact: true, children: [_jsx(IconHelpCircle, { className: styles.icon }), _jsx("p", { className: styles.feedbackAlertText, children: alertText }), _jsx(LinkButton, { href: url, kind: "PRIMARY_ALTERNATE", isSmall: true, className: styles.linkButton, children: cta })] }));
};
const getHrField = (user) => {
    if (isHr(user)) {
        return 'admin';
    }
    else if (isAssistant(user)) {
        return 'assistant';
    }
    else {
        return 'no';
    }
};
export const getTypeformRoleFields = (user) => `manager=${isManager(user) ? 'yes' : 'no'}&hr=${getHrField(user)}`;
export default CollectFeedbackHeaderBox;
