import { mkEmptyUserSelectionFilterState, } from '../../../../components/UserSelection/types';
import { isFeatureEnabled } from '../../../../services/Features';
export function getRevieweeSelection(users, revieweeIds) {
    const revieweeIdSet = new Set(revieweeIds);
    const everyUserIsReviewee = users.every(u => revieweeIdSet.has(u.id));
    return {
        selectedType: isFeatureEnabled('FEATURE_reviewNewUserSelection') && (everyUserIsReviewee || revieweeIds.length === 0)
            ? 'FILTER'
            : 'STATIC',
        STATIC: {
            type: 'STATIC',
            userIds: revieweeIds,
        },
        FILTER: mkEmptyUserSelectionFilterState(),
    };
}
export function getActiveRevieweeSelection(state) {
    return state[state.selectedType];
}
