import { compose, concat, filter, slice, takeWhile, uniq } from 'lodash/fp';
import { createAction, handleActions } from 'redux-actions';
import { isFeatureEnabled } from '../../../services/Features';
const SLICE_NAME = 'cycleCreate';
const CYCLE_CREATE_INIT = 'CYCLE_CREATE_INIT';
const CYCLE_CREATE_RESET = 'CYCLE_CREATE_RESET';
const CYCLE_CREATE_SAVE = 'CYCLE_CREATE_SAVE';
const CYCLE_CREATE_NEXT = 'CYCLE_CREATE_NEXT';
const CYCLE_CREATE_ENTER_EDIT_MODE = 'CYCLE_CREATE_ENTER_EDIT_MODE';
const CYCLE_CREATE_LEAVE_EDIT_MODE = 'CYCLE_CREATE_LEAVE_EDIT_MODE';
const CYCLE_CREATE_POLL_STATUS_SET = 'CYCLE_CREATE_POLL_STATUS_SET';
function doInit(initialState) {
    return createAction(CYCLE_CREATE_INIT)(initialState);
}
function doReset() {
    return createAction(CYCLE_CREATE_RESET)();
}
function doGoToNextSection() {
    return createAction(CYCLE_CREATE_NEXT)();
}
function doEnterEditMode(id) {
    return createAction(CYCLE_CREATE_ENTER_EDIT_MODE)(id);
}
function doLeaveEditMode() {
    return createAction(CYCLE_CREATE_LEAVE_EDIT_MODE)();
}
function doSave() {
    return createAction(CYCLE_CREATE_SAVE)();
}
function doSetPollStatus(pollStatus) {
    return createAction(CYCLE_CREATE_POLL_STATUS_SET)(pollStatus);
}
export const INITIAL_POLL_STATUS = {
    status: 'IDLE',
    revieweesToCreate: 0,
    willGetInformed: false,
    createPending: false,
    invitePending: false,
    informPending: false,
    noOneToCreate: false,
};
const INITIAL_STATE = {
    steps: [],
    editedSteps: [],
    optionalSteps: [],
    currentStep: '',
    mode: '',
    lastStep: '',
    poll: INITIAL_POLL_STATUS,
};
const reducer = handleActions({
    [CYCLE_CREATE_INIT]: applyInit,
    [CYCLE_CREATE_RESET]: applyReset,
    [CYCLE_CREATE_SAVE]: applySave,
    [CYCLE_CREATE_NEXT]: applyGoToNextSection,
    [CYCLE_CREATE_ENTER_EDIT_MODE]: applyEnterEditMode,
    [CYCLE_CREATE_LEAVE_EDIT_MODE]: applyLeaveEditMode,
    [CYCLE_CREATE_POLL_STATUS_SET]: applySetPollStatus,
}, INITIAL_STATE);
function applyInit(state, action) {
    let { steps, currentStep, editedSteps = [], optionalSteps = [], mode } = action.payload;
    let lastStep = steps[steps.length - 1];
    currentStep = currentStep || '';
    const settings = {
        cycleAdmins: isFeatureEnabled('FEATURE_cycleAdmins'),
    };
    return Object.assign(Object.assign({}, state), { settings,
        steps,
        currentStep,
        lastStep,
        editedSteps,
        optionalSteps,
        mode, poll: INITIAL_POLL_STATUS });
}
function applyReset() {
    return INITIAL_STATE;
}
function applySave(state) {
    // TODO: save
    return Object.assign({}, state);
}
function applyGoToNextSection(state) {
    const editedSteps = compose(uniq, concat(state.editedSteps), takeWhile(step => { var _a; return step === state.currentStep || ((_a = state.optionalSteps) === null || _a === void 0 ? void 0 : _a.includes(step)); }), slice(state.steps.indexOf(state.currentStep), state.steps.length))(state.steps);
    const currentStep = filter(step => !editedSteps.includes(step), state.steps)[0] || '';
    return Object.assign(Object.assign({}, state), { currentStep, editedSteps });
}
function applyEnterEditMode(state, action) {
    return Object.assign(Object.assign({}, state), { currentStep: action.payload });
}
function applyLeaveEditMode(state) {
    return Object.assign(Object.assign({}, state), { currentStep: null });
}
function applySetPollStatus(state, action) {
    const poll = action.payload;
    const updatedPoll = Object.assign(Object.assign({}, state.poll), poll);
    const { informPending, invitePending, createPending, noOneToCreate } = updatedPoll;
    let status;
    if (noOneToCreate) {
        status = createPending ? 'PENDING' : 'DONE';
    }
    else {
        status = !informPending && !invitePending && !createPending ? 'DONE' : 'PENDING';
    }
    return Object.assign(Object.assign({}, state), { poll: Object.assign(Object.assign({}, updatedPoll), { status }) });
}
function getSlice(state) {
    return state[SLICE_NAME];
}
function getMode(state) {
    return getSlice(state).mode;
}
function getSettings(state) {
    return getSlice(state).settings;
}
function getEditedSteps(state) {
    return getSlice(state).editedSteps;
}
function getCurrentStep(state) {
    return getSlice(state).currentStep;
}
function getPollStatus(state) {
    return getSlice(state).poll;
}
const selectors = {
    getMode,
    getSettings,
    getEditedSteps,
    getCurrentStep,
    getPollStatus,
};
const actionCreators = {
    doInit,
    doReset,
    doGoToNextSection,
    doEnterEditMode,
    doLeaveEditMode,
    doSetPollStatus,
    doSave,
};
const reducers = { [SLICE_NAME]: reducer };
const actionTypes = {
    CYCLE_CREATE_INIT,
    CYCLE_CREATE_RESET,
    CYCLE_CREATE_SAVE,
    CYCLE_CREATE_NEXT,
    CYCLE_CREATE_ENTER_EDIT_MODE,
    CYCLE_CREATE_LEAVE_EDIT_MODE,
};
export { actionCreators, actionTypes, reducers, selectors };
