import { jsx as _jsx } from "react/jsx-runtime";
import api from '../../../../../api';
import LoadingIndicatorFull from '../../../../components/LoadingIndicatorFull';
import { withData } from '../../../../hoc/withData';
import { isFeatureEnabled } from '../../../../services/Features';
import { EMAIL_TEMPLATE_TYPES } from '../../constants/email-templates';
import FeedbackCycleCreate from '../../lazy';
import { getCreateModel } from '../../services';
import { loadQuestionnaireTemplates } from '../../services/Request';
function FeedbackCycleCreatePage({ allUsers, emailTemplates, hrUsers, questionnaireTemplates }) {
    const model = getCreateModel(allUsers, emailTemplates, hrUsers, !!questionnaireTemplates.length);
    return _jsx(FeedbackCycleCreate, { mode: "create", model: model, questionnaireTemplates: questionnaireTemplates });
}
export default withData({
    resolve: {
        allUsers: () => api.usersMedium.getAll(),
        hrUsers: () => api.usersHR.getAllActiveHrs(true),
        questionnaireTemplates: () => isFeatureEnabled('FEATURE_feedbackQuestionnaireTemplates')
            ? loadQuestionnaireTemplates()
            : Promise.resolve([]),
        emailTemplates: () => api.emailTemplate.getTemplates(EMAIL_TEMPLATE_TYPES),
    },
    pendingComponent: () => _jsx(LoadingIndicatorFull, {}),
})(FeedbackCycleCreatePage);
