export const FULL = {
    formats: ['bold', 'italic', 'underline', 'strike', 'list', 'indent', 'link'],
    modules: {
        autoConversions: true,
        autoLinks: true,
        emojis: true,
    },
};
export const MEDIUM = {
    formats: ['bold', 'italic', 'underline', 'list', 'indent', 'link'],
    toolbarFormats: ['bold', 'italic', 'underline', 'list', 'link'],
    modules: {
        autoConversions: true,
        autoLinks: true,
        emojis: true,
    },
};
export const SMALL = {
    formats: ['bold', 'italic', 'underline', 'list', 'indent', 'link'],
    toolbarFormats: ['bold', 'italic', 'underline'],
    modules: {
        autoConversions: true,
        autoLinks: true,
        emojis: true,
    },
};
