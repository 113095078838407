import moment from 'moment';
import r from '../../../services/Replacement';
export const grantSiImpersonationUntil = ` By default, SI staff can only see very limited data within your account. In some cases it can help to
                troubleshoot issues or to discuss your settings by letting an SI employee take a look into your system.
                You can enable this here. By setting a date this permission is automatically revoked after a few days,
                even if you forget.`;
export const euAllowExternalServices = `Allow our application to exchange data with select 3rd party services while ensuring compliance with EU regulations. 
This may include usage statistics and bug reports to help improve the service.`;
export const notifyReviewersEveryNHours = `Notify newly approved 360° Feedback reviewers by email every x hours at most. Default is 1. Select 12
                for instance and email notifications are only sent every 12 hours - meaning less timely notifications,
                but higher chance of reviewers receiving a smaller number of batched notifications.`;
export const endofBusinessHour = `
    When you set up milestones for ${r('pr.plural')} or 360° Feedback,
    this will be the default hour of the day for everyone to wrap up their work.
    You can still change the actual hour of the day, but this is a convenient default setting.`;
export const timeFormat = (dateFormat, dateTimeFormat) => `
    Sample: Today is ${moment().format(dateFormat)}, with the time that's ${moment().format(dateTimeFormat)}.`;
export const timezone = `
    Changing the time zone does not change actual milestone times, so after making a change here you may
    want to edit the milestones for ${r('pr.plural')} or 360° Feedback as well.`;
export const timezoneHint = `
    Hint: If you have more than one office, we suggest you pick the time zone of the most western office,
    this is simpler for everyone to grasp.`;
