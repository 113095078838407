export const objective = {
    path: '/objectives',
    bulkChangeWeightings: {
        path: '/objectives/changeWeightings',
    },
    getByIdOnly: {
        path: '/objective',
    },
    cycle: {
        path: '/objective-cycles',
        bulkNudgeUsers: {
            path: '/bulk-inform',
        },
        nudgeUser: {
            path: '/nudge-user',
        },
        create: {
            path: '/objective-cycles/guided/create',
        },
        update: {
            path: '/objective-cycles/guided/edit',
        },
    },
    summary: {
        path: '/summaries',
    },
}, feedback = {
    path: '/feedback-requests',
    cycle: {
        path: '/feedback-cycles',
        create: {
            path: '/feedback-cycles/guided/create',
        },
        update: {
            path: '/feedback-cycles/guided/edit',
        },
    },
    participant: {
        path: '/participants',
    },
}, feedbackProvide = {
    get: {
        path: '/feedback',
    },
    post: {
        path: '/feedback/provide-feedback',
    },
    complete: {
        path: '/feedback-cycles/{{ cycleId }}/feedback-requests/{{ requestId }}/participants/{{ id }}/completed',
    },
    delete: {
        path: '/feedback-cycles/{{ cycleId }}/feedback-requests/{{ requestId }}/participants/{{ id }}',
    },
    newUnsolicited: {
        path: '/feedback/new-unsolicited',
    },
}, review = {
    path: '/reviews',
    cycle: {
        path: '/review-cycles',
        create: {
            path: '/review-cycle',
        },
        update: {
            path: '/review-cycles/guided/edit',
        },
    },
    aboutUser: {
        path: '/reviews/about',
    },
}, user = {
    path: '/users',
    changeTwoFactorVerification: {
        path: '/2fa',
    },
}, company = {
    path: '/companies',
}, messages = {
    path: '/messages',
    badges: {
        path: '/badges',
    },
}, draft = {
    path: '/drafts',
};
