import { debounce } from 'lodash';
import { compose, curry, map, range, size, toPairs, values, zip } from 'lodash/fp';
// Only for debug purpose
// eslint-disable-next-line camelcase
export const log_here = x => {
    // eslint-disable-next-line no-console
    console.log(x);
    return x;
};
const mapPairs = f => map(p => f(p[1], p[0]));
export const mapValuesWithKeys = curry((iteratee, collection) => compose(mapPairs(iteratee), toPairs)(collection));
export const mapValuesWithIndex = curry((iteratee, collection) => compose(mapPairs(iteratee), zip(compose(range(0), size)(collection)), values)(collection));
export const debounceWithOptions = curry((wait, options, fn) => debounce(fn, wait, options));
