import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { groupBy, map } from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import TimePicker from '../../../../components/DateTime/TimePicker';
import { FormLabel } from '../../../../components/Form/FormLabel';
import { Option, OptionHeader, Select } from '../../../../components/Select';
import * as labels from '../constants';
import { getAllTimezones, getDateFormats, getDateTimeFormat, getEndOfBusinessHour, getMinuteStepSize, getPreferredTimezones, getSelectedDateFormat, getSelectedTimeFormat, getSelectedTimezone, getStartOfBusinessHour, getTimeFormats, is12hrFormat, setEndOfBusinessHour, setMinuteStepSize, setSelectedDateFormat, setSelectedTimeFormat, setSelectedTimezone, setStartOfBusinessHour, } from '../model';
import styles from '../style.m.less';
import SettingsRow from './SettingsRow';
// @ts-ignore
const mapWithKey = map.convert({ cap: false });
const AdminDateTime = ({ model, setModel }) => (_jsxs("div", { id: "dateTimeSettings", children: [_jsx("h2", { children: "Date and Time Settings" }), _jsx("table", { className: "settingsTable", children: _jsxs("tbody", { children: [_jsx(SettingsRow, { label: "Timezone", children: _jsx(Timezone, { model: model, setModel: setModel }) }), _jsx(SettingsRow, { label: "Date and Time Format", children: _jsx(TimeFormat, { model: model, setModel: setModel }) }), _jsx(SettingsRow, { label: "Default Business Day", children: _jsx(EndOfBusinessHour, { model: model, setModel: setModel }) })] }) })] }));
const Timezone = ({ model, setModel }) => {
    const selectTimeZoneProps = {
        selected: getSelectedTimezone(model),
        optionToText: (timezone) => timezone,
        onSelect: (timezone) => setModel(setSelectedTimezone(timezone, model)),
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: "Selected Timezone" }), _jsxs(Select, Object.assign({}, selectTimeZoneProps, { children: [_jsx(OptionHeader, { name: "Preferred timezones" }), map(timeZone => (_jsx(Option, { value: timeZone, children: timeZone }, timeZone)), getPreferredTimezones(model)), _jsx(OptionHeader, { name: "All timezones" }), map(timeZone => (_jsx(Option, { value: timeZone, children: timeZone }, timeZone)), getAllTimezones(model))] })), _jsx("p", { className: "caption", children: labels.timezone }), _jsx("p", { className: "caption", children: labels.timezoneHint })] }));
};
const OptionsFromGroup = ({ formatGroup }) => (_jsx(_Fragment, { children: map(dateFormat => (_jsx(Option, { value: dateFormat, children: moment().format(dateFormat.format) }, dateFormat.format)), formatGroup) }));
const TimeFormat = ({ model, setModel }) => {
    const selectDateFormatProps = {
        selected: getSelectedDateFormat(model),
        optionToText: (df) => moment().format(df.format),
        onSelect: (df) => setModel(setSelectedDateFormat(df, model)),
    };
    const selectTimeFormatProps = {
        selected: getSelectedTimeFormat(model),
        optionToText: (tf) => tf.name,
        onSelect: (tf) => setModel(setSelectedTimeFormat(tf, model)),
    };
    const selectMinuteStepSizeProps = {
        selected: getMinuteStepSize(model),
        optionToText: (ti) => `${ti} min`,
        onSelect: (ti) => setModel(setMinuteStepSize(ti, model)),
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: "Selected Date Format" }), _jsx(Select, Object.assign({}, selectDateFormatProps, { children: mapWithKey((formatGroup, key) => (_jsxs(React.Fragment, { children: [_jsx(OptionHeader, { name: key }), _jsx(OptionsFromGroup, { formatGroup: formatGroup })] }, key)), groupBy('name', getDateFormats(model))) })), _jsx(FormLabel, { children: "Selected Time Format" }), _jsx(Select, Object.assign({}, selectTimeFormatProps, { children: map(timeFormat => (_jsx(Option, { value: timeFormat, children: timeFormat.name }, timeFormat.format)), getTimeFormats(model)) })), _jsx(FormLabel, { children: "Selected Minute Increments" }), _jsx(Select, Object.assign({}, selectMinuteStepSizeProps, { children: map(interval => (_jsx(Option, { value: interval, children: `${interval} min` }, interval)), [1, 5, 10, 15]) })), _jsx("p", { className: "caption", children: labels.timeFormat(getSelectedDateFormat(model).format, getDateTimeFormat(model)) })] }));
};
const EndOfBusinessHour = ({ model, setModel }) => (_jsxs(_Fragment, { children: [_jsx(FormLabel, { children: "Start of Business Day" }), _jsx("span", { className: styles.pickerMiddle, children: _jsx(TimePicker, { kind: "DEFAULT", timeZone: getSelectedTimezone(model), value: getStartOfBusinessHour(model), onChange: time => setModel(setStartOfBusinessHour(time, model)), stepSizeMins: 0, format: is12hrFormat(model) ? '12hr' : '24hr' }) }), _jsx(FormLabel, { children: "End of Business Day" }), _jsx("span", { className: styles.pickerMiddle, children: _jsx(TimePicker, { kind: "DEFAULT", timeZone: getSelectedTimezone(model), value: getEndOfBusinessHour(model), onChange: time => setModel(setEndOfBusinessHour(time, model)), stepSizeMins: 0, format: is12hrFormat(model) ? '12hr' : '24hr' }) }), _jsx("p", { className: "caption", children: labels.endofBusinessHour })] }));
export default AdminDateTime;
